export const MenusResponse = {

  'leftTopNavigation': [
    {
      'name': 'Navigation - Main - My Territory',
      'link': '/my-territory',
      'labelMobile': 'LEFT_NAV.MYTERRITORY_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
      'hover': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
      'breadcrumb': 'My Territory',
      'text': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
      'icon': 'menu-territory-view-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['117']
    },
    {
      'name': 'Navigation - Main - Dashboards',
      'link': '/sites',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Dashboards',
      'icon': 'menu-dashboard-icon',
      'isSelected': false,
      'text': 'LEFT_NAV.MAIN_NAV_LABEL',
      'subNavigation': [
        {
          'name': 'Navigation - Main - CIP',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL',
          'hover': 'CIP',
          'isExternal': 'true',
          'breadcrumb': 'CIP',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['45']
        },
        {
          'name': 'Navigation - Main - Inventory',
          'link': '/inventory',
          'labelMobile': 'LEFT_NAV.SUBNAV_INVENTORY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_INVENTORY.NAV_LABEL',
          'hover': 'Inventory',
          'breadcrumb': 'Inventory',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_INVENTORY.NAV_LABEL',
          'subNavigation': [],
          'role': ['101']
        },
        {
          'name': 'Navigation - Main - Climate Intelligence',
          'link': '/climate-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL',
          'hover': 'Climate Intelligence',
          'breadcrumb': 'Climate Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL',
          'subNavigation': [],
          'role': ['78']
        },
        {
          'name': 'Navigation - Main - eCommerce',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL',
          'hover': 'eCommerce',
          'isExternal': 'true',
          'breadcrumb': 'eCommerce',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - Crude Flex',
          'link': '/crude-flex',
          'labelMobile': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL',
          'hover': 'Crude Flex',
          'breadcrumb': 'Crude Flex',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL',
          'subNavigation': [],
          'role': ['85']
        },
        {
          'name': 'Navigation - Main - FSQS',
          'link': '/fsqs',
          'labelMobile': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL',
          'hover': 'FSQS',
          'breadcrumb': 'FSQS',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL',
          'subNavigation': [],
          'role': ['86']
        },
        {
          'name': 'Navigation - Main - OMNI',
          'link': '/omni',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': 'OMNI',
          'breadcrumb': 'OMNI',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['5']
        },
        {
          'name': 'Navigation - Main - Recycle Intelligence',
          'link': '/recycle-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL',
          'hover': 'Recycle Intelligence',
          'breadcrumb': 'Recycle Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL',
          'subNavigation': [],
          'role': ['75']
        },
        {
          'name': 'Navigation - Main - RO Optimizer',
          'link': '/ro-optimizer',
          'labelMobile': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL',
          'hover': 'RO Optimizer',
          'breadcrumb': 'RO Optimizer',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['59']
        },
        {
          'name': 'Navigation - Main - Scale Guard Index',
          'link': '/scale-guard-index',
          'labelMobile': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL',
          'hover': 'Scale Guard Index',
          'breadcrumb': 'Scale Guard Index',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['53', '54']
        },
        {
          'name': 'Navigation - Main - Wash Process Intelligence',
          'link': '',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'breadcrumb': 'Wash Process Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['63']
        },
        {
          'name': 'Navigation - Main - Water Balance Intelligence',
          'link': '/water-balance-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Balance Intelligence',
          'breadcrumb': 'Water Balance Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['52']
        },
        {
          'name': 'Navigation - Main - Water Flow Intelligence',
          'link': '/water-flow-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['7']
        },
        {
          'name': 'Navigation - Main - Water Quality ',
          'link': '/quality',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['4']
        },
        {
          'name': 'Navigation - Main - Water Safety',
          'link': '/water-safety',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['2']
        },
        {
          'name': 'Navigation - Main - Total Booth Management',
          'link': '/total-booth-management',
          'labelMobile': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL',
          'hover': 'Total Booth Management',
          'breadcrumb': 'Total Booth Management',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL',
          'subNavigation': [],
          'role': ['102']
        },
        {
          'name': 'Navigation - Main - Ecolab water for climate',
          'link': '/water-for-climate',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FOR_CLIMATE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FOR_CLIMATE.NAV_LABEL',
          'hover': 'Ecolab water for climate',
          'breadcrumb': 'Ecolab water for climate',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_WATER_FOR_CLIMATE.NAV_LABEL',
          'subNavigation': [],
          'role': ['116']
        },
        {
          'name': 'Navigation - Main - Paper',
          'link': '/paper',
          'labelMobile': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'label': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'hover': 'Paper',
          'breadcrumb': 'Paper',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'subNavigation': [],
          'role': ['118'] 
        }
      ]
    },
  ],
  'leftMidNavigation': [
    {
      'name': 'Navigation - Main - Service Summary',
      'link': '/service-summary',
      'labelMobile': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'hover': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'breadcrumb': 'Service Summary',
      'text': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'icon': 'menu-service-summary-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Data Visualization',
      'link': '/data-visualization',
      'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
      'hover': 'Data Visualization',
      'text': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
      'breadcrumb': 'Data Visualization',
      'icon': 'menu-dv-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['9']
    },
    {
      'name': 'Navigation - Main - Financial Impact',
      'link': '/financial-impact',
      'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
      'hover': 'Financial Impact',
      'text': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
      'breadcrumb': 'Financial Impact',
      'icon': 'menu-financial-impact-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['8']
    },
    {
      'name': 'Navigation - Main - Action Log',
      'link': '/action-log',
      'labelMobile': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'label': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'hover': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'breadcrumb': 'Actions Log',
      'text': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'icon': 'menu-actionRecommendations-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Asset Details ',
      'link': '/asset-details',
      'labelMobile': 'LEFT_NAV.SUBNAV_ASSET_DETAILS.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_ASSET_DETAILS.NAV_LABEL',
      'hover': 'asset-details ',
      'breadcrumb': 'asset-details',
      'text': 'LEFT_NAV.SUBNAV_ASSET_DETAILS.NAV_LABEL',
      'icon': 'menu-asset-details-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - My Documents',
      'link': '/my-documents',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'breadcrumb': 'My Documents',
      'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'icon': 'menu-myDocuments-icon',
      'isSelected': false,
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Tools',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'label': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'hover': '',
      'breadcrumb': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'text': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'icon': 'menu-tools-icon',
      'isSelected': false,
      'subNavigation': [
        // Alarm And user mangement  should be at first 
        {
          'name': 'Alarm & User Management',
          'link': '/alarm-user-mgmt',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'hover': 'Alarms',
          'breadcrumb': 'Alarms',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - Asset Harvester',
          'link': '/assetharvester',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': 'Asset Harvester',
          'breadcrumb': 'Asset Harvester',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'subNavigation': [],
          'role': ['28', '29']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Asset Tree',
          'link': '/asset-tree',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL',
          'hover': 'Asset Tree',
          'breadcrumb': 'Asset Tree',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['3']
        },
        {
          'name': 'Envision-Configurator-CanningAndBottling',
          'link': 'Configurators_CanningAndBottling',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Envision-Configurator',
          'link': 'Envision_Configurator',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['-62', '1']
        },

        {
          'name': 'Envision-Configurator-CoolingWater',
          'link': 'Configurators_CoolingWater',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Controller Admin',
          'link': '/controller-management',
          'labelMobile': 'CONTROLLER_MANAGEMENT.CONTROLLER_MANAGEMENT',
          'label': 'CONTROLLER_MANAGEMENT.CONTROLLER_MANAGEMENT',
          'hover': 'Controller Management',
          'breadcrumb': 'Controller Management',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['29', '67']
        },
        {
          'name': 'Navigation - Main - Credential Management',
          'link': '/credential-management',
          'labelMobile': 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL',
          'hover': 'Credential Management',
          'breadcrumb': 'Credential Management',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['83']
        },
        {
          'name': 'Envision-CustomerData-Upload',
          'link': 'Envision_CustomerDataUpload',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Navigation - Main - Connectivity',
          'link': '/connectivity',
          'labelMobile': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL',
          'hover': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL',
          'breadcrumb': 'Connectivity',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL_MOBILE',
          'subNavigation': [],
          'role': ['94']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Ecolab Equipment Assets',
          'link': '/equipment-manager',
          'labelMobile': 'Equipment Manager',
          'label': 'Ecolab Equipment Assets',
          'hover': 'Ecolab Equipment Assets',
          'breadcrumb': 'Ecolab Equipment Assets',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['61']
        },
        {
          'name': 'Navigation - Main - E-data',
          'link': 'Envision_EData_Workaround',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'hover': 'E-data-workaround',
          'breadcrumb': 'E-data-workaround',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - Alarms',
          'link': '/admin-alarm',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'hover': 'Alarms',
          'breadcrumb': 'Alarms',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'subNavigation': [],
          'role': ['19', '20', '21', '77']
        },
        {
          'name': 'Envision-Controller',
          'link': 'Envision_Controller',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': true,
          'role': ['1']
        },
        {
          'name': 'Envision-ERB',
          'link': 'Envision_ERB',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['-62', '1']
        },
        {
          'name': 'Envision-Inventory',
          'link': 'Envision_Inventory',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': true,
          'role': ['62']
        },
        // Equipment Connectivity menu should be here
        {
          'name': 'Navigation - Main - Admin & Settings - Logo Management',
          'link': '/logo-management',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_SERVICE_SUMMARY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_SERVICE_SUMMARY.ADMIN_NAV_LABEL',
          'hover': 'Logo Management',
          'breadcrumb': 'Logo Management',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['68']
        },
        // Controller credential Menu, Plannig for June release.
        {
          'name': 'Navigation - Main - Admin & Settings - Controller Credentials',
          'link': '/controller-credentials',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL',
          'hover': 'NextGen Controller credential',
          'breadcrumb': 'NextGen Controller credential',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Envision-Configurator-SecondaryDesinfection',
          'link': 'Configurators_SecondaryDesinfection',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Envision-USBManualNextGenUpgrades',
          'link': 'Envision_NextGenUpgrades',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Envision-Configurator-WasteWaterOther',
          'link': 'Configurators_WasteWaterOther',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'isVisible': false,
          'role': ['62']
        },
        {
          'name': 'Navigation - wfi - Alarms',
          'link': '/WFI-alarm',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'hover': 'wfi Alarms',
          'breadcrumb': 'wfi Alarms',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'subNavigation': [],
          'role': ['19', '20', '21', '77']
        },
        // {
        //   'name': 'Navigation - Main - Admin & Settings - Water Flow Intelligence',
        //   'link': '/water-flow-intelligence/admin/sensor-config',
        //   'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL_MOBILE',
        //   'label': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL',
        //   'hover': 'WFI Sensor Mapping',
        //   'breadcrumb': 'WFI Sensor Mapping',
        //   'icon': 'icon-icon_admin',
        //   'isSelected': false,
        //   'subNavigation': [],
        //   'role': ['27']
        // },
        {
          'name': 'Navigation - Main - Manual Equipment Creation',
          'link': '/manual-equipment-creation',
          'labelMobile': 'Manual Equipment Creation',
          'label': 'Manual Equipment Creation',
          'hover': 'Manual Equipment Creation',
          'breadcrumb': 'Manual Asset Creation',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['81']
        },
        {
          'name': 'Navigation - Main - Manual Equipment Creation',
          'link': '/manual-equipment-creation/approverPage',
          'labelMobile': 'Manual Equipment Creation',
          'label': 'Manual Equipment Creation',
          'hover': 'Manual Equipment Creation',
          'breadcrumb': 'Manual Asset Creation',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['82']
        }
      ],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Admin & Settings',
      'link': '/settings',
      'labelMobile': 'LEFT_NAV.ADMIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Settings',
      'icon': 'menu-settings-icon',
      'isSelected': false,
      'text': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'subNavigation': [

        {
          'name': 'Navigation - Main - Admin & Settings - Water Safety ',
          'link': '/water-safety/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL',
          'hover': 'Water Safety Admin',
          'breadcrumb': 'Water Safety Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['10']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Quality ',
          'link': '/quality/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL',
          'hover': 'Water Quality Admin',
          'breadcrumb': 'Water Quality Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['11']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - OMNI ',
          'link': '/omni/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL',
          'hover': 'OMNI Admin',
          'breadcrumb': 'OMNI Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['12']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Climate Intelligence ',
          'link': '/climate-intelligence/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_CLIMATE_INTELLIGENCE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_CLIMATE_INTELLIGENCE.ADMIN_NAV_LABEL',
          'hover': 'Climate-Intelligence Admin',
          'breadcrumb': 'Climate-Intelligence Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['84']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Notifications',
          'link': '/notifications-admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL',
          'hover': 'Notifications Admin',
          'breadcrumb': 'Notifications Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['19', '20', '21', '22']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - System Assurance Admin',
          'link': '/system-assurance/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL',
          'hover': 'System Assurance Admin',
          'breadcrumb': 'System Assurance Admin',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['55']
        },
        {
          'name': 'Navigation - Main - Recycle Intelligence Configuration',
          'link': '/recycle-intelligence-configuration',
          'labelMobile': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL',
          'hover': 'Recycle Intelligence Configuration',
          'breadcrumb': 'Recycle Intelligence Configuration',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'text': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL',
          'subNavigation': [],
          'role': ['76']
        }
      ]
    },
  ],

  'leftBottomNavigation': [
    {
      'name': 'Training',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'label': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'hover': '',
      'breadcrumb': 'Training',
      'text': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'icon': 'menu-training-icon',
      'isSelected': false,
      'subNavigation': [
        {
          'name': 'Training Customers',
          'link': 'Training-Customers-External',
          'labelMobile': 'LEFT_NAV.CUSTOMERS',
          'label': 'LEFT_NAV.CUSTOMERS',
          'hover': '',
          'breadcrumb': 'Training Customers',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Training Ecolab Associate',
          'link': 'Training-Ecolab-Associate',
          'labelMobile': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'label': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'hover': ' Ecolab Associate ',
          'breadcrumb': 'Ecolab Associate ',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        }
      ]
    },
    // Feedback forums sub navigation links are sorted in ascending order.
    {
      'name': 'Feedback - Uservoice',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'label': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'hover': '',
      'breadcrumb': 'Feedback - Uservoice',
      'text': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'icon': 'menu-feedback-icon',
      'isSelected': false,
      'subNavigation': [
        {
          'name': 'Actions & Recommendations',
          'link': 'Action-Recommendation-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL',
          'hover': ' Actions & Recommendations',
          'breadcrumb': 'Actions & Recommendations',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Asset Harvester',
          'link': 'Asset-Harvester-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': ' Asset Harvester ',
          'breadcrumb': 'Asset Harvester',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['28', '29']
        },
        {
          'name': 'Data Visualization',
          'link': 'data-Visualization-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
          'hover': 'Data Visualization',
          'breadcrumb': 'Data Visualization',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['9']
        },
        {
          'name': 'E-Data',
          'link': 'E-Data-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_E_DATA.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_E_DATA.NAV_LABEL',
          'hover': 'E-Data',
          'breadcrumb': 'E-Data',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Financial Impact',
          'link': 'financial-impact-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
          'hover': 'Financial Impact',
          'breadcrumb': 'Financial Impact',
          'icon': 'icon-icon_admin',
          'isSelected': false,
          'subNavigation': [],
          'role': ['8', '15']
        },
        {
          'name': 'Inventory Management',
          'link': 'Inventory-Management-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_INVENTORY_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_INVENTORY_MANAGEMENT.NAV_LABEL',
          'hover': 'Inventory Management',
          'breadcrumb': 'Inventory Management',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'My Documents',
          'link': 'my-documents-Uservoice',
          'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
          'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'breadcrumb': 'My Documents',
          'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'icon': 'ico-nav-my-documents',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Omni',
          'link': 'Omni-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': ' Omni ',
          'breadcrumb': 'Omni',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['5']
        },
        {
          'name': 'Service Summary Dashboard',
          'link': 'service-summary-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL',
          'hover': ' Service Summary Dashboard',
          'breadcrumb': 'Service Summary Dashboard',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Water Flow Intelligence',
          'link': 'water-Flow-intelligence-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isSelected': false,
          'role': ['7']
        },
        {
          'name': 'Water Quality',
          'link': 'Water-Quality-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['4', '11']
        },
        {
          'name': 'Water Safety Intelligence',
          'link': 'Water-Safety-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['2', '10']
        }
      ]
    },
    {
      'name': 'Support Info',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_SUPPORT_SAFETY_INFO',
      'label': 'LEFT_NAV.MAIN_NAV_SUPPORT_SAFETY_INFO',
      'hover': '',
      'breadcrumb': 'Support Info',
      'text': 'LEFT_NAV.MAIN_NAV_SUPPORT_INFO',
      'icon': 'menu-support-icon',
      'isSelected': false,
      'isVisible': true,
      'subNavigation': [
        {
          'name': 'Ecolab Digital Support:(866) 851 – 8670',
          'link': '',
          'href': 'tel:(866) 851 – 8670',
          'labelMobile': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT',
          'label': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT',
          'hover': '',
          'breadcrumb': 'Ecolab Digital Support:(866) 851 – 8670',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': false,
          'region': ['North America', 'Latinamerica'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n 855-432-6522',
          'link': '',
          'href': 'tel:855-432-6522',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_NA',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_NA',
          'hover': '',
          'breadcrumb': 'Onboarding Support:\n 855-432-6522',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n +31 71 524 1111',
          'link': '',
          'href': 'tel:+31 71 524 1111',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_EU',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_EU',
          'hover': '',
          'breadcrumb': 'Onboarding Support:\n +31 71 524 1111',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['Europe'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n Digital Contacts',
          'link': '',
          'href': '',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA_PACIFIC',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA_PACIFIC',
          'hover': '',
          'breadcrumb': 'Onboarding Support: Digital Contacts',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['Pacific', 'Greater China', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],// Should remove other regions except pscific for this menu till we get the confirmation
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Ecolab Digital Support:',
          'link': '',
          'href': '',
          'labelMobile': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT_ALL',
          'label': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT_ALL',
          'hover': '',
          'breadcrumb': 'Ecolab Digital Support:',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': false,
          'region': ['Europe', 'Pacific', 'Mid.East/Africa'], // 'Greater China'
          'subNavigation': [],
          'role': ['1']
        },
        //Should uncomment it after getting confirmed menu
        // {
        //   'name': 'Onboarding Support: ',
        //   'link': '',
        //   'href': 'tel:',
        //   'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA',
        //   'label': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA',
        //   'hover': '',
        //   'breadcrumb': 'Onboarding Support:',
        //   'icon': '',
        //   'isSelected': false,
        //   'isVisible': true,
        //   'isInternalUser': true,
        //   'region': ['Greater China', 'Latinamerica', 'Mid.East/Africa'],
        //   'subNavigation': [],
        //   'role': ['1']
        // },
        {
          'name': 'Email',
          'link': '',
          'href': '',
          'target': '',
          'labelMobile': 'LEFT_NAV.EGIC_EMAIL',
          'label': 'LEFT_NAV.EGIC_EMAIL',
          'hover': 'Email',
          'breadcrumb': 'Email',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Site Action Request',
          'link': 'SITE Creation (Emily Synal) (formsite.com)',
          'href': '',
          'labelMobile': 'LEFT_NAV.SITE_ACTION_REQUEST',
          'label': 'LEFT_NAV.SITE_ACTION_REQUEST',
          'hover': 'Site Action Request',
          'breadcrumb': 'Site Action Request',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Digital Support Dashboard',
          'link': '',
          'href': 'https://ecolab.sharepoint.com/sites/IndustrialDigitalSupportDashboard/SitePages/ECOLAB3D-noticeboard.aspx?csf=1&&web=1&&e=ZuTHyf&&cid=6ed84063-33a2-4490-8729-b4d391a134ed',
          'labelMobile': 'LEFT_NAV.DIGITAL_SUPPORT_DASHBOARD',
          'label': 'LEFT_NAV.DIGITAL_SUPPORT_DASHBOARD',
          'hover': 'Digital Support Dashboard',
          'breadcrumb': 'Digital Support Dashboard',
          'icon': '',
          'isSelected': false,
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Emergency Numbers',
          'link': 'common-pages/emergency-numbers',
          'labelMobile': 'LEFT_NAV.EMERGENCY',
          'label': 'LEFT_NAV.EMERGENCY',
          'hover': '',
          'breadcrumb': 'Emergency Numbers',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'SH&E Reference',
          'link': 'common-pages/safety-health',
          'labelMobile': 'LEFT_NAV.SH&E',
          'label': 'LEFT_NAV.SH&E',
          'hover': 'SH&E Reference',
          'breadcrumb': 'SH&E Reference',
          'icon': '',
          'isSelected': false,
          'subNavigation': [],
          'role': ['1']
        }
      ]
    }
  ],



};
