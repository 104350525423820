import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { ChartData } from '../../../shared/models/ChartData';
import { ChartDataSeries } from '../../../shared/models/ChartDataSeries';
import { XAxis } from '../../../shared/models/XAxis';
import { YAxis } from '../../../shared/models/YAxis';
import { BarColor, CalculatedParameters, ChartDataNames, ConversionSource, LabelFormat, ROTabs, RecoveryUnits, SaveSuccessResponse, TemperatureUnits, ToolTipColors, VALID, pHAcids, pHBases } from '../../_enum/constants';
import { maxValue, minValue } from '../../_enum/validators';
import { ROMeasures } from '../../models/ROMeasures';
import { ProductModel, SelectedProduct } from '../../models/SelectedProduct';
import { UnitConversion } from '../../models/UnitConversion';
import { SharedRoService } from '../../services/shared-ro.service';

@Component({
  selector: 'nalco-dosage-and-recommendation',
  templateUrl: './dosage-and-recommendation.component.html',
  styleUrls: ['./dosage-and-recommendation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DosageAndRecommendationComponent extends XdComponentState implements OnInit, OnChanges, OnDestroy {

  @Input() drData;
  @Input() dimensionalUsageKeys;
  @Input() scenarioData;
  @Input() oipScenario;
  @Output() errorTabStatus = new EventEmitter<any>();
  @Output() saveStatus = new EventEmitter<any>();
  @Output() phTableValidation = new EventEmitter<any>();
  @Output() saveLoaderStatus = new EventEmitter<boolean>();
  dosageAndRecommendationForm: FormGroup;
  recommendationsForm: FormGroup;
  errorInfo: any;
  data: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  waterChemistry: string;
  subscription: Subscription;
  formSubscription: Subscription;
  paramsSubscription: Subscription;
  drtdataCount: number = 0;
  paramData = [];
  infoDandR: any;
  opFormData: any;
  wcFormData: any;
  cbFormData: any;
  unitConversion: Array<UnitConversion>;
  commonParams: any;
  waterAnalysis: any;
  updatedData: ROMeasures;
  initialData: any;
  chartData: ChartData = new ChartData();
  isSaveDisabled: boolean = true;
  allForms: any;
  pointPadding: number;
  tooltip: any = {};
  calculatedParameters: Subscription;

  constructor(private formBuilder: FormBuilder,
    private roService: SharedRoService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {
    super();
  }

  myParentInstance(): DosageAndRecommendationComponent {
    return this;
  }


  ngOnInit() {
    this.setState(ComponentState.ready);
    this.roService.waterChemistryTypeChangeEvnt.subscribe(x => this.waterChemistry = x);
    this.dosageAndRecommendationForm = this.formBuilder.group({
    });
    this.subscription = this.roService.dosageAndRecommendationTab.subscribe(x => {
      this.createForm();
      this.createRecommendationsForm();
    });
    this.formSubscription = this.roService.tabForms.subscribe(form => {
      this.allForms = form;
      if (form) {
        if (form['operatingParameters'] && form['operatingParameters'].controls) {
          this.opFormData = Object.keys(form['operatingParameters'].controls).length > 0 ? form['operatingParameters'].controls : this.opFormData;
        }
        else if (form['waterChemistry'] && form['waterChemistry'].controls) {
          this.wcFormData = Object.keys(form['waterChemistry'].controls).length > 0 ? form['waterChemistry'].controls : this.wcFormData;
        } else {
          this.cbFormData = form['chargeBalance'] ? form['chargeBalance'] : null;
        }
        this.setInputParams();
      }
    })
    this.roService.pHControl.subscribe(x => {
      this.drData.filter(dr => {
        if (dr.DisplayName == 'pH Control') {
          dr.DropdownParam.Value = x;
        }
      });
      if (Object.keys(this.dosageAndRecommendationForm.controls).length > 0) {
        this.dosageAndRecommendationForm.controls['pHControlProductNamedd'].setValue(x);
        this.setValidators();
      }
    });
    this.roService.pHSetPoint.subscribe(x => {
      this.drData.filter(dr => {
        if (dr.DisplayName == 'pH Setpoint') {
          dr.InputParam.Value = x;
        }
      });
      if (Object.keys(this.dosageAndRecommendationForm.controls).length > 0) {
        this.dosageAndRecommendationForm.controls['pHSetPointtb'].setValue(x);
        this.setValidators();
      }
    });
    this.paramsSubscription = this.roService.commonFormParams.subscribe(x => {
      this.commonParams = x;
      if (this.commonParams !== null && this.commonParams['component'] !== ROTabs[2]) {
        Object.keys(this.commonParams).forEach(control => {
          if (this.commonParams[control].FormControlName !== 'RawWaterFlowtb' && this.commonParams[control].updated == true) {
            if (Object.keys(this.dosageAndRecommendationForm.controls).length > 0) {
              this.dosageAndRecommendationForm.controls[control.concat('tb')].setValue(this.commonParams[control].InputValue);
              this.dosageAndRecommendationForm.controls[control.concat('dd')].setValue(this.commonParams[control].DropdownValue);
            }
            this.drData.filter(dr => {
              if (dr.DisplayName == control) {
                dr.DropdownParam.Value = this.commonParams[control].DropdownValue;
                dr.InputParam.Value = this.commonParams[control].InputValue;
              }
            });
            this.drData = JSON.parse(JSON.stringify(this.drData));
            this.commonParams[control].updated = false;
          }
        });
      }
    });
    this.calculatedParameters = this.roService.calculatedParams.subscribe(x => {
      if (x.isSolve == true && x.tab !== ROTabs[2]) {
        this.getConvertedUnits(null, true, null, true, x.isrecDRTab);
      }
    });
  }

  getData() {
    if (this.opFormData || this.wcFormData || this.cbFormData) {
      this.getConvertedUnits(null, true);
    }
    else {
      this.getDandRInfo(this.updatedData)
    }
  }

  createForm() {
    if (Object.keys(this.dosageAndRecommendationForm.controls).length == 0) {
      this.drData.forEach((element, index) => {
        if (element.Type == 'combo-box') {
          this.dosageAndRecommendationForm.addControl(element.InputParam.FormControlName, new FormControl(''));
          this.dosageAndRecommendationForm.addControl(element.DropdownParam.FormControlName, new FormControl(''));
        } else if (element.Type === 'drop-down') {
          this.dosageAndRecommendationForm.addControl(element.DropdownParam.FormControlName, new FormControl(''));
        } else if (element.Type === 'input') {
          this.dosageAndRecommendationForm.addControl(element.InputParam.FormControlName, new FormControl(''));
        }
        this.drtdataCount = index;
      });
      this.setValues();
      this.setValidators();
    }
  }

  setValues() {
    if (this.drData.length > 0) {
      this.drData.forEach(x => {
        if (x.Type == 'combo-box' || x.Type == 'input') {
          this.dosageAndRecommendationForm.controls[x.InputParam.FormControlName].setValue(x.InputParam.Value);
        }
      })
    }
  }

  setValidators() {
    if (this.drData.length > 0 && this.waterChemistry == 'RawWater') {
      let val = this.drData.filter(x => x.DisplayName == 'pH Control')[0].DropdownParam.Value;
      if (val == null || val == '' || val == 'None') {
        this.dosageAndRecommendationForm.controls['pHSetPointtb'].disable();
      } else {
        let ph = this.updatedData.InputStream.filter(x => x.Name == 'pH')[0].Value;
        if (pHAcids.includes(val)) {
          this.dosageAndRecommendationForm.controls['pHSetPointtb'].setValidators([Validators.required, Validators.min(6.5), Validators.max(ph)]);
        } else if (pHBases.includes(val)) {
          this.dosageAndRecommendationForm.controls['pHSetPointtb'].setValidators([Validators.required, Validators.min(ph), Validators.max(12)]);
        }
        this.dosageAndRecommendationForm.controls['pHSetPointtb'].enable();
      }
      let recUnit = this.drData.filter(x => x.DisplayName == 'Recovery')[0].DropdownParam.Value;
      if (recUnit == RecoveryUnits[0]) {
        this.dosageAndRecommendationForm.controls['Recoverytb'].setValidators([Validators.required, minValue(0), maxValue(100)]);
      } else if (recUnit == RecoveryUnits[1]) {
        this.dosageAndRecommendationForm.controls['Recoverytb'].setValidators([Validators.required, minValue(0), maxValue(1)]);
      }
      let tempUnit = this.drData.filter(x => x.DisplayName == 'Temperature')[0].DropdownParam.Value;
      if (tempUnit == TemperatureUnits[0]) {
        this.dosageAndRecommendationForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(273), maxValue(373)]);
      } else if (tempUnit == TemperatureUnits[1]) {
        this.dosageAndRecommendationForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(32), maxValue(212)]);
      } else if (tempUnit == TemperatureUnits[2]) {
        this.dosageAndRecommendationForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(0), maxValue(100)]);
      }
      Object.keys(this.dosageAndRecommendationForm.controls).forEach(key => {
        this.dosageAndRecommendationForm.get(key).updateValueAndValidity();
      });
    }
    this.dosageAndRecommendationForm = Object.create(this.dosageAndRecommendationForm);
  }

  updateDropdown(event) {
    if (event.FormControlName == 'pHControlProductNamedd') {
      this.roService.changepHControlProduct(event.Value);
      this.dosageAndRecommendationForm.controls['pHSetPointtb'].setValue(null);
      this.roService.changepHSetPoint(null);
      this.checkSaveStatus();
    } else if (event.FormControlName == 'Recoverydd' || event.FormControlName == 'Temperaturedd') {
      this.setState(ComponentState.loading);
      this.getConvertedUnits(event);
    }
    if (this.opFormData && this.wcFormData) {
      this.setInputParams(true);
    }
    else {
      if (event.FormControlName == 'pHControlProductNamedd') {
        this.updatedData.OperatingParameters.filter(x => x.Name == event.FormControlName.substring(0, event.FormControlName.length - 2))[0].Value = event.Value == 'None' ? null : event.Value;
        this.updatedData.OperatingParameters.filter(x => x.Name == 'pHSetPoint')[0].Value = event.Value == 'None' ? null : event.Value;
        this.updatedData.ReferenceCondition.pHSetPoint.Value = event.Value == 'None' ? null : event.Value;
        this.drData.filter(x => x.DisplayName == "pH Control")[0].DropdownParam.Value = event.Value;
        this.drData.filter(x => x.DisplayName == "pH Setpoint")[0].InputParam.Value = event.Value == 'None' ? null : event.Value;
        if (event.Value == 'None') {
          this.getDandRInfo(this.updatedData);
          this.drData.filter(x => x.DisplayName == 'pH Setpoint')[0].Valid = true;
        } else if (this.dosageAndRecommendationForm.controls['pHSetPointtb'].value == null || this.dosageAndRecommendationForm.controls['pHSetPointtb'].value == '') {
          this.createRecommendationsForm();
          this.buildChartData(null);
          this.drData.filter(x => x.DisplayName == 'pH Setpoint')[0].Valid = false;
          let validation = {};
          validation['component'] = ROTabs[2];
          this.phTableValidation.emit(validation);
        }
      }
    }
    this.setValidators();
  }

  updateInput(event) {
    const error = this.dosageAndRecommendationForm.controls[event.name].errors;
    this.drData.forEach(x => {
      let condition = x.DisplayName == 'pH Control' ? x.DropdownParam.FormControlName : x.InputParam.FormControlName;
      if (condition == event.name) {
        if (error == null) {
          x.Valid = true;
          let tabStatus = {};
          tabStatus['status'] = false;
          tabStatus['tab'] = ROTabs[2];
          this.errorTabStatus.emit(tabStatus);
        } else {
          x.Valid = false;
        }
        x.InputParam.Value = event.updatedValue;
      }
    });
    if (event.name == 'pHSetPointtb') {
      this.roService.changepHSetPoint(event.updatedValue);
    } else if (event.name == 'Recoverytb' || event.name == 'Temperaturetb') {
      let control = event.name.substring(0, event.name.length - 2);
      this.commonParams[control].InputValue = event.updatedValue;
      this.commonParams[control].updated = true;
      this.commonParams['component'] = ROTabs[2];
      this.commonParams[control].chargeBalupdated = this.commonParams[control].FormControlName != 'Recoverytb' ? this.commonParams[control].updated : !this.commonParams[control].updated;
      this.roService.setCommonParams(this.commonParams);
    }
    if (error == null && this.dosageAndRecommendationForm.valid) {
      if (this.opFormData && this.wcFormData) {
        this.setInputParams(true);
      } else {
        if (event.name == 'Recoverytb' || event.name == 'Temperaturetb') {
          let control = event.name.substring(0, event.name.length - 2);
          this.drData.filter(x => x.DisplayName == control)[0].InputParam.Value = event.updatedValue;
          let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + control)[0];
          this.data = []
          let fromUnit = this.drData.filter(x => x.DisplayName == control)[0].DropdownParam.Value;
          this.setInputParams();
          this.data.push(this.inputParam(key.UnitType, fromUnit, "", event.updatedValue, control, key.ConversionSource));
          let recUpdated = event.name == 'Recoverytb' ? true : false;
          this.getConvertedUnits(null, true, null, null, null, recUpdated);
        }
        else {
          this.updatedData.OperatingParameters.filter(x => x.Name.concat('tb') == event.name)[0].Value = event.updatedValue;
          this.updatedData.ReferenceCondition.pHSetPoint.Value = event.updatedValue;
          this.getDandRInfo(this.updatedData)
        }
      }
    } else {
      this.checkSaveStatus();
      this.createRecommendationsForm();
      this.buildChartData(null);
      let validation = {};
      validation['component'] = ROTabs[2];
      this.phTableValidation.emit(validation);
    }
    this.setValidators();
  }

  //Input params for Unit Conversion
  inputParam(unitType, fromUnit, toUnit, value, name, source) {
    return {
      UnitType: unitType,
      FromUnit: fromUnit,
      ToUnit: toUnit,
      OriginalValue: value,
      ParameterName: name,
      ConversionSource: source
    }
  }

  //Setting Input Parameters for fetching the data
  setInputParams(updateInfo?) {
    this.data = [];
    if (this.opFormData && Object.keys(this.opFormData).length > 0 && this.updatedData) {
      this.updatedData.OperatingParameters.forEach(x => {
        Object.keys(this.opFormData).forEach(y => {
          if (x.Name.concat('tb') == y) {
            x.Value = this.opFormData[y].value == '' ? null : this.opFormData[y].value;
          } else if (x.Name == 'WaterChemistry') {
            x.Value = this.waterChemistry;
          }
        })
      });
      Object.keys(this.updatedData.ReferenceCondition).forEach(x => {
        Object.keys(this.opFormData).forEach(y => {
          if (x.concat('tb') == y) {
            this.updatedData.ReferenceCondition[x].Value = this.opFormData[y].value == '' ? null : this.opFormData[y].value;
          }
        })
      });
      this.updatedData.OperatingParameters.forEach(element => {
        if (element.Name != 'WaterChemistry' && element.Name != 'Days/Year' && element.Name != 'Hours/Day' &&
          element.Name != 'WaterAnalysisType' && element.Name != 'pHSetPoint' && element.Name != 'pHControlProductName') {
          let dimKey = this.dimensionalUsageKeys.configurations.filter(y => y.dimensionalUsageKey == 'Roo_' + element.Name)[0];
          let fromUnit = this.opFormData[element.Name.concat('dd')].value;
          this.data.push(this.inputParam(dimKey.UnitType, fromUnit, "", element.Value, element.Name, dimKey.Conversion));
        }
      });
    }
    if (this.wcFormData && Object.keys(this.wcFormData).length > 0) {
      let InputParams = [];
      let form = [];
      this.updatedData.ReferenceCondition.pHSetPoint.Value = this.wcFormData['pHSetPoint'] ? (this.wcFormData['pHSetPoint'].value == '' ? null : this.wcFormData['pHSetPoint'].value) : null;
      this.updatedData.OperatingParameters.forEach(x => {
        Object.keys(this.wcFormData).forEach(y => {
          if (x.Name == y || (x.Name == "pHControlProductName" && y == "pHControl")) {
            x.Value = this.wcFormData[y].value == 'None' ? null : this.wcFormData[y].value == '' ? null : this.wcFormData[y].value;
          }
        })
      });
      Object.keys(this.wcFormData).forEach(key => {
        if (key.slice(-2) == 'tb') {
          form.push({ key: key.substring(0, key.length - 2), value: this.wcFormData[key] })
        }
      });
      form.forEach(f => {
        let obj = {};
        let dimKey = this.dimensionalUsageKeys.configurations.filter(y => y.dimensionalUsageKey == 'Roo_' + f.key)[0];
        obj['Name'] = f.key;
        obj['Type'] = f.key == 'pH' ? 'System.Double' : dimKey.NamespacePrefix + "." + dimKey.UnitType;
        obj['Value'] = f.value.value;
        if (obj['Value'] != "" && obj['Value'] != null)
          InputParams.push(obj);
      });
      const filteredresults = JSON.parse(this.initialData).InputStream.filter(({ Name: id1 }) => !InputParams.some(({ Name: id2 }) => id2 === id1));
      if (filteredresults.length > 0) {
        filteredresults.forEach(res => {
          InputParams.push(res);
        });
      }
      this.updatedData.InputStream = InputParams;
      this.updatedData.InputStream.forEach((element, index) => {
        let loopCount = this.updatedData.InputStream.length - filteredresults.length;
        if (element.Name != 'pH' && index < loopCount) {
          let fromUnit = this.wcFormData[element.Name.concat('dd')].value;
          let dimKey = this.dimensionalUsageKeys.configurations.filter(y => y.dimensionalUsageKey == 'Roo_' + element.Name)[0];
          this.data.push(this.inputParam(dimKey.UnitType, fromUnit, "", element.Value, element.Name, dimKey.ConversionSource));
        }
      });
    }
    if (this.cbFormData && this.cbFormData.details) {
      this.updatedData.ChargeBalanceAdded = {
        Name: this.cbFormData.details.ShortName,
        Type: this.cbFormData.details.Type,
        Value: this.cbFormData.selectedIon !== undefined ? this.cbFormData.selectedIon[0].AddedIon : this.updatedData.ChargeBalanceAdded.Value
      }
    } else if (this.cbFormData === null) {
      this.updatedData.ChargeBalanceAdded = null;
    }
    if (updateInfo) {
      this.getConvertedUnits(this.updatedData, true);
    }
  }

  //For unit conversion
  getConvertedUnits(obj, multiple?, recTable?, calcParam?, updateDR?, recUpdated?) {
    this.setState(ComponentState.loading);
    if (multiple != true) {
      let control = obj.FormControlName.substring(0, obj.FormControlName.length - 2);
      let tbvalue = this.dosageAndRecommendationForm.get(control.concat('tb')).value;
      let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + control)[0];
      this.paramData.push(this.inputParam(key.UnitType, obj.previousValue, obj.Value, tbvalue, control, key.ConversionSource));
    }
    else {
      this.paramData = this.data;
    }
    this.roService.GetConversionByUnitType(this.paramData).subscribe(
      response => {
        this.paramData = this.data = [];
        if (response) {
          if (multiple == true) {
            response.forEach(x => {
              if (x.ConversionSource == ConversionSource) {
                if (recTable) {
                  if (x.ParameterName == 'DosageInFeed' || x.ParameterName == 'DosageInConcentrate') {
                    this.infoDandR.SelectedProduct[x.ParameterName].Value = x.ConvertedValue;
                  } else {
                    this.infoDandR.pHControlProductUsage.Value = x.ConvertedValue;
                  }
                  this.createRecommendationsForm(this.infoDandR);
                }
                else {
                  if (this.updatedData.InputStream.filter(y => y.Name == x.ParameterName)[0]) {
                    this.updatedData.InputStream.filter(y => y.Name == x.ParameterName)[0].Value = x.ConvertedValue;
                  }
                }
              }
              else {
                this.updatedData.OperatingParameters.filter(y => y.Name == x.ParameterName)[0].Value = x.ConvertedValue;
                this.updatedData.ReferenceCondition.Recovery.Value = ('Recovery' === x.ParameterName) ? x.ConvertedValue : this.updatedData.ReferenceCondition.Recovery.Value;
                this.updatedData.ReferenceCondition.Temperature.Value = ('Temperature' === x.ParameterName) ? x.ConvertedValue : this.updatedData.ReferenceCondition.Temperature.Value;
              }
            });
            if (!recTable) {
              calcParam = updateDR == true ? undefined : calcParam;
              this.getDandRInfo(this.updatedData, calcParam, recUpdated);
            }
          }
          else {
            this.setState(ComponentState.ready);
            this.unitConversion = response;
            let control = obj.FormControlName.substring(0, obj.FormControlName.length - 2);
            this.dosageAndRecommendationForm.get(control.concat('tb')).setValue(this.unitConversion[0].ConvertedValue);
            this.drData.forEach(x => {
              let condition = x.DisplayName == 'pH Control' ? x.DropdownParam.FormControlName : x.InputParam.FormControlName;
              if (condition == control.concat('tb')) {
                x.Valid = this.dosageAndRecommendationForm.controls[control.concat('tb')].errors == null ? true : false;
                x.InputParam.Value = this.unitConversion[0].ConvertedValue;
              }
            })
            this.commonParams[control].DropdownValue = obj.Value;
            this.commonParams[control].InputValue = this.unitConversion[0].ConvertedValue;
            this.commonParams[control].updated = true;
            this.commonParams['component'] = ROTabs[2];
            this.roService.setCommonParams(this.commonParams);
          }
        } else {
          this.setState(ComponentState.empty);
        }
        this.setValidators();
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }
  //To Fetch Dosage and Recommendations Data
  getDandRInfo(updatedData, calcParam?, recUpdated?) {
    this.setState(ComponentState.loading);
    this.roService.GetDosageAndRecommendationInfo(updatedData).subscribe(result => {
      if (calcParam) {
        this.roService.setDataForCalculation({ isSolve: false, response: result });
      } else {
        if (calcParam != undefined || recUpdated == true) {
          this.roService.setDataForCalculation({ isSolve: false, response: result, isrecDRTab: true, tab: ROTabs[2] });
          CalculatedParameters.find(wc => wc.wcType == this.waterChemistry).Parameters.forEach(param => {
            this.updatedData.OperatingParameters.filter(y => y.Name == param)[0].Value = result.OperatingParameters.filter(x => x.Name == param)[0].Value;
          });
        } else {
          this.infoDandR = result.ReferenceCondition.Output;
          let selectedProduct = this.infoDandR.SelectedProduct = new SelectedProduct(this.infoDandR.SelectedProduct);
          let pHControlProduct = this.infoDandR.pHControlProductUsage = new ProductModel(this.infoDandR.pHControlProductUsage);
          this.data = [];
          if (this.infoDandR.SelectedProduct) {
            Object.keys(this.infoDandR.SelectedProduct).forEach((x: any) => {
              if (x !== 'Name' && selectedProduct[x].Name) {
                this.data.push(this.inputParam(selectedProduct[x].Type.split(".")[selectedProduct[x].Type.split(".").length - 1], "", "ppm", selectedProduct[x].Value, selectedProduct[x].Name, ConversionSource));
              }
            });
          }
          if (pHControlProduct.Name) {
            this.data.push(this.inputParam(pHControlProduct.Type.split(".")[pHControlProduct.Type.split(".").length - 1], "", "ppm", pHControlProduct.Value, pHControlProduct.Name, ConversionSource));
          }
          this.getConvertedUnits(this.data, true, true);
          this.chartData = new ChartData();
          this.buildChartData(this.infoDandR);
          this.checkSaveStatus();
          this.setState(ComponentState.ready);
        }
      }
    }, error => {
      this.errorInfo = error;
      this.setState(ComponentState.ready);
      if (500 <= error.status && error.status < 600) {
        this.setState(ComponentState.error);
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.setState(ComponentState.error);
        this.errorHandling.showErrorPopup(error);
      }
    }
    )
  }

  createRecommendationsForm(result?) {
    this.recommendationsForm = this.formBuilder.group({
      selectedProduct: result ? result.SelectedProduct.Name : '',
      feedDosage: result ? (result.SelectedProduct.DosageInFeed.Value != null ? result.SelectedProduct.DosageInFeed.Value.toFixed(2) : '') : '',
      concentrateDosage: result ? (result.SelectedProduct.DosageInConcentrate.Value != null ? result.SelectedProduct.DosageInConcentrate.Value.toFixed(2) : '') : '',
      pHControl: result ? (result.pHControlProductUsage.Value == null ? 0 : result.pHControlProductUsage.Value.toFixed(2)) : '',
      concentrateLSI: result ? (result.ConcentrateIndex.Value != null ? result.ConcentrateIndex.Value.toFixed(2) : '') : '',
      concentrateSDI: result ? (result.ConcentrateIndex.Value != null ? result.ConcentrateIndex.Value.toFixed(2) : '') : ''
    });
    this.recommendationsForm.disable();
  }

  buildChartData(chartInfoDandR) {
    //-------------- ToBuildChart --------------////
    chartInfoDandR = chartInfoDandR ? chartInfoDandR.BarChartData : null;
    const series = new Array<ChartDataSeries>();
    const unTreatedSeries = new ChartDataSeries();
    const treatedSeries = new ChartDataSeries();
    const cat = [];
    const obj = { categories: [] };
    const yAxis = new YAxis();
    this.pointPadding = 0.2;
    this.tooltip.backgroundColor = ToolTipColors[0];
    this.tooltip.color = ToolTipColors[1];
    unTreatedSeries.name = ChartDataNames[0];
    unTreatedSeries.color = BarColor[0];
    treatedSeries.name = ChartDataNames[1];
    treatedSeries.color = BarColor[1];
    if (chartInfoDandR) {
      chartInfoDandR.UntreatedData.forEach(x => {
        unTreatedSeries.data.push(x.YCoordinate);
      });
      chartInfoDandR.TreatedData.forEach(x => {
        treatedSeries.data.push(x.YCoordinate);
        cat.push(x.XCoordinate);
      });
      yAxis.max = 200;
      yAxis.min = 0;
      yAxis.plotLines = [{
        value: 100,
        zIndex: 0,
        width: 2,
        color: 'red',
      }];
    };
    series.push(unTreatedSeries);
    series.push(treatedSeries);
    obj.categories = cat;
    const xaxis = new XAxis(obj);
    this.chartData.xAxis = new XAxis(xaxis);

    yAxis.labelFormat = LabelFormat;
    this.chartData.yAxis = new YAxis(yAxis);
    this.chartData.series = series;

    this.chartData.xAxis = JSON.parse(JSON.stringify(this.chartData.xAxis));
    this.chartData.series = JSON.parse(JSON.stringify(this.chartData.series));
    this.chartData = JSON.parse(JSON.stringify(this.chartData));
    this.setState(ComponentState.ready);
  }

  saveScenario() {

    this.setState(ComponentState.loading);
    this.saveLoaderStatus.emit(true);
    this.roService.SaveScenarioInfo(this.updatedData).subscribe(
      response => {
        if (SaveSuccessResponse.includes(response)) {
          let event = {};
          this.initialData = JSON.stringify(this.updatedData);
          event['status'] = true;
          event['scenario'] = this.updatedData;
          this.saveStatus.emit(event);
          this.isSaveDisabled = true;
          this.setState(ComponentState.ready);
          this.saveLoaderStatus.emit(false);
        } else {
          this.setState(ComponentState.empty);
        }
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }

  sortStream(stream) {
    return stream.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
  }

  checkSaveStatus() {
    let opFormStatus;
    let wcFormStatus;
    let unit = JSON.parse(this.initialData).ScenarioUnit;
    this.initialData = JSON.parse(this.initialData);
    this.initialData.InputStream = this.sortStream(this.initialData.InputStream);
    this.updatedData.InputStream = this.sortStream(this.updatedData.InputStream);
    this.initialData = JSON.stringify(this.initialData);
    if (unit !== this.updatedData.ScenarioUnit && this.dosageAndRecommendationForm.status == VALID) {
      this.isSaveDisabled = false;
    } else if (this.initialData != JSON.stringify(this.updatedData)) {
      if ((this.allForms == null && !this.dosageAndRecommendationForm.pristine && (this.dosageAndRecommendationForm.status == VALID)) || !this.oipScenario) {
        this.isSaveDisabled = false;
      } else if (this.allForms !== null) {
        opFormStatus = this.allForms.operatingParameters !== undefined ?
          !this.allForms.operatingParameters.pristine && (this.allForms.operatingParameters.status == VALID) : true;
        wcFormStatus = this.allForms.waterChemistry !== undefined ?
          !this.allForms.waterChemistry.pristine && (this.allForms.waterChemistry.status == VALID) : true;
        if (opFormStatus && wcFormStatus && (this.dosageAndRecommendationForm.status == VALID)) {
          this.isSaveDisabled = false;
        } else {
          this.isSaveDisabled = true;
        }
      } else {
        this.isSaveDisabled = true;
      }
    } else {
      this.isSaveDisabled = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scenarioData && changes.scenarioData.currentValue) {
      this.updatedData = JSON.parse(JSON.stringify(changes.scenarioData.currentValue));
      this.initialData = JSON.stringify(changes.scenarioData.currentValue);
      this.roService.preferredUnitChangeEvnt.subscribe(x => {
        this.updatedData.ScenarioUnit = x;
      });
      this.waterAnalysis = (this.updatedData.OperatingParameters.filter(x => x.Name == "WaterAnalysisType"))[0].Value;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.roService.setForms(null);
    this.formSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
    this.calculatedParameters.unsubscribe();
  }

}