export class NavigateDV {
    ServiceTypeId? = '0';
    TimeRange?= '0';
    StartDate?= '';
    StartTime?= '';
    EndDate?= '';
    EndTime?= '';
    SiteId?= '0';
    SystemId?= '0';
    AssetId?= '0';
    MeasurementId?= '0';
    StuiId?= '0';
    StuiTypeId?= '0';
    MeasurementInstanceId1?= '0';
    MeasurementInstanceId2?= '0';
    MeasurementInstanceId3?= '0';
    MeasurementInstanceId4?= '0';
    MeasurementInstanceId5?= '0';
    MeasurementInstanceId6?= '0';
    assetMeasurementInstanceId: number;
    PortSubTypeId? = '0';  
    NavigationSource?: string = undefined;
    SystemName?: string = '';
    AssetName?: string = '';
    constructor(data?) {
        if (data) {
            this.ServiceTypeId = data.ServiceTypeId;
            this.TimeRange = data.TimeRange;
            this.StartDate = data.StartDate;
            this.StartTime = data.StartTime;
            this.EndDate = data.EndDate;
            this.EndTime = data.EndTime;
            this.SiteId = data.SiteId;
            this.SystemId = data.SystemId;
            this.AssetId = data.AssetId;
            this.MeasurementId = data.MeasurementId;
            this.StuiId = data.StuiId;
            this.StuiTypeId = data.StuiTypeId;
            this.assetMeasurementInstanceId = data.AssetMeasurementInstanceId;
            this.MeasurementInstanceId1 = data.MeasurementInstanceId1;
            this.MeasurementInstanceId2 = data.MeasurementInstanceId2;
            this.MeasurementInstanceId3 = data.MeasurementInstanceId3;
            this.MeasurementInstanceId4 = data.MeasurementInstanceId4;
            this.MeasurementInstanceId5 = data.MeasurementInstanceId5;
            this.MeasurementInstanceId6 = data.MeasurementInstanceId6;
            this.PortSubTypeId = data.PortSubTypeId;
        }
    }
}
