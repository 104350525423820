import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { UserSettings } from '../../../shared/models/User';
import { USERROLES } from '../../../app.config';

@Injectable()
export class AssetTreeGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,private userService: UserService) { }

    canActivate(): Observable<boolean> {
        const user: UserSettings = this.userService.getCurrentSettings();
        let hasATPermission = false;
        user.Permissions.forEach( p => {
            if(p.PermissionId.toString() === USERROLES.ASSETTREEADMIN){
                hasATPermission = true;
            }
        });
        if (hasATPermission) {
            return Observable.of(hasATPermission);
        } else {
            this.router.navigate(['/access-denied']);
        }
    }

    canActivateChild(): Observable<boolean>{
        return this.canActivate();
    }
}