import { Injectable } from '@angular/core';
import { APP_CONFIG, ENVIRONMENTS } from '../../app.config';
import type { IConfig } from '../_contracts/IConfig';

@Injectable()
export class NotificationServiceConfig {

  private configMock: IConfig = { // MOCK - local json settings
    environment: ENVIRONMENTS.mock,
    baseUrl: '',
    apiUrl: {
      getAllNotification: 'api/v1/Notifications/GetUnreadNotifications',
      getDataSourceMappingDetails: 'api/v1/ContextPoints/DataSourceMappings/{0}/{1}/{2}',
      copyMappingToDatasource: 'api/v1/AssetHarvester/CopyMapping',
      retryReplicateMeasurementsAndLimits: 'MeasurementAggregatorService/api/v1/RetryReplicateMeasurementsAndLimits',
    }
  };

  private configProd: IConfig = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
    environment: ENVIRONMENTS.prod,
    baseUrl: '',
    apiUrl: {
      getAllNotification: 'api/v1/Notifications/GetUnreadNotifications',
      getDataSourceMappingDetails: 'api/v1/ContextPoints/DataSourceMappings/{0}/{1}/{2}',
      copyMappingToDatasource: 'api/v1/AssetHarvester/CopyMapping',
      retryReplicateMeasurementsAndLimits: 'MeasurementAggregatorService/api/v1/RetryReplicateMeasurementsAndLimits',
    }
  };

  private _environment: string = APP_CONFIG.ENVIRONMENT;

  /**
   * @description function to return the environment configured
   * @returns IConfig
   */
  public getEnvironment(): IConfig {
    switch (this._environment) {
      case ENVIRONMENTS.mock:
        return this.configMock;
      case ENVIRONMENTS.dev:
        return this.configProd;
      case ENVIRONMENTS.test:
        return this.configProd;
      case ENVIRONMENTS.stg:
        return this.configProd;
      case ENVIRONMENTS.prod:
        return this.configProd;
      default:
        return null;
    }
  }

  /**
   * @description function to set the environment configured
   * @param environment which environment it is configured
   * @returns void
   */
  public setEnvironment(environment: string): void {
    this._environment = environment;
  }

}
