<div [ngClass]="[tableClass ? tableClass : '']" slimScroll class="custom-table-responsive">
  <table class="table custom-reading-table custom-reading-table-scroll">
    <thead>
      <tr>
        <th *ngIf="enableSorting && isEditModeEnabled" class="sort-icon"></th>
        <th *ngFor="let header of columns" 
            [ngStyle]="{width: header.width ? header.width : 'auto'}"
            [ngClass]="[header.thClass ? header.thClass : '', header.sortable ? 'cursorChange' : '' , header.sortable && header.isLatestSorted && header.isDescSort ? 'pointerAsc' : header.sortable && header.isLatestSorted && !header.isDescSort ? 'pointerDesc' : '']" 
            [hidden]="header.isHidden"
            (click)="sortByColumn(header, true)">
          <span *ngIf="header.type != 'array'" 
            [ngClass]="{'header-label': true}">
            {{header.label + ' '}}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of getRows();let i = index" class="custom-grid-page-break" [ngClass]="{'drop': draggedRowIndex && draggedRowIndex === i}">
        <td *ngIf="enableSorting && isEditModeEnabled" (dragstart)="dragStart($event, i)" draggable="true"
        (dragenter)="dragEnter($event,i)" (dragend)="dragEnd()" class="sort-icon all-scroll"><span class="custom-icon"></span></td>
        <td *ngFor="let column of columns" class="order-column required-chinese-font" 
            [hidden]="column.isHidden"
            [ngStyle]="{width: column.width ? column.width : 'auto'}" 
            [ngClass]="[column.tdClass ? column.tdClass : '']">
          <span [ngSwitch]="column.type">
            <span *ngSwitchCase="'link'">
              <span class="column-label link" [title]="row[column.field]" (click)="linkClicked(row)">
                {{row[column.field]}}
              </span>
            </span>
            <span *ngSwitchCase="'array'">
              <span *ngFor="let arrayItem of row[column.field]" class="column-label array-item">
                {{arrayItem[column.arrayField]}}
              </span>
            </span>
            <span *ngSwitchCase="'string'">
              <span class="column-label array-item">
                {{row[column.field]}}
              </span>
            </span>
            <span *ngSwitchCase="'status-color'">
              <span [class]="getIconClassForTable(row[column.field])"></span>
            </span>
            <span *ngSwitchCase="'status-color-value'" class="row status-span-value">
              <span [class]="getIconClassForTable(row[column.field][0].color)"></span> 
              <span class="col status-number-value" [style.font-size.rem]="checkNumericValueFont(row[column.field][0].val) ? 0.85 : 0.75" >{{row[column.field][0].val}}</span>
            </span>
            <span *ngSwitchCase="'number'">
              <span class="column-label array-item">
                {{row[column.field]}}
              </span>
            </span>
            <span *ngSwitchCase="'readingDate'">
              <span class="column-label array-item">
                {{row[column.field] ? (row[column.field]  | dateFormat :
                  true : false) : ''}}
              </span> <br>
              <span class="column-label">
                {{row[column.readingTime]}}
              </span>
            </span>
            <span *ngSwitchCase="'dataSource'">
              <span [class]="getIconClassForDataSource(row[column.field])" class="column-label array-item">
                {{row[column.field] ? row[column.field] : ''}}
              </span>
            </span>
            <span *ngSwitchDefault [ngClass]="{'column-label':true}" [title]="row[column.field]">
              {{row[column.field]}}
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>