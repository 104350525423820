import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssetHarvesterService } from '../../../asset-harvester/_services/asset-harvester.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SharedService } from '../../services/shared-services.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'nalco-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  dagId: number;
  accountname: any;
  sitename: any;
  isLoading: boolean;
  showOnboardingCheckbox: boolean = true;
  disableSave: boolean = false;
  subscriptions: Array<Subscription> = [];
  accountDetails: Array<{
    AccountNumber: string,
    CustomerName: string,
    Location: string
  }> = [];
  IsOnboardingPersistOldValue: boolean;
  IsOnboarding: boolean;
  @Output() onBoradingEvent ? = new EventEmitter<any>();
  IsTrialFlag: boolean;
  IsTrialFlagPersistOldValue: boolean;
  siteId: number;

  constructor(private translate: TranslateService,
     public activeModal: NgbActiveModal,
     private assetHarvesterService: AssetHarvesterService,
     private sharedService: SharedService,
     private localCacheService: LocalCacheService) {
  }
  ngOnInit() {
    this.dagId = JSON.parse(this.localCacheService.fetchData('dagId'));
    this.getAccountDetails();
    const userPermissions = JSON.parse(this.localCacheService.fetchData('msalUserInfo')).Permissions;
    const userPermissionFlag = userPermissions.findIndex(x => x.UserPermissionTypeId === 58);
    if ((this.assetHarvesterService.readOnly && !this.assetHarvesterService.writeAlso) || userPermissionFlag === -1) {
      this.showOnboardingCheckbox = false;
    }
    //this.getOnBoarding();
  }

  getAccountDetails() {
   this.accountDetails = [];
   this.isLoading = true;
   this.IsOnboarding = false;
   this.IsTrialFlag = false;
   this.dagId = JSON.parse(this.localCacheService.fetchData('dagId'));
    this.assetHarvesterService.getAccountsByDag(this.dagId)
    .subscribe(response => {
      if (response !== null){
        let detailsInfo = JSON.parse(JSON.stringify(response));
        this.accountname = detailsInfo[0].CorporateAccountName;
        this.sitename = detailsInfo[0].SiteName;

        detailsInfo.forEach(element =>
          this.accountDetails.push({
            ​​   AccountNumber: element.SoldToShipTo,
               CustomerName: element.AccountName,
               Location: element.CustomerLocation
               }​​
            )
        );
      }
      this.isLoading = false;
    });

    if (JSON.parse(this.localCacheService.fetchData('siteId'))) {
      this.siteId = JSON.parse(this.localCacheService.fetchData('siteId'));
          // get on boarding details
          this.sharedService.getPlannedAssets(this.siteId, false).subscribe(response => {
            if (response) {
              this.IsOnboarding = response.IsOnboarding;
              this.IsOnboardingPersistOldValue = response.IsOnboarding;
              this.IsTrialFlag = response.IsTrialEnabled ? true : false;
              this.IsTrialFlagPersistOldValue = response.IsTrialEnabled ? true : false;
            }});
    }
  }
  saveOnBoarding() {
        // get on boarding details
        this.isLoading = true;

        this.assetHarvesterService.postOnBoardingByDag(this.dagId, this.IsOnboarding, this.IsTrialFlag).subscribe(res => {
          const data = {
            'ContextPointId': this.dagId,
            'IsOnboardingFlag': this.IsOnboarding,
            'IsTrialFlag': this.IsTrialFlag
          };
          this.onBoradingEvent.emit(data);
          this.activeModal.close();
          this.isLoading = false;
          const key = this.siteId;
          this.sharedService.clearCachedAssets(key);
          this.sharedService.onBoardingUpdated.next();
        }, error => {
          this.isLoading = false;
        });

  }
  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
