<div class="dd-integrated-container select-integrated" [ngClass]="isDisable ? 'disable' : 'enable'">
	<div class="dd-control-container" (click)="toggle();onDropdownClick()" tabindex="0" [ngClass]="{'hidden': isListHide, 'shown':!isListHide, 'no-pointer-event':disableDd}"
		#displayLabel>
		<span class="dd-display-item overflow-ellipsis text-transform-none" role="textbox" #element2 placement='bottom'
			[ngbTooltip]="(element2.offsetWidth+1 < element2.scrollWidth) ? display : '' "
			[ngClass]="displayOnInit.length==0 && virtualMeterFlag ? 'placeholderContent' : ''">
			<div for="floatField" class="dropdown-heading" *ngIf="headerValue !== undefined">{{headerValue}}</div>
			{{display}}
		</span>
	</div>
	<div class="dd-list-container" [hidden]="isListHide">
		<div class="search-box" *ngIf="showSearchBox"
			[ngClass]="(items.length === 0 && !showLoader && searchText == '') ? 'disable' : 'enable'">
			<span class="ico-nav-search mb-0">
			</span>
			<input type="text" class="drop-input" [disabled]="(items.length === 0 && !showLoader && searchText == '')"
				placeholder="{{'COMMON.SEARCH' | translate}}" [(ngModel)]="searchText" (keyup)="onKeyUp(searchText)"
				(keypress)="onKeyPress($event)" #filterInput>
		</div>

		<div class="list-items position-relative">
			<div class="filterByCategory" *ngIf="showFilterBycategory">
				<div ngbDropdown>
					<button class="btn btn-outline-primary drop-btn" id="dropdownConfig" ngbDropdownToggle>
						{{'COMMON.FILTER_BY_CATEGORY' | translate}}
					</button>
					<div ngbDropdownMenu aria-labelledby="dropdownConfig" container="body">
						<button class="dropdown-item" *ngIf="categoryList.length === 0"
							[innerHTML]="noMatchingCategory"></button>
						<span *ngFor="let option of categoryList" class="d-block">
							<label class="form-check-label radio-items overflow-ellipsis" container="body" #element3
								placement='bottom'
								[ngbTooltip]="(element3.offsetWidth+1 < element3.scrollWidth) ? option.text : '' ">
								<input type='checkbox' [checked]="option.checked" class="form-check-input"
									(change)="onCategoryChange(option)">
								<span></span>
								{{option.text}}
							</label>
						</span>
					</div>
				</div>
			</div>
			<div *ngIf="showLoader  || (items | textCategorySearch : searchText : selectedCategory : true)?.length != 0"
				[attr.data-dd-value]="{id:'', display:'Select'}" class="dd-select-list">
				<!-- <label class="validation-message"
					*ngIf="multiSelection && multiselectedItems.length >= 4 && selectionLimitOverMsg ">Limit of four
					selected
					source/sample points reached. Remove some to add others.</label> -->
				{{placeholder}}
			</div>
			<div (click)="checkBoxClick($event)" #scroll class="dd-items-container">
				<div #listItems [ngClass]=" { 'dd-active dd-hightlight-item' :isSelected(item)} "
					class="dd-list-item overflow-ellipsis text-transform-none" *ngFor="let item
				 of items | textCategorySearch : searchText : selectedCategory : true; let i=index " #element4 placement='bottom'
					[ngbTooltip]="(element4.offsetWidth+1 < element4.scrollWidth) ? item?.display : '' "
					 [attr.data-dd-value]="stringify(item)" (mouseover)="onHover($event) "
					(mouseout)="onHover($event) ">
					<label class="form-check-label radio-items" *ngIf="!multiSelection">
						<input type='checkbox' class="form-check-input" [checked]="item.checked"
							(change)="onItemSelect(item,$event)">
						<span></span>
						{{item?.display}}
					</label>
					<label #selectAll class="form-check-label checkbox-items d-block mb-3" *ngIf="multiSelection && i===0 && isSelectAllAvailable && allOption" [ngClass]="{'disabledItems' : addEditMode==='edit'}">
						<input type='checkbox' [disabled]="(disableRecordsFlag && item.checked === false) ||  item.disable === true || addEditMode==='edit'"
							[checked]="isSelectAllChecked(selectAll) || isSelectAllSelected" (change)="onSelectAll($event)">
						<span *ngIf="item.isMapped" class="float-right legend-text"></span>
						<span class="checkmark"></span>
						{{multiselectText}}
					</label>
					<label class="form-check-label checkbox-items" *ngIf="multiSelection" [ngClass]="{'disabledItems' : item.disable === true}">
						<input type='checkbox' [disabled]="(disableRecordsFlag && item.checked === false) ||  item.disable === true"
							[checked]="item.checked" (change)="onItemSelect(item,$event)">
						<span *ngIf="item.isMapped" class="float-right legend-text"></span>
						<span class="checkmark"></span>
						<span class="legend-text" *ngIf="item?.isMappedNxcOsmosisReverseTrain"></span><!--Added pin icon for already mapped measurement-->
						{{item?.display}}
					</label>
				</div>
				<div class="dd-select-list"
					*ngIf="items.length > 0 && (items | textCategorySearch : searchText : selectedCategory : true)?.length === 0 && !showLoader ">
					{{noRecoredsLabel}}
				</div>
				<div class="dd-select-list" *ngIf="items.length === 0 && !showLoader && searchText == ''">
					{{noMatchingRecoredsLabel}}
				</div>
				<span class="loader" *ngIf="showLoader"></span>
			</div>
			<div [ngClass]="{'disabled': disableClearSelection}">
				<div *ngIf="showClearSelectionFlag" [attr.data-dd-value]="{id:'', display:'Select'}" class="dd-clear-list" (click)="onClear()">{{'COMMON.CLEAR_SELECTION' | translate}}
				</div>
			</div>
		</div>

		<!-- <div><p class="drop-input">Clear Selection</p></div> -->
	</div>

</div>