import type { ModuleWithProviders } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { USERROLES } from './app.config';

import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LogoutComponent } from '../app/core/auth/logout/logout.component';
import { UserSettingsResolverService } from '../app/core/auth/resolver/user-settings.resolver';
import { SiteOverviewComponent } from './asset-harvester/site-overview/site-overview.component';
import { AuthenticationGuard } from './core/auth/guards/authentication-guard';
import { AuthorizationGuard } from './core/auth/guards/authorization-guard';
import { LoginComponent } from './core/auth/login/login.component';
import { ApplicationDowntimeComponent } from './core/components/application-downtime/application-downtime.component';
import { ConsentPageComponent } from './core/components/consent-page/consent-page.component';
import { HomeComponent } from './core/components/home/home.component';
import { ErrorPageComponent } from './core/fallback-modules/error-page/error-page.component';
import { FnbComponent } from './shared/components/fnb/fnb.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const ADRUM: unknown;

// BASE APP ROUTE
const APP_ROUTES: Routes = <Routes>[
  // for default redirect never put prefix for pathMatch
  // it should be always full, so that it will apply for once
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard, AuthenticationGuard],
    pathMatch: 'full',
    data: {
      hideSubHeader: true,
    },
  },

  {
    path: 'logout',
    component: LogoutComponent,
    pathMatch: 'full',
  },
  // { path: 'assettree', redirectTo: 'asset-tree', pathMatch: 'full' },

  {
    path: 'login',
    component: LoginComponent,
    resolve: { UserSettings: UserSettingsResolverService },
    canActivate: [MsalGuard]
  },
  {
    // for MSAL gettting the token
    path: 'code',
    component: LoginComponent,
    canActivate: [MsalGuard]
  },
  {
    // for Global search
    path: 'global-search',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./shared-ui-refresh/global-ai-search-dropdown/global-ai-search-dropdown.module').then(m => m.GlobalAiSearchDropdownModule),
  },
  {
    path: 'asset-tree',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./asset-tree/asset-tree.module').then(m => m.AssetTreeModule),
    data: {
      // this will allow all the users who have access to just AH see the Asset Tree also
      // security needs to be implemented in AT screen to restrict
      origin: 'at',
      roles: [
        USERROLES.ASSETTREEADMIN,
        USERROLES.ASSETHARVESTERREAD,
        USERROLES.ASSETHARVESTERREADWRITE,
      ],
      globalIdleEnabled: false,
    },
  },
  {
    path: 'assetharvester',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./asset-tree/asset-tree.module').then(m => m.AssetTreeModule),
    data: {
      // this will allow all the users who have access to just AH see the Asset Tree also
      // security needs to be implemented in AT screen to restrict
      origin: 'ah',
      roles: [
        USERROLES.ASSETTREEADMIN,
        USERROLES.ASSETHARVESTERREAD,
        USERROLES.ASSETHARVESTERREADWRITE,
      ],
      globalIdleEnabled: false,
    },
  },
  {
    path: 'assetharvester-manage-sites',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./AH-manage-sites/AH-manage-sites.module').then(m => m.AHManageSitesModule),

    data: {
      hideSubHeader: false,
      roles: [
      ]
    },
  },
  {
    path: 'credential-management',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./credential-management/credential-management.module').then(m => m.CredentialManagementModule),
    data: {
      breadcrumb: 'Device Credential Management',
      roles: [USERROLES.CREDMGMT],
      globalIdleEnabled: false
    }
  },
  {
    path: 'manual-equipment-creation',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./manual-equipment-creation/manual-equipment-creation.module').then(m => m.ManualEquipmentCreationModule),
    data: {
      breadcrumb: 'Manual Asset Creation',
      globalIdleEnabled: false
    }
  },
  {
    path: 'offline-data-upload',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./offline-data-upload/offline-data-upload.module').then(m => m.OfflineDataUploadModule),
    data: {
      breadcrumb: 'Offline Data Upload',
      roles: [USERROLES.ENVISION4WEB], //Need to check,
      path: 'offline-data-upload',
    },
  },
  {
    path: 'controller-management',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import(
      './controller-management/controller-management.module').then(m => m.ControllerManagementModule),
    data: {
      breadcrumb: 'Controller Management',
      roles: [USERROLES.ASSETHARVESTERREADWRITE, USERROLES.ENVISIONSALES], //Need to check,
      path: 'controller-management',
    },
  },
  {
    path: 'shared-ui-components',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import(
      './shared-ui-components/ui-components.module').then(m => m.TestSharedModule),
    data: {
      breadcrumb: 'Test Shared',
      roles: [USERROLES.ASSETHARVESTERREADWRITE, USERROLES.ENVISIONSALES], //Need to check,
      path: 'shared-ui-components',
    },
  },
  {
    path: 'recycle-intelligence-configuration',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./recycle-intelligence-configuration/recycle-intelligence-configuration.module').then(m => m.RecycleIntelligenceConfigurationModule),
    data: {
      breadcrumb: "Recycle Intelligence Configuration",
      path: 'recycle-intelligence-configuration',
      roles: [USERROLES.RECYCLE_INTELLIGENCE_ADMIN]
    }
  },
  {
    path: 'logo-management',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./service-summary/admin/service-summary-admin.module').then(m => m.ServiceSummaryAdminModule),
    data: {
      breadcrumb: 'Logo Management',
      path: 'logo-management',
      roles: [USERROLES.SSD_LOGO_MANAGEMENT],
    },
  },
  {
    path: 'assetharvester',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./asset-harvester/asset-harvester.module').then(m => m.AssetHarvesterModule),
    data: {
      roles: [USERROLES.ASSETHARVESTERREAD, USERROLES.ASSETHARVESTERREADWRITE],
      globalIdleEnabled: false,
    },
  },
  {
    path: 'water-safety',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./water-safety/water-safety.module').then(m => m.WaterSafetyModule),
    data: {
      breadcrumb: 'Enterprise Overview',
      roles: [USERROLES.WSIDASHBOARD],
    },
  },
  {
    path: 'quality',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./quality/quality.module').then(m => m.QualityModule),
    data: {
      breadcrumb: 'Water Quality',
      roles: [USERROLES.WQDASHBOARD],
    },
  },
  // asset details.
  {
    path: 'asset-details',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./asset-details/asset-details.module').then(m => m.AssetDetailsModule),
    data: {
      breadcrumb: 'Asset Details',
      path: 'asset-details',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  //////equipment manager
  {
    path: 'equipment-manager',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./equipment-manager/equipment-manager.module').then(m => m.EquipmentManagerModule),
    data: {
      breadcrumb: 'Equipment-Manager',
      path: 'equipment-manager',
      roles: [USERROLES.ASSETHARVESTERDECOMMISSIONWRITE],
    },
  },
  // linked omni module.
  {
    path: 'omni',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./omni/omni.module').then(m => m.OmniModule),
    data: {
      breadcrumb: 'Omni',
      path: 'omni',
      roles: [USERROLES.OMNIDASHBOARD /*USERROLES.OMNIDASHBOARD*/],
    },
  },
  {
    path: 'ro-optimizer',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./ro-optimizer/ro-optimizer.module').then(m => m.RoOptimizerModule),
    data: {
      breadcrumb: 'RO Optimizer',
      roles: [USERROLES.RODASHBOARD],
    },
  },
  {
    path: 'recycle-intelligence',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./recycle-intelligence/recycle-intelligence.module').then(m => m.RecycleIntelligenceModule),
    data: {
      breadcrumb: "Recycle Intelligence",
      path: 'recycle-intelligence',
      roles: [USERROLES.RECYCLE_INTELLIGENCE]
    }
  },
  {
    path: 'climate-intelligence',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./climate-intelligence/enterprise-overview/enterprise-overview.module').then(m => m.EnterpriseOverviewModule),
    data: {
      breadcrumb: "Climate-Intelligence",
      path: 'climate-intelligence',
      roles: [USERROLES.CLIMATE_INTELLIGENCE]
    }
  },
  {
    path: 'crude-flex',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./crude-flex/crude-flex.module').then(m => m.CrudeFlexModule),
    data: {
      breadcrumb: "Crude-flex",
      path: 'crude-flex',
      roles: [USERROLES.FEED_FLEX],
      hideSubHeader: true
    }
  },
  {
    path: 'inventory',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    data: {
      breadcrumb: "Inventory",
      path: 'inventory',
      roles: [USERROLES.INVENTORY]
    }
  },
  {
    path: 'climate-intelligence/admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./climate-intelligence/admin/admin.module').then(m => m.AdminModule),
    //  component: FinancialImpactAdminComponent,
    //  pathMatch: 'prefix',
    data: {
      breadcrumb: 'Climate-Intelligence Admin',
      path: 'climate-intelligence/admin',
      roles: [USERROLES.CLIMATE_INTELLIGENCE_ADMIN /*USERROLES.CIADMIN*/],
    },
  },
  {
    path: 'system-assurance/admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./system-assurance-admin/system-assurance-admin.module').then(m => m.SystemAssuranceAdminModule),
    data: {
      breadcrumb: 'System Assurance',
      roles: [USERROLES.SAADMIN],
    },
  },
  {
    path: 'omni/admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./omni/admin/admin.module').then(m => m.AdminModule),
    //  component: FinancialImpactAdminComponent,
    //  pathMatch: 'prefix',
    data: {
      breadcrumb: 'Omni Admin',
      path: 'omni/admin',
      roles: [USERROLES.OMNIADMIN /*USERROLES.FIADMIN*/],
    },
  },
  {
    path: 'financial-impact',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./financial-impact/financial-impact.module').then(m => m.FinancialImpactModule),
    data: {
      breadcrumb: 'Financial Impact',
      path: 'financial-impact',
      roles: [USERROLES.FIDASHBOARD],
    },
  },
  {
    path: 'financial-impact/admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./financial-impact/admin/admin.module').then(m => m.AdminModule),
    //  component: FinancialImpactAdminComponent,
    //  pathMatch: 'prefix',
    data: {
      breadcrumb: 'Financial Impact Admin',
      path: 'financial-impact/admin',
      roles: [USERROLES.FIADMIN],
    },
  },
  {
    path: 'data-visualization-old',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./data-visualization/data-visualization.module').then(m => m.DataVisualizationModule),
    // data: {
    //   breadcrumb: 'Data Visualization-old',
    //   path: 'data-visualization',
    //   roles: [USERROLES.VISDASHBOARD]
    // }
  },
  {
    path: 'data-visualization',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./data-visualization-library/data-visualization-library.module').then(m => m.DataVisualizationLibraryModule),
    data: {
      breadcrumb: 'Data Visualization',
      path: 'data-visualization',
      roles: [USERROLES.VISDASHBOARD],
    },
  },
  {
    path: 'data-viz-plot-chart',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./data-viz-plot-chart/data-viz-plot-chart.module').then(m => m.DataVizPlotChartModule),
    data: {
      breadcrumb: 'Data Visualization',
      path: 'data-viz-plot-chart',
      roles: [USERROLES.VISDASHBOARD],
    },
  },
  {
    path: 'water-flow-intelligence',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./water-flow-intelligence/water-flow-intelligence.module').then(m => m.WaterFlowIntelligenceModule),
    data: {
      breadcrumb: 'Water Flow Intelligence',
      path: 'water-flow-intelligence',
      roles: [USERROLES.WFIDASHBOARD],
    },
  },
  {
    path: 'water-flow-intelligence/admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./water-flow-intelligence/admin/admin.module').then(m => m.AdminModule),
    data: {
      breadcrumb: 'Water flow Intelligence Admin',
      path: 'water-flow-intelligence/admin',
      roles: [USERROLES.WFISENSORMAPPING],
    },
  },
  {
    path: 'notifications-admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./notifications-admin/notifications-admin.module').then(m => m.NotificationsAdminModule),
    data: {
      breadcrumb: 'Notifications Admin',
      path: 'notifications-admin',
      roles: [
        USERROLES.NOTIFICATIONS_ADMIN_INTERNAL,
        USERROLES.NOTIFICATIONS_ADMIN_OWN,
        USERROLES.NOTIFICATIONS_CUSTOMER_FULL,
        USERROLES.NOTIFICATIONS_CUSTOMER_ADD,
      ],
    },
  },
  {
    path: 'controller-credentials',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./controller-credentials/controller-credentials.module').then(m => m.ControllerCredentialsModule),
    data: {
      breadcrumb: 'Controller Credentials',
      path: 'controller-credentials',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'action-log',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./action-log/action-log.module').then(m => m.ActionLogModule),
    data: {
      breadcrumb: 'Action and Recommendations',
      path: 'action-log',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  // New UX Action and Recommendations Page
  {
    path: 'action-recommendations',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./actions-and-recommendations/actions-and-recommendations.module').then(m => m.ActionsAndRecommendationsModule),
    data: {
      breadcrumb: 'Action & Recommendations',
      path: 'action-recommendations',
      roles: [USERROLES.ENVISION4WEB],
      hideSubHeader: true,
    },
  },
  {
    path: 'my-documents',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./my-documents/my-documents.module').then(m => m.MyDocumentsModule),
    data: {
      breadcrumb: 'My Documents',
      path: 'my-documents',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'digital-twin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./digital-twin/digital-twin.module').then(m => m.DigitalTwinModule),
    data: {
      hideLayout: true,
      // roles: [USERROLES.WQDASHBOARD,USERROLES.OMNIDASHBOARD]
    },
  },
  {
    // eslint-disable-next-line max-len
    path: 'navigate-data-viz',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./navigate-data-viz/navigate-data-viz.module').then(m => m.NavigateDataVizModule),
    data: {
      breadcrumb: 'Data Visualization',
      path: 'navigate-data-viz',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    // eslint-disable-next-line max-len
    path: 'service-summary',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren:
      () => import('./service-summary/service-summary.module').then(m => m.ServiceSummaryModule),
    data: {
      breadcrumb: 'service-summary',
      path: 'service-summary',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    // eslint-disable-next-line max-len
    path: 'fnb',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    data: {
      breadcrumb: 'F & B',
      path: 'fnb',
      roles: [
        USERROLES.ASSETTREEADMIN,
        USERROLES.ASSETHARVESTERREAD,
        USERROLES.ASSETHARVESTERREADWRITE,
      ],
    },
    component: FnbComponent,
  },
  {
    path: 'common-pages',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./common-pages/common-pages.module').then(m => m.CommonPagesModule),
    data: {
      breadcrumb: 'Common Pages',
      path: 'common-pages',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'scale-guard-index',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./scale-guard-index/sgi.module').then(m => m.ScaleGuardIndexModule),
    data: {
      breadcrumb: 'Scale Guard Index',
      path: 'scale-guard-index',
      roles: [USERROLES.SGI_ADMIN_ACCESS, USERROLES.SGI_READ_ACCESS],
    },
  },
  {
    path: 'water-balance-intelligence',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./water-balance-intelligence/wbi.module').then(m => m.WBIModule),
    data: {
      breadcrumb: 'Water Balance Intelligence',
      path: 'water-balance-intelligence',
      roles: [USERROLES.WBI_READ_ACCESS],
    },
  },
  {
    path: 'ssd-analyzer',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./ssd-analyzer/ssd-analyzer.module').then(m => m.SsdAnalyzerModule),
    data: {
      breadcrumb: 'SSD Analyzer',
      path: 'ssd-analyzer',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'ssd-poc',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./ssd-poc/ssd-poc.module').then(m => m.SsdPocModule),
    data: {
      breadcrumb: 'Sample Number Mapping',
      path: 'sample-number-mapping',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'e-data-entry',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./e-data-entry/e-data-entry.module').then(m => m.EDATAModule),
    data: {
      breadcrumb: 'E Data',
      path: 'e-data-entry',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'fsqs',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./fsqs/fsqs.module').then(m => m.FsqsModule),
    data: {
      roles: [USERROLES.FSQS_PROCEDURE_WRITE,
      USERROLES.FSQS_PROCEDURE_READ,
      USERROLES.FSQS_AH_LOCATION_READ,
      USERROLES.FSQS_AH_LOCATION_WRITE,
      USERROLES.FSQS_PROCEDURE_LOG_READ,
      USERROLES.FSQS_PROCEDURE_LOG_WRITE,
      USERROLES.FSQS_EMPSERVICE_DASHBOARD,
      USERROLES.FSQS_PTSSERVICE_DASHBOARD,
      USERROLES.SERVICE_PLAN_LOG_READ,
      USERROLES.SERVICE_PLAN_LOG_WRITE,
      USERROLES.SERVICE_PLAN_READ,
      USERROLES.SERVICE_PLAN_WRITE
      ],
    },
  },
  {
    path: 'admin-alarm',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./admin-alarm/admin-alarm.module').then(m => m.AdminAlarmModule),
    data: {
      path: 'admin-alarm',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'alarm-user-mgmt',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./alarm-user-mgmt/alarm-user-mgmt.module').then(m => m.AlarmUserMgmtModule),
    data: {
      path: 'alarm-user-mgmt',
      roles: [USERROLES.ENVISION4WEB],
    },
  },
  {
    path: 'WFI-alarm',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./wfi-alarm/wfi-alarm.module').then(m => m.WfiAlarmModule),
    data: {
      path: 'WFI-alarm',
      roles: [USERROLES.ENVISION4WEB],
    },
  },

  {
    path: 'connectivity',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./connectivity/connectivity.module').then(m => m.ConnectivityModule),
    data: {
      breadcrumb: "Connectivity",
      path: 'connectivity',
      roles: [USERROLES.IOTCWP] ////////need change
    }
  },
  {
    path: 'my-territory',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./my-territory/my-territory.module').then(m => m.MyTerritoryModule),
    data: {
      breadcrumb: 'My Territory',
      path: 'my-territory',
      roles: [USERROLES.MTV_ACCESS],
    },
  },
  {
    path: 'total-booth-management',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./total-booth-management/total-booth-management.module').then(m => m.TBMModule),
    data: {
      breadcrumb: "total-booth-management",
      path: 'total-booth-management',
      roles: [USERROLES.TOTAL_BOOTH_MANAGEMENT]
    }
  },
  {
    path: 'paper',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./paper/paper.module').then(m=>m.PaperModule),
    data: {
      breadcrumb: "paper",
      path: 'paper',
      roles: [USERROLES.PAPER,
              USERROLES.Paper_YOI,
              USERROLES.Paper_PWI,
              USERROLES.Paper_RBLI,
      ]
    }
  },
  {
    path: 'manual-equipment-creation',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./manual-equipment-creation/manual-equipment-creation.module').then(m => m.ManualEquipmentCreationModule),
    data: {
      breadcrumb: 'Manual Asset Creation',
      globalIdleEnabled: false
    }
  },
  {
    path: 'water-for-climate',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: () => import('./water-for-climate/water-for-climate.module').then(m => m.WaterForClimateModule),
    data: {
      breadcrumb: 'Water for climate',
      path: 'water-for-climate',
      roles: ["116"],
    }
  },
  /* Commenting as this feature is on hold
  {
    path: 'platform-notifications-admin',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
    loadChildren: ()=> import('./platform-notifications-admin/platform-notifications-admin.module').then(m=>m.PlatformNotificationsAdminModule),
    data: {
      breadcrumb: 'Platform Notifications Admin',
      path: 'platform-notifications-admin',
      roles: [USERROLES.PFMNFTADMN],
    },
  },
  */
  {
    path: 'consent-page',
    component: ConsentPageComponent,
    pathMatch: 'prefix',
  },
  {
    path: 'downtime',
    component: ApplicationDowntimeComponent,
    pathMatch: 'prefix',
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
    pathMatch: 'prefix',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard]

  },
  {
    path: '**',
    component: ErrorPageComponent,
    pathMatch: 'prefix',
    canActivate: [MsalGuard, AuthenticationGuard, AuthorizationGuard],
  }

];

export const APP_ROUTER: ModuleWithProviders<any> = RouterModule.forRoot(
  APP_ROUTES,
  {
    // preloadingStrategy: PreloadAllModules,
    useHash: true,
    // enableTracing: true
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? 'enabledNonBlocking'
        : 'disabled', // Set to enabledBlocking to use Angular Universal
  })


/*
export class AppRoutingModule {
  vpView: any;

  // Subscribe to the Router URL changes and report the metrics to AppDynamics.
  constructor(public router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.debug('NavigationEnd: ' + event.url);

        this.vpView.markViewChangeEnd();
        this.vpView.markViewDOMLoaded();
        this.vpView.markXhrRequestsCompleted();
        this.vpView.markViewResourcesLoaded();
        this.vpView.end();
        ADRUM.report(this.vpView);

      } else if (event instanceof NavigationStart) {
        console.debug('NavigationStart: ' + event.url);

        this.vpView = new ADRUM.events.VPageView();
        this.vpView.start();
        this.vpView.markViewChangeStart();
      }
    });
  };
}
*/
