// List of all configurational constants that are project level
// Make sure Global level enums are here

import { environment } from '../environments/environment';

export const ENVIRONMENTS: any = {
  mock: 'MOCK',
  dev: 'DEV',
  test: 'TEST',
  stg: 'STG',
  prod: 'PROD'
};

export const VULNERABLE_CHAR: string[] = [
  "@",
  "-",
  "+",
  "="
];

export const COMP_VIEW_MODES: any = {
  add: 'ADD',
  edit: 'EDIT',
  view: 'VIEW'
};

export const DATE_RANGE = [
  {
    text: '7 Days',
    value: '7'
  },
  {
    text: '14 Days',
    value: '14'
  },
  {
    text: '1 Month',
    value: '30'
  },
  {
    text: '2 Months',
    value: '60'
  },
  {
    text: '3 Months',
    value: '90'
  },
  {
    text: '6 Months',
    value: '180',
    isSelected: true
  },
  {
    text: '1 Year',
    value: '365'
  },
  {
    text: 'Year To Date',
    value: 'ytd'
  },
  {
    text: 'Previous Quarter',
    value: 'preQuater'
  },
  {
    text: 'Current Quarter',
    value: 'quater'
  }
];

export const CMV_DATE_RANGE = [
  {
    text: 'All Time',
    value: 'allTime',
    isSelected: true
  },
  {
    text: '3 Months',
    value: '90'
  },
  {
    text: '6 Months',
    value: '180'
  },
  {
    text: '1 Year',
    value: '365'
  },
  {
    text: '2 Years',
    value: '730'
  },
  {
    text: '3 Years',
    value: '1095'
  },
  {
    text: 'Year To Date',
    value: 'ytd'
  },
  {
    text: 'Previous Quarter',
    value: 'preQuater'
  },
  {
    text: 'Current Quarter',
    value: 'quater'
  }
];

export const INDICATOR_TYPE = [
  {
    text: 'Overall',
    value: 'Overall',
    isSelected: true
  },
  {
    text: 'KPI',
    value: 'KPI'
  },
  {
    text: 'KSI',
    value: 'KSI'
  },
  {
    text: 'KCI',
    value: 'KCI'
  },
  {
    text: 'Monitor',
    value: 'Monitor'
  }
];

export const LOCAL_BUSINESS_DRIVER_TITLE = [
  {
    text: 'Safety',
    value: '21b36c64-11ba-e711-8723-5820b178538c',
    isSelected: true
  },
  {
    text: 'Productivity Improvement',
    value: '22b36c64-11ba-e711-8723-5820b178538c'

  },
  {
    text: 'Cost Optimization',
    value: '23b36c64-11ba-e711-8723-5820b178538c'
  },
  {
    text: 'Reliability Improvement',
    value: '24b36c64-11ba-e711-8723-5820b178538c'
  },
  {
    text: 'Product Quality',
    value: '25b36c64-11ba-e711-8723-5820b178538c'
  },
  {
    text: 'Environmental',
    value: '26b36c64-11ba-e711-8723-5820b178538c'
  },
  {
    text: 'Water, Energy, Air Optimization',
    value: '27b36c64-11ba-e711-8723-5820b178538c'
  },
  {
    text: 'People',
    value: '28b36c64-11ba-e711-8723-5820b178538c'
  }
];

export const CORPORATE_BUSINESS_DRIVER_TITLE = [
  {
    text: 'Safety',
    value: '7498b891-50ba-e711-8723-5820b178538c',
    isSelected: true
  },
  {
    text: 'Productivity Improvement',
    value: '7598b891-50ba-e711-8723-5820b178538c'

  },
  {
    text: 'Cost Optimization',
    value: '7698b891-50ba-e711-8723-5820b178538c'
  },
  {
    text: 'Reliability Improvement',
    value: '7798b891-50ba-e711-8723-5820b178538c'
  },
  {
    text: 'Product Quality',
    value: '7898b891-50ba-e711-8723-5820b178538c'
  },
  {
    text: 'Environmental',
    value: '7998b891-50ba-e711-8723-5820b178538c'
  },
  {
    text: 'Water, Energy, Air Optimization',
    value: '7a98b891-50ba-e711-8723-5820b178538c'
  },
  {
    text: 'People',
    value: '7b98b891-50ba-e711-8723-5820b178538c'
  }
];

export const DASH_STYLES: string[] = ['Dash',
  'ShortDash',
  'ShortDot',
  'ShortDashDot',
  'ShortDashDotDot',
  'Dot',
  'Solid',
  'LongDash',
  'DashDot',
  'LongDashDot',
  'LongDashDotDot'
];

export class APP_CONFIG {
  public static BASE_PATH: string = '';
  public static genericErrorMessage: string = 'There is an error during the process. Please, try again later.';
  public static toasterDuration: number = 5000;
  public static snackbarDuration: number = 5000;
  public static numberOfSnackbarAllowed: number = 3;
  public static dateTimeStoreFormat: string = 'YYYY-MM-DDTHH:mm:ss+-HH:mm';
  public static dateTimeDisplayFormat: string = 'MMM Do YY, h:mm a';
  public static froalaLicenceKey = 'FD3C2H3B1A7B2wB1B1B1D1E1E4B3B2C10C8jXd1TEWUa1c2QSDe1HAb2==';

  //public static ENV: string = environment.environment;
  //public static PRODUCTION: boolean = environment.isProduction;
  //public static ENV: string = ENVIRONMENTS.dev;

  // manage environments and common application settings
  // Lets get this from index.cs via RAZOR
  public static get ENVIRONMENT(): string {
    if (window['environment'] && window['environment'] !== '') {
      return window['environment'];
    }
    else {
      return environment.environment;
    }

  }

  public static get PRODUCTION(): boolean {
    if (window['isProduction'] && window['isProduction'] !== '') {
      return window['isProduction'];
    }
    else {
      return environment.production;
    }
  }

}

export class KPI {
  public static legionellaGrowthRisk: string = 'legionellaGrowthRisk';
  public static compliance: string = 'compliance';
  public static performance: string = 'performance';
  public static legionellaTestResults: string = 'legionellaTestResults';
};

export class KPIINDICATORS {
  public static high: string = 'high';
  public static medium: string = 'medium';
  public static low: string = 'low';
  public static positive: string = 'positive';
  public static no_test: string = 'no test';
  public static non_detect: string = 'non detect';
};

export class ASSETTREECONSTANTS {
  public static SELECTEDSITE: string = 'SELECTEDSITE';
  public static UNIQUENAME: string = 'Provide a unique name for ';
  public static REORDERTEXT: string = 'You can only reorder within an area.';
  public static REORDERTITLE: string = 'Restricted Action';
  public static DROPBLOCK: string = 'Current operation not allowed.';
  public static DROPBLOCKTITLE: string = 'Warning';
  public static DELETETEXT: string = ' will be permanently deleted.';
  public static LEAVETEXT: string = 'Leave site?';
  public static LEAVECONTENT: string = 'Changes you made will not be saved.';
  public static DONOTDELETEASSETTEXT: string = 'Cannot delete asset';
  public static MAPPEDSUBASSETTEXT: string = 'Assets cannot be deleted when sub assets exist. Delete sub assets first.';
  public static MAPPEDDATATEXT: string = 'Data is mapped to this asset. Contact the System Administrator to delete.';
  public static SAVETEXT = 'Ok to Save?';
  public static SAVECONTENTTEXT = 'All changes made to Asset Tree, Properties and Data Mapping will be saved.  Do you wish to proceed with Save?';
  public static SAVEDSUCCESS = 'Asset Tree Saved Succesfully';
}

export const oprationalStatusType: any = {
  Hidden: 0,
  Active: 1,
  Pending: 2,
  Delete: 3,
  Inactive: 4,
  Offline: 5,
  UnHidden: 6
};

export const isGEMOFeatureVisible: boolean = true;

export class SERVICES {
  public static OMNI: string = 'Omni';
  public static WATER_QUALITY: string = 'WaterQuality';
}

export class USERROLES {
  public static ENVISION4WEB: string = '1';
  public static WSIDASHBOARD: string = '2';
  public static ASSETTREEADMIN: string = '3';
  public static WQDASHBOARD: string = '4';
  public static OMNIDASHBOARD: string = '5';
  public static UAADASHBOARD: string = '6';
  public static WFIDASHBOARD: string = '7';
  public static FIDASHBOARD: string = '8';
  public static VISDASHBOARD: string = '9';
  public static RODASHBOARD: string = '59';
  public static WSIADMIN: string = '10';
  public static AHROADMIN: string = '74';
  public static WQADMIN: string = '11';
  public static SAADMIN: string = '55';
  public static OMNIADMIN: string = '12';
  public static OMNIHXSURVEYREAD: string = '112';
  public static OMNIHXSURVEYWRITE: string = '113';
  public static UAAADMIN: string = '13';
  public static WFIADMIN: string = '14';
  public static FIADMIN: string = '15';
  public static VISADMIN: string = '16';
  // ASSETTREEVIEW permission is obsolete and not used in the application both at FED & API level.
  public static ASSETTREEVIEW: string = '17';
  public static WATERSAFETYENABLEMENT: string = '18';
  public static WSILEGISLATIVEADMIN: string = '51';
  //NOTIFICATION PERMISSION
  public static NOTIFICATIONS_ADMIN_OWN: string = '19';
  public static NOTIFICATIONS_ADMIN_INTERNAL: string = '20';
  public static NOTIFICATIONS_CUSTOMER_FULL: string = '21';
  public static NOTIFICATIONS_CUSTOMER_ADD: string = '22';
  public static NOTIFICATIONS_ADMIN: string = '77';

  //PLATFORM NOTIFICATIONS ADMIN
  public static PFMNFTADMN: string = '79';

  public static WFISENSORMAPPING: string = '27';
  public static ASSETHARVESTERREAD: string = '28';
  public static ASSETHARVESTERREADWRITE: string = '29';
  public static WATERHARVESTERCALCULATIONS: string = '60';
  public static CONTROLLERADMIN: string = '64';
  public static ENVISIONSALES: string = '67';
  public static WATERHARVESTERWRITE: string = '66';
  public static ASSETHARVESTERDECOMMISSIONWRITE: string = '61' ///// decommission write permission for BRT
  public static ASSETHARVESTERADMIN: string = '58';
  public static TANKADMIN: string = '80'; /// for bypass serial number while creating inventory assets
  public static FSQSMANAGESITEREAD: string = '88';
  public static FSQSMANAGESITEWRITE: string = '89';
  public static INVENTORY: string = '101';

  //Service Summary
  public static SSDAdmin: string = '46';
  //WBI Access
  public static WBI_READ_ACCESS: string = '52';
  //SGI Access
  public static SGI_ADMIN_ACCESS: string = '53';
  public static SGI_READ_ACCESS: string = '54';
  public static SSD_LOGO_MANAGEMENT: string = '68';

  public static CREDMGMT: string = '83';
  public static IOTCWP: string = '94';
  public static RECYCLE_INTELLIGENCE: string = '75';
  public static RECYCLE_INTELLIGENCE_ADMIN: string = '76';

  public static CLIMATE_INTELLIGENCE: string = '78';
  public static CLIMATE_INTELLIGENCE_ADMIN: string = '84';
  public static FEED_FLEX: string = '85';
  public static TOTAL_BOOTH_MANAGEMENT: string = '102';
  //FSQS PERMISSIONS
  public static FSQS_SANITATION_SERVICE_DASHBOARD: string = '86';
  public static FSQS_PROCEDURE_WRITE: string = '87';
  public static FSQS_AH_LOCATION_READ: string = '88';
  public static FSQS_AH_LOCATION_WRITE: string = '89';
  public static FSQS_PROCEDURE_LOG_READ: string = '90';
  public static FSQS_PROCEDURE_LOG_WRITE: string = '91';
  public static FSQS_PROCEDURE_READ: string = '92';
  public static FSQS_EMPSERVICE_DASHBOARD: string = '109';
  public static FSQS_PTSSERVICE_DASHBOARD: string = '108';
  public static SERVICE_PLAN_LOG_READ: string = '106';
  public static SERVICE_PLAN_READ: string = '104';
  public static SERVICE_PLAN_WRITE: string = '105';
  public static SERVICE_PLAN_LOG_WRITE: string = '107';

  public static CONTROLLERADMINREMOTE: string = '93';
  public static HARVESTERMANUALEQUIPMENTREQUESTOR: string = '81';
  public static HARVESTERMANUALEQUIPMENTAPPROVER: string = '82';

  // MTV Territory
  public static MTV_ACCESS = '380'
}
