// angular modules packages
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// custom modules components
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { SubheaderHandlerService } from '../../app/core/nav/subheader-handler.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { DialogService } from '../shared/services/dialog.service';
import { SharedModule } from '../shared/shared.module';
import { NotificationService } from './_services/notification.service';
import { NotificationServiceConfig } from './_services/notification.service.config';
import { MergeSystemNameComponent } from './asset-harvester-merge-system-name/merge-system-name.component';
import { NotificationComponent } from './notification.component';
import { NOTIFICATION_ROUTES } from './notification.routes';
import { SuccessBandComponent } from './success-band/success-band.component';
import { ViewAllNotificationComponent } from './view-all-notification/view-all-notification.component';
import { BreadcrumbModule } from '../shared-ui-refresh/breadcrumb/breadcrumb.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(NOTIFICATION_ROUTES),
    NgbModule.forRoot(),
    InfiniteScrollModule,
    TranslateModule.forChild({
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    SharedModule,
    BreadcrumbModule
  ],
  declarations: [
    NotificationComponent,
    ViewAllNotificationComponent,
    MergeSystemNameComponent,
    SuccessBandComponent
  ],
  exports: [
    NotificationComponent,
    ViewAllNotificationComponent,
    MergeSystemNameComponent
  ],
  providers: [NotificationServiceConfig,
    NotificationService,
    CanDeactivateGuard,
    DialogService,
    SubheaderHandlerService
  ]
})
export class NotificationModule {


}
