import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { IAppliedFilter } from '../../../shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { StartEndDate } from '../../models/StartEndDate';
import { SiteService } from '../../services/site.service';
import { ErrorhandlingPopupComponent } from '../error-handling/errorhandling-popup/errorhandling-popup.component';
import { XdComponentState } from '../xd-container/xd-component';
import { IQuickFiltersText, IQuickFiltersData } from '../../../shared-ssd/_contracts/IQuickFiltersInfo';
import { DGSNSupport } from '../../../../library/sharedDVLibrary/angular-shared-component/projects/angular-v13/src/lib/shared/enum/constants';

@Component({
    selector: 'nalco-tile',
    templateUrl: './tile.component.html',
    animations: [
        trigger(
            'stateChangeAnimation',
            [
                transition(
                    ':enter', [
                    style({ 'opacity': 0 }),
                    animate('700ms', style({ 'opacity': 1 }))
                ]
                ),
                transition(
                    ':leave', [
                    style({ 'opacity': 1 }),
                    animate('700ms', style({ 'opacity': 0 }))
                ]
                )]
        ),
    ],
})
export class TileComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('moreOption',{static: false}) moreOption: any;
    @Input() set parentInstance(value: any) {
        this.consumerState = of(value);
    }
    @Input() details: any;
    @Input() headerTitle: any;
    @Input() headerTitleWithTooltip?: any;
    @Input() headerTooltip?= false;
    @Input() tooltipContent: any;
    @Input() htmlContent: any;
    @Input() headerSubTitle: any;
    @Input() headerAnchor?: string;
    @Input() headerAnchorlink: any;
    @Input() paramValues: any;
    @Input() id: any;
    @Input() showMoreOptions = true;
    @Input() showFavourites = false;
    @Input() showInfoIcon = true;
    @Input() exportCsvData: any;
    @Input() fileName?= 'Export Data';
    @Input() useGraphExport?= false;
    @Input() showExportAsPDF = false;
    @Input() showAddToReport = false;
    @Input() showExportAsImage = false;
    @Input() showExportAsCsv = false;
    @Input() showAudit = false;
    @Input() showAccountDetails = false;
    @Input() isCollapsible?: boolean;
    @Input() isCollapsed?= false;
    @Input() days?: number;
    @Input() epxortWithInfo?= false;
    @Input() epxortWithInfoDetails?= false;
    @Input() imageTitle?: string;
    @Input() companyName?: string;
    @Input() exportDateText?: string;
    @Input() pageLevelFilterText?: string;
    @Input() timeRangeText?: string;
    @Input() divisionByText?: string;
    @Input() isExportToImageSSD?: boolean;
    @Input() titleText?: string;
    @Input() isTimeRange?= true;
    @Input() exportedDateFormat?: string;
    @Input() isTCOPipeline?= true;
    @Input() selectedFIDuration: any;
    @Input() selectedDivisions?: any;
    @Input() siteName?: string;
    @Input() errorData?: any;
    @Input() showDeleteIcon? = false;
    @Input() showDragHandlerIcon? = false;
    @Input() showCardHeader = true;
    @Input() disableHeader = false;
    @Input() showDownloadConfig = false;
    @Input() showSystemHealth = false;
    @Input() showGatewayStatistics = false;
    @Input() showDataDownload = false;
    @Input() showHardwareDetails = false;
    @Input() showAlarmNotification = false;
    @Input() showAlarmList = false;
    @Input() filterDetails:IAppliedFilter;
    @Input() monthlyQuarterly: {durationType: string, value: number};
    @Input() showExpandCollapse ?= false;
    @Input() quickFiltersText?: IQuickFiltersText;
    @Input() quickFilterData?: IQuickFiltersData;
    @ViewChild('header', {static: false}) header: ElementRef;
    // commenting these lines as we are not using font awesome
    // @Input('empty-icon-class') emptyIconClass: string = 'fa fa-info-circle';
    @Input('empty-icon-class') emptyIconClass: string = '';

    @Input('loading-icon-class') loadingIconClass: string = 'loader';

    // commenting these lines as we are not using font awesome
    // @Input('error-icon-class') errorIconClass: string = 'fa fa-exclamation-triangle';
    @Input('error-icon-class') errorIconClass: string = '';

    @Input('empty-title-text') emptyTitleText = this.translate.instant('COMMON.EMPTY_TITLE_TEXT');
    @Input('loading-title-text') loadingTitleText = this.translate.instant('COMMON.LOADING_TITLE_TEXT');
    @Input('error-title-text') errorTitleText: string = this.translate.instant('COMMON.ERROR_TITLE_TEXT', { supportEmail: DGSNSupport.Email });

    @Input('loading-message-text') loadingMessageText = this.translate.instant('COMMON.LOADING_TITLE_TEXT');
    @Input('error-message-text') errorMessageText = this.translate.instant('COMMON.ERROR_MESSAGE_TEXT');
    @Input('error-content') errorContent = this.translate.instant('COMMON.ERROR_CONTENT');
    @Input() showTrendLines = false;
    @Input() dateRangeObject: StartEndDate;

    @Output() favouriteToggled: EventEmitter<any> = new EventEmitter();
    @Output() exportAsCsvClicked: EventEmitter<any> = new EventEmitter();
    @Output() exportAsAccountClicked: EventEmitter<any> = new EventEmitter();
    @Output() exportAsAuditClicked: EventEmitter<any> = new EventEmitter();
    @Output() deleteWidgetIconClicked: EventEmitter<any> = new EventEmitter();
    @Output() moreOptionPrintWidgetClicked: EventEmitter<any> = new EventEmitter();
    @Output() headerTitleClicked : EventEmitter<any> = new EventEmitter();
    @Output() downloadConfigurationClicked : EventEmitter<any> = new EventEmitter();
    @Output() systemHealthClicked :EventEmitter<any>=new EventEmitter();
    @Output() gatewayStatisticsClicked :EventEmitter<any>=new EventEmitter();
    @Output() dataDownloadClicked :EventEmitter<any>=new EventEmitter();
    @Output() hardwareDetailsClicked :EventEmitter<any>=new EventEmitter();
    @Output() alarmNotificationClicked : EventEmitter<any> = new EventEmitter();
    @Output() alarmListClicked :EventEmitter<any>=new EventEmitter();
    @Output() isExpandAllClicked :EventEmitter<any>=new EventEmitter();
    @Output() isCollapseAllClicked :EventEmitter<any>=new EventEmitter();

    consumerState:Observable<XdComponentState>;
    modalRef: any;
    tooltipTitle:string;
    expandState: boolean = true;
    private subscriptions: Subscription[] = [];
    isPrintPreview: boolean;
    emptyTitleClass:string;
    errorTitleClass: string;
    loadingMessageClass: string;
    loadingTitleClass:string;

    chartLegends: Array<any> = [
        { id: '1', name: this.translate.instant('QUALITY.OVERALL_PERFORMANCE') },

        { id: '2', name: this.translate.instant('QUALITY.SINGULAR_ASSET_OFFLINE') }];

    constructor(private cdr: ChangeDetectorRef,
        private translate: TranslateService,private siteService: SiteService, private modalService: NgbModal,) {
            // this.consumerState.setState(ComponentState.ready);

    }

    ngOnInit() {
        this.tooltipTitle = '';
        this.subscriptions.push(
          this.siteService.printPDFClicked$.subscribe((data) => {
            this.isPrintPreview = data;
          })
        ); 
    }

    errorPopUp(event) {
        let status = JSON.parse(this.errorData.status);
        let errorMessage = '';
        let guid = '';
        if (status !== 0) {
            let error = JSON.parse(this.errorData._body);
            if (!error || !error.ExceptionMessage) {
                errorMessage = this.errorData._body;
            } else {
                errorMessage = error.ExceptionMessage;
                guid = error.Message;
                errorMessage = '{' + guid + '} ' + errorMessage;
            }
        }
        this.modalRef = this.modalService.open(ErrorhandlingPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'saveExit-modal'
        }).componentInstance;
        this.modalRef.errorMessage = errorMessage;
    }
    ngOnChanges() {
        if (this.header) {
            if (this.disableHeader)
                this.header.nativeElement.style.backgroundColor = '#d7d8d9';
            else
                this.header.nativeElement.style.backgroundColor = '#ffffff';
        }
    }
    ngAfterViewInit() {
        this.cdr.detectChanges();
        if (this.disableHeader && this.header) {
            this.header.nativeElement.style.backgroundColor = '#d7d8d9';
        }
    }

    onfavouriteToggle($event) {
        this.favouriteToggled.emit($event);
    }

    public handleExportAsCsvClick() {
        this.exportAsCsvClicked.emit();
    }

    public handleExportAsAccountClick() {
        this.exportAsAccountClicked.emit();
    }
    public handleDownloadConfigurationClick() {
        this.downloadConfigurationClicked.emit();
    }
    public handleSystemHealthClick() {
        this.systemHealthClicked.emit();
    }
    public handleGatewayStatisticsClick() {
        this.gatewayStatisticsClicked.emit();
    }
    public handleDataDownloadClick() {
        this.dataDownloadClicked.emit();
    }
    public handleHardwareDetailsClick() {
        this.hardwareDetailsClicked.emit();
    }
    public handleAlarmNotificationClick() {
        this.alarmNotificationClicked.emit();
    }

    public handleAlarmListClicked() {
        this.alarmListClicked.emit();
    }

    public handleExportAsAuditClick() {
        this.exportAsAuditClicked.emit();
    }

    public GenerateCsvData(data: any) {
        this.moreOption.ExportAsCSV(data);
    }

    public removeTileItem() {
        const toolTip = document.body.querySelector('.tooltip');
        this.deleteWidgetIconClicked.emit(true);
        toolTip.remove();
    }

    public handleHeaderTitleClicked() {
        this.headerTitleClicked.emit();
    }

    ifElement(value: string, ele: any, ele1: any) {
        let returnValue: string;
        if (ele > ele1 + 1) {
            returnValue = value.trim();
        } else {
            returnValue = '';
        }
        return returnValue;
    }

    public setPrintWidgetOption() {
        this.moreOptionPrintWidgetClicked.emit(true);
    }
    getTooltip(element: HTMLElement): void {
        if(this.headerTitle){
            this.tooltipTitle = element.scrollWidth > element.offsetWidth+1 ? this.headerTitle : '';
        }
    }

    public collapseAllClicked(){
        this.expandState = false;
        this.isCollapseAllClicked.emit();
    }

    public expandAllClicked(){
        this.expandState = true;
        this.isExpandAllClicked.emit();
    }

    ngOnDestroy():void{
        this.subscriptions.forEach((s)=>{
            s.unsubscribe();
        })
    }
}
