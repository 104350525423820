import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DateLocaleService {

  constructor(private translate: TranslateService) { }

  public getLocaleMonths(): Array<any> {
    return this.buildLocaleMonths();
  }

  public getShortLocaleMonths(): Array<any> {
    return this.buildShortLocaleMonths();
  }

  public getLocaleDays(): Array<any> {
    return this.buildLocaleDays();
  }

  private buildLocaleMonths(): Array<any> {
    return [
      this.translate.instant('COMMON.JANUARY'),
      this.translate.instant('COMMON.FEBRUARY'),
      this.translate.instant('COMMON.MARCH'),
      this.translate.instant('COMMON.APRIL'),
      this.translate.instant('COMMON.MAY'),
      this.translate.instant('COMMON.JUNE'),
      this.translate.instant('COMMON.JULY'),
      this.translate.instant('COMMON.AUGUST'),
      this.translate.instant('COMMON.SEPTEMBER'),
      this.translate.instant('COMMON.OCTOBER'),
      this.translate.instant('COMMON.NOVEMBER'),
      this.translate.instant('COMMON.DECEMBER')
    ];
  }

  private buildShortLocaleMonths(): Array<any> {
    return [
      this.translate.instant('COMMON.JAN'),
      this.translate.instant('COMMON.FEB'),
      this.translate.instant('COMMON.MAR'),
      this.translate.instant('COMMON.APR'),
      this.translate.instant('COMMON.MAY_SHORT'),
      this.translate.instant('COMMON.JUN'),
      this.translate.instant('COMMON.JUL'),
      this.translate.instant('COMMON.AUG'),
      this.translate.instant('COMMON.SEP'),
      this.translate.instant('COMMON.OCT'),
      this.translate.instant('COMMON.NOV'),
      this.translate.instant('COMMON.DEC')
    ];
  }

  private buildLocaleDays(): Array<any> {
    return [
      this.translate.instant('COMMON.SUN'),
      this.translate.instant('COMMON.MON'),
      this.translate.instant('COMMON.TUES'),
      this.translate.instant('COMMON.WED'),
      this.translate.instant('COMMON.THURS'),
      this.translate.instant('COMMON.FRI'),
      this.translate.instant('COMMON.SAT')
    ];
  }
}
