import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LogLevel, Logger } from '@azure/msal-browser';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MsalV2Service {
  isIframe = false;
  loggedIn: boolean;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: MsalService,
    private router: Router,
  ) { }

  authInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.checkoutAccount();
    // this.handleRedirectCallback();
    this.setLogger();
  }

  checkoutAccount() {
    this.loggedIn = !!(this.authService.instance.getAllAccounts().length > 0);
  }

  logout(): void {
    // Clear MSAL tokens from cache
    this.clearMSALCache();
    this.router.navigate(['/logout']);
  }

  public clearMSALCache() {
    // Clear MSAL tokens from cache
    let localStorageKeys = Object.keys(window.localStorage);
    for (let key of localStorageKeys) {
      if (key.includes('msal')) {
        window.localStorage.removeItem(key);
      }
    }
  }

  private setLogger(): void {
    this.authService.setLogger(new Logger({
      loggerCallback: (log_Level, message, piiEnabled) => {
          console.log('MSAL Logging: ', message);
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Info
  }));
  }

  // private handleRedirectCallback() {
  //   this.authService.instance.handleRedirectPromise((authError) => {
  //     if (authError) {
  //       console.error('Redirect Error: ', authError.errorMessage);
  //       if (authError.errorMessage.indexOf('AADB2C90077') !== -1) {
  //         this.clearMSALCache();
  //         this.authService.loginRedirect();
  //       }
  //     }
  //   });
  // }
}