import { Component, OnInit, ChangeDetectorRef, ViewRef, AfterViewInit, Input, HostListener, OnDestroy } from '@angular/core';
import { NavigationHandlerService } from './../../../core/nav/navigation-handler.service';
import {
    Router, ActivatedRoute
} from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Navigation } from '../../models/Navigation';
import { INavigationMenu } from '../../contracts/_contracts';
import { environment } from '../../../../environments/environment';
import { Response } from './navigation.json';
import { UserService } from './../../../core/user/user.service';
import { User } from '../../models/User';
import { AppEnvConfig } from '../../../app.env.config';
import { FilterNavigation } from './../../../core/pipes/filter-navigation.pipe';
import { Observable, Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppDynamicsService } from '../../../core/analytics/app-dynamics.service';
import { SharedMainService } from '../../services/shared-main.service';

@Component({
    selector: 'nalco-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, OnDestroy {

    user: User;
    isNavigationDisabled = false;
    isExternalUser = false;
    navigationMenu: Navigation;
    useExternalNavigation = true;
    useLocalNavigation = true;
    private subscriptions: Subscription[] = [];
    leftNavigationItems: INavigationMenu[];
    isMobile: Boolean = false;
    digitalSupportHTML: String = '<p>Ecolab Digital Support: (866) 851-8670</p';
    public isEnvisionMenuHide: String;
    isCredentialMenuHide : boolean;
    isClimateIntelligenceNavigation : any;
    isCrudeFlexEnabled: any;
    isFSQSEnabled: any;
    isTotalBoothManagementEnabled : boolean;

    constructor(private navService: NavigationHandlerService,
        private router: Router,
        private config: AppEnvConfig,
        private translate: TranslateService,
        private appDynamicsService: AppDynamicsService,
        private sharedService: SharedMainService, private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private meta: Meta
       ) {
        this.isEnvisionMenuHide = config.getEnv('isEnvisionMenuHide').toString();
        this.isCredentialMenuHide = config.getEnv('isCredentialMenuHide');
        this.isClimateIntelligenceNavigation = config.getEnv('isClimateIntelligenceNavigation').toString();
        this.isCrudeFlexEnabled = config.getEnv('isCrudeFlexEnabled').toString();
        this.isFSQSEnabled = config.getEnv('isFSQSEnabled');
        this.isTotalBoothManagementEnabled = config.getEnv('isTotalBoothManagementEnabled');
        navService.navToggle$.subscribe(state => {
            this.isNavigationDisabled = state;
        });
        this.useExternalNavigation = false; //  environment.useExternalNavigation;
        this.useLocalNavigation = true; // environment.useLocalNavigation;
    }

    ngOnInit() {
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        // this.sharedService.getUsersNavigationMenu(this.user.profile.name)
        //     .subscribe(response => {
        //         if (this.useLocalNavigation) {
        //             this.navigationMenu = Response;
        //         } else {
        //             this.navigationMenu = response;
        //         }
        //     });

        // this.authenticationGuard.canActivate(this.activatedRoute.snapshot, this.router.routerState.snapshot);
        this.getNavigationMenu();
        this.subscriptions.push(this.navService.updateLeftNav$.subscribe(async (update) => {
            this.getNavigationMenu();
        }));

    }
    private hideLeftSubMenuLinks() {
       if (this.isEnvisionMenuHide === 'true') {
            this.leftNavigationItems.forEach((s,index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_TOOLS') {
                    this.leftNavigationItems.splice(index,1);
                }
            });
        }
        if(!this.isCredentialMenuHide){
            this.leftNavigationItems.forEach((s,index) => {
                if (s.label === 'LEFT_NAV.ADMIN_NAV_LABEL') {
                   s.subNavigation.forEach((d,i)=>{
                      if(d.label === 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL'){
                          this.leftNavigationItems[index].subNavigation.splice(i,1)
                      }
                   })
                }
            });
        }
        if (this.isClimateIntelligenceNavigation == "false") {
            this.navigationMenu.leftNavigation.forEach((s, index) => {
            if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                s.subNavigation.forEach((d, i) => {
                if (d.label === 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL') {
                    this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
                }
                })
            }
            });
        }
        if(this.isCrudeFlexEnabled === "false"){
            this.navigationMenu.leftNavigation.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                    s.subNavigation.forEach((d, i) => {
                    if (d.label === 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL') {
                        this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
                    }
                    })
                }
                });
        }
        if (this.isFSQSEnabled === "false") {
            this.navigationMenu.leftNavigation.forEach((s, index) => {
              if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                s.subNavigation.forEach((d, i) => {
                  if (d.label === 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL') {
                    this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
                  }
                })
              }
            });
          }
          if(this.config.getEnv("IsE3DChina") === "true"){
            this.navigationMenu.leftNavigation.forEach((s, index) => {
              if (s.label === 'LEFT_NAV.MAIN_NAV_TOOLS') {
                s.subNavigation.forEach((d, i) => {
                  if (d.label === 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR') {
                    this.navigationMenu.leftNavigation[index].
                    subNavigation[i].link = 'Envision_Configurator_China';
                  }
                })
              }
            });
          }
          if (!this.isTotalBoothManagementEnabled) {
            this.navigationMenu.leftNavigation.forEach((s, index) => {
              if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                s.subNavigation.forEach((d, i) => {
                  if (d.label === 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL') {
                    this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
                  }
                })
              }
            });
          }
        // if (this.isFSQS == "false") {
        //     this.navigationMenu.leftNavigation.forEach((s, index) => {
        //     if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
        //         s.subNavigation.forEach((d, i) => {
        //         if (d.label === 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL') {
        //             this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
        //         }
        //         })
        //     }
        //     });
        // }
    }

    menuItemClicked(event: any) {
        if(event.link == '/admin-alarm'){
            sessionStorage.removeItem('UserLibSelection');
            sessionStorage.setItem('navigatedFromComponent', 'DashboardMenu');
            if(this.router.url == '/admin-alarm'){
                window.location.reload();
                sessionStorage.removeItem('UserLibSelection');
            }
        }
        if(event.link == '/alarm-user-mgmt'){
            sessionStorage.removeItem('UserLibSelection');
            sessionStorage.setItem('navigatedFromComponent', 'AlarmUserManagement');
            if(this.router.url == '/alarm-user-mgmt'){
                window.location.reload();
                sessionStorage.removeItem('UserLibSelection');
            }
        }
        if(event.link == '/WFI-alarm'){
            sessionStorage.removeItem('UserLibSelection');
            sessionStorage.setItem('navigatedFromComponent', 'DashboardMenuAlarm');
            if(this.router.url == '/WFI-alarm'){
                window.location.reload();
                sessionStorage.removeItem('UserLibSelection');
            }
        }
        let navigationLink = '';
        if (this.useExternalNavigation) {
            navigationLink = event.link;
        } else {
            if (event.link.substr(event.link.indexOf('#') > 2)) {
                navigationLink = event.link.substr(event.link.indexOf('#') + 1);
            }
        }
        if (navigationLink.indexOf('settings') > -1) {

            if (event.siteId === undefined || event.siteId === null) {

                return false;
            } else if (event.siteId !== '') {

                this.routeToComponent(navigationLink, { queryParams: { siteId: event.siteId } });
            } else {

                this.routeToComponent(navigationLink);
            }
        } else if (navigationLink.indexOf('feedback') > -1) {
        } else {
            event.siteId = null;
            this.routeToComponent(navigationLink);
        }

    }

    desktopToggle() {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=1600, , initial-scale=0.1' }, true);
    }


    private routeToComponent(navigationLink: string, queryParams?: any) {

        if (this.useExternalNavigation) {
            if (queryParams !== undefined) {
                window.location.href = navigationLink + '?siteId=' + queryParams.queryParams.siteId;
            } else {
                window.location.href = navigationLink;
            }
        } else {

            this.router.navigate([navigationLink], queryParams);
        }
    }

    filterItemsOfType() {
        if (this.navigationMenu !== undefined) {
            if (this.navigationMenu.leftNavigation !== undefined) {
                this.leftNavigationItems = this.navigationMenu.leftNavigation.filter(x => x.hover !== 'Feedback');
            }
            // hide left nav for E3D China
            const userSettings = this.userService.getCurrentSettings();
            const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
            if(isE3DChina && (!userSettings.IsInternalUser)){
                this.leftNavigationItems = this.navigationMenu.leftNavigation.filter(x => x.name !== 'Training');
            }
            this.hideLeftSubMenuLinks();
        }
    }

    filterByUserRole(role, navigationDetail, linksToExclude: string[]) {
        const userSettings = this.userService.getCurrentSettings();
        const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
        let navigationMenu = [];
        if (role !== null) {
            const pipe = new FilterNavigation();
            let IsInternalUser=userSettings?userSettings.IsInternalUser:true;
            const UserRegion = userSettings?userSettings.UserPreference.UserRegion : null;
            navigationMenu = pipe.transform(role, navigationDetail.leftNavigation,IsInternalUser, isE3DChina, linksToExclude, UserRegion);
            return navigationMenu;
        } else {
            return navigationMenu;
        }
    }

    getNavigationMenu() {

        const role = this.userService.getUserRole();
        let userPermissions = [];
        if (role !== null) {
            role.forEach(function (s) {
                userPermissions.push(s.PermissionId.toString());
            })
        }
        let fullMenu = JSON.parse(JSON.stringify(Response));
        this.navigationMenu = fullMenu;
        const linksToExclude = this.getLinksToExclude();
        this.navigationMenu.leftNavigation = this.filterByUserRole(userPermissions, fullMenu, linksToExclude);
        this.filterItemsOfType();
    }

    /**
   * @description Gets list of links to exclude
   * @returns string of links to exclude
   */
    getLinksToExclude(): string[] {
        const isNewFiRefreshEnabled: boolean = this.config.getEnv('isNewFiRefreshEnabled') === "true";
        const excludeLinks = isNewFiRefreshEnabled ? ['/financial-impact/admin'] : [];

        return excludeLinks;
    }

      /************ Analytics Listener ************/
  @HostListener('click', ['$event'])
  onClick(event) {
    const adrumEvent = event;
    const adrumService = 'Home';
    const adrumComponent = this.constructor.name.toString();
    this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
