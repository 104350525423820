import { Component, OnInit } from '@angular/core';
import { SubheaderHandlerService } from '../../nav/subheader-handler.service';
import { HttpRequest } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IButtonData } from '../../../shared-ui-refresh/button/contracts/button-model';
import { errorCodes, pageRefreshErrorCodes  } from '../_enum/constants';
import { DGSNSupport } from '../../../../library/sharedDVLibrary/angular-shared-component/projects/angular-v13/src/lib/shared/enum/constants';

@Component({
  selector: 'nalco-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
  statusCode: number;
  statusCodeMsg : string;
  buttonSecondary : IButtonData;
  buttonPrimaryLeft :IButtonData;
  request: HttpRequest<any>;
  isRefreshShow : boolean = true;
  refreshUrl : string;
  supportEmail = DGSNSupport.Email;
  isValidErrorCode : boolean;
  constructor(private subHeaderService: SubheaderHandlerService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subHeaderService.updateLeftSection({ type: '', data: '', className: 'col-lg-6' });
    this.subHeaderService.updateMiddleSection({ type: '', data: '', className: '' });
    this.subHeaderService.updateRightSection({ type: '', data: '', className: '' });
    this.buttonSecondary = {
      buttonClass: "secondary", buttonText: this.translate.instant('COMMON.RETURN_TO_HOME_PAGE')
    }
    this.buttonPrimaryLeft = {
      buttonClass: "primary", buttonText: this.translate.instant('COMMON.BACK_TO_PREVIOUS_PAGE'),
      leftIconUri: "url(../../../assets/images/mi_arrow-left.svg)"
    }
    this.refreshUrl = window.location.href;
    this.getStatusCodeDetails();
  }

  getStatusCodeDetails() {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        if (params.hasOwnProperty('status')) {
          this.isValidErrorCode = Object.values(errorCodes).includes(params['status'])? true : false;
          this.statusCode =  this.isValidErrorCode ? params['status'] : errorCodes.ERROR_404;
          this.statusCodeMsg = this.isValidErrorCode ? this.translate.instant('COMMON.ERROR_MESSAGE_' + this.statusCode, { supportEmail: DGSNSupport.Email }) :
          this.translate.instant('COMMON.ERROR_MESSAGE_' + errorCodes.ERROR_404, { supportEmail: DGSNSupport.Email }) ;
          this.isRefreshShow = Object.values(pageRefreshErrorCodes).includes(this.statusCode.toString())? true : false;
        }
    }
    });
  }

  /**
   * @description This function is used redirect to the previous page
   */

  backToPreviousPage() {
    window.history.back();
  }

    /**
   * @description This function is used redirect to the Home page
   */
  backToHomePage() {
    this.router.navigate(['/home']);

  }

      /**
   * @description This function is used reload/refrsh the existing url
   */
  reloadPage()
  {
    window.location.reload();
  }
}