import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  BingMapAPILoader,
  BingMapAPILoaderConfig,
  DocumentRef,
  MapAPILoader,
  MapModule,
  WindowRef
} from '../angular-maps/public_api';

import { DeltaComponent } from './components/delta/delta.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FavoriteToggleComponent } from './components/favorite-toggle/favorite-toggle.component';
import { FavoriteComponent } from './components/favorite/favorite.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MoreOptionComponent } from './components/more-option/more-option.component';
import { LeftMenuItemComponent } from './components/navigation/left-menu-item/left-menu-item.component';
import { LeftMenuSubmenuItemComponent } from './components/navigation/left-menu-submenu-item/left-menu-submenu-item.component';
import { LeftMenuComponent } from './components/navigation/left-menu/left-menu.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SearchComponent } from './components/search/search.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { SubHeaderLeftSectionComponent } from './components/sub-header/left-section/left-section.component';
import { SubHeaderMiddleSectionComponent } from './components/sub-header/middle-section/middle-section.component';
import { SubHeaderRightSectionComponent } from './components/sub-header/right-section/right-section.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { TileComponent } from './components/tile/tile.component';
//import { HttpWrapperService } from './services/http.service';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'angular-highcharts';
import { DateRangeService } from './services/date-range.service';
import { ResponsiveService } from './services/reponsive.service';
import { SharedService } from './services/shared-services.service';
import { SharedServiceConfig } from './services/shared-services.service.config';

import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { AutoCompleteModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { AccountSearchComponent } from './components/account-search/account-search.component';
import { AutoCompleteDropdownComponent } from './components/auto-complete-dropdown/auto-complete-dropdown.component';
import { BarLineChartComponent } from './components/bar-line-chart/bar-line-chart.component';
import { CancelpopupComponent } from './components/cancel-popup/cancel-popup.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { CustomDateRangeDropdownComponent } from './components/custom-date-range-dropdown/custom-date-range-dropdown.component';
import { DateRangeDropdownComponent } from './components/date-range-dropdown/date-range-dropdown.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DynamicDropdownComponent } from './components/dynamic-dropdown/dynamic-dropdown.component';
import { EnterprisePerformanceTileComponent } from './components/enterprise-performance-tile/enterprise-performance-tile.component';
import { FiOmniProjectOverviewComponent } from './components/fi-omni-project-overview/fi-omni-project-overview.component';
import { FilterPillsComponent } from './components/filter-pills/filter-pills.component';
import { CustomerDivisionFilterComponent } from './components/filters/customer-division-filter/customer-division-filter.component';
import { GeoSalesRegionFilterComponent } from './components/filters/geo-sales-region-filter/geo-sales-region-filter.component';
import { SitesFilterComponent } from './components/filters/sites-filter/sites-filter.component';
import { HorizantalBarChartComponent } from './components/horizantal-bar-chart/horizantal-bar-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { MapComponent } from './components/map/map.component';
import { MultipleSitePopupComponent } from './components/multiple-site-popup/multiple-site-popup';
import { HumbergurMenuComponent } from './components/navigation/humbergur-menu/humbergur-menu.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PerformanceDonutChartComponent } from './components/performance-donut-chart/performance-donut-chart.component';
import { PillsComponent } from './components/pills/pills.component';
import { SelectDropdownComponentIntegrated } from './components/select-dropdown-integrated/select-dropdown-integrated.component';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { SiteNavigationPopUpComponent } from './components/site-navigation-popup/site-navigation-popup.component';
import { StackChartComponent } from './components/stack-chart/stack-chart.component';
import { StockChartComponent } from './components/stock-chart/stock-chart.component';
import { XdContainerComponent } from './components/xd-container/xd-container.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { DateYearDayPipe } from './pipes/date-year-day.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { NoDecimalPipe } from './pipes/no-decimals';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { secondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { TextCategorySearchPipe } from './pipes/text-category-search.pipe';
import { TextSearchPipe } from './pipes/text-search.pipe';
import { ClickOutside } from './services/click-outside.directive';
import { ConversionUtilityService } from './services/coversionUtility.service';
import { DateLocaleService } from './services/date-locale.service';
import { LooperService } from './services/looper.service';
// eslint-disable-next-line max-len
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { UserService } from '../core/user/user.service';
import { DataVisualizationService } from '../data-visualization/_services/data-visualization.service';
import { DataVisualizationServiceConfig } from '../data-visualization/_services/data-visualization.service.config';
import { SystemAssuranceAdminService } from '../system-assurance-admin/_services/system-assurance-admin.service';
import { SystemAssuranceAdminServiceConfig } from '../system-assurance-admin/_services/system-assurance-admin.service.config';
import { CustomPopupComponent } from '../water-flow-intelligence/admin/sensor-config/custom-popup/custom-popup.component';
import { SensorMappingFormComponent } from '../water-flow-intelligence/admin/sensor-config/sensor-mapping-form/sensor-mapping-form.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AssetCountComponent } from './components/asset-count/asset-count.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ConfigurationPopupComponent } from './components/configuration-popup/configuration-popup.component';
import { CustomGridComponent } from './components/custom-grid/custom-grid.component';
import { DateRangeCampaignDropdownComponent } from './components/date-range-campaign-dropdown/date-range-campaign-dropdown.component';
import { DiagnosticWidgetComponent } from './components/diagnostic-widget/diagnostic-widget.component';
import { DropdownDynamicComponent } from './components/dropdown-dynamic/dropdown-dynamic.component';
import { InventoryListComponent } from './components/inventory_list/inventory-list/inventory-list.component';
import { MaximizeDialogComponent } from './components/maximize-dialog/maximize-dialog.component';
import { FilterByTermPipe } from './components/pills/pills-search.pipe';
import { RecipientDetailsPopupComponent } from './components/recipient-details-popup/recipient-details-popup.component';
import { DateTimePipe } from './pipes/date-time-format.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { DistinctAssetsPipe } from './pipes/distinct-assets.pipe';
import { GroupAssetByCategoryPipe } from './pipes/group-asset-by-category.pipe';
import { GroupPortsByAssetPipe } from './pipes/group-ports-by-asset.pipe';
import { GroupSystems } from './pipes/group-systems.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { CsvExportService } from './services/csvExport.service';
import { DialogComponent, DialogService } from './services/dialog.service';
import { EvocCvocFilterService } from './services/evoc-cvoc-filter.service';
import { SSDSharedService } from './services/ssd-shared.service';
// import { FileUploadModule } from 'ng2-file-upload'
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { AssetDetailsService } from '../asset-details/_services/asset-details.service';
import { AssetDetailsServiceConfig } from '../asset-details/_services/asset-details.service.config';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AppConfigService } from '../core/services/app-config.service';
import { FiCurrencyFormatPipe } from '../shared-financial-impact/pipe/fi-currency-format.pipe';
import { FiNumberFormatPipe } from '../shared-financial-impact/pipe/fi-number-format.pipe';
import { AlarmListComponent } from './components/alarm-list/alarm-list.component';
import { CustomDateMultiviewCalendarComponent } from './components/custom-date-multiview-calendar/custom-date-multiview-calendar.component';
import { CustomizeGridPopupWidgetComponent } from './components/customize-grid-popup-widget/customize-grid-popup-widget.component';
import { DataDownloadComponent } from './components/data-download/data-download.component';
import { DownloadConfigurationComponent } from './components/download-configuration/download-configuration.component';
import { GatewayStatisticsComponent } from './components/gateway-statistics/gateway-statistics.component';
import { HardwareDetailsComponent } from './components/hardware-details/hardware-details.component';
import { ParametersPopupComponent } from './components/parameters-popup/parameters-popup.component';
import { SelectDateRangeComponent } from './components/select-date-range/select-date-range.component';
import { SerialNumberSearchComponent } from './components/serial-number-search/serial-number-search.component';
import { SolidGaugeChartComponent } from './components/solid-gauge-chart/solid-gauge-chart.component';
import { SpeedoMeterGuageComponent } from './components/speedo-meter-guage/speedo-meter-guage.component';
import { SystemHealthComponent } from './components/system-health/system-health.component';
import { ThresholdPopupComponent } from './components/threshold-popup/threshold-popup.component';
import { TimeRangeSectionComponent } from './components/time-range-section/time-range-section.component';
import { ViewTrendChartComponent } from './components/view-trend-chart/view-trend-chart.component';
import { DateTimeNoSecondsPipe } from './pipes/date-time-format-no-seconds.pipe';
import { CustomEDataOverviewComponent } from './components/custom-e-data-overview/custom-e-data-overview.component';
import { CheckboxModule } from '../shared-ui-refresh/checkbox/checkbox.module';
import { SidePanelModule } from '../shared-ui-refresh/side-panel/side-panel.module';
import { ButtonModule } from '../shared-ui-refresh/button/button.module';
import { SharedButtonDropdownModule } from '../shared-ui-refresh/dropdown/dropdown.module';
import { RadioButtonModule, } from '../shared-ui-refresh/radio-button/radio-button.module';
import { DividerModule } from '../shared-ui-refresh/divider/divider.module';
import { CustomDatePickerModule } from '../shared-ui-refresh/custom-date-picker/custom-date-picker.module';

export function MapServiceProviderFactory(configService: AppConfigService) {
  const bc: BingMapAPILoaderConfig = new BingMapAPILoaderConfig();
  const appConfig = configService.getConfig();
  bc.apiKey = appConfig.getEnv('bingMapConfig').apiKey;
  bc.branch = '';
  return new BingMapAPILoader(bc, new WindowRef(), new DocumentRef());
}
@NgModule({
  imports: [
    NgbModule,
    AutoCompleteModule,
    DropDownsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ChartModule,
    InfiniteScrollModule,
    MapModule.forRootBing(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    ReactiveFormsModule,
    GridModule,
    DatePickerModule,
    IntlModule,
    LabelModule,
    DateInputsModule,
    FormFieldModule,
    PopupModule,
    SidePanelModule,
    //  FormsModule,
    ButtonsModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    DividerModule,
    SharedButtonDropdownModule,
    CustomDatePickerModule
  ],
  declarations: [
    TileComponent,
    SearchComponent,
    DropdownComponent,
    MoreOptionComponent,
    FavoriteComponent,
    FavoriteToggleComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SubHeaderComponent,
    SubHeaderLeftSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderRightSectionComponent,
    LineChartComponent,
    StackChartComponent,
    DonutChartComponent,
    PerformanceDonutChartComponent,
    BarLineChartComponent,
    DeleteConfirmationComponent,
    NumberFormatPipe,
    FilterByTermPipe,
    GroupByPipe,
    CurrencyFormatPipe,
    TextSearchPipe,
    TextCategorySearchPipe,
    DateYearDayPipe,
    secondsToTimePipe,
    StockChartComponent,
    EnterprisePerformanceTileComponent,
    FilterPillsComponent,
    MapComponent,
    PillsComponent,
    LeftMenuComponent,
    LeftMenuItemComponent,
    LeftMenuSubmenuItemComponent,
    ConfirmationPopupComponent,
    CancelpopupComponent,
    SiteNavigationPopUpComponent,
    AutoCompleteDropdownComponent,
    XdContainerComponent,
    SitesFilterComponent,
    CustomerDivisionFilterComponent,
    GeoSalesRegionFilterComponent,
    AccountSearchComponent,
    PageHeaderComponent,
    ClickOutside,
    DateRangeDropdownComponent,
    HorizantalBarChartComponent,
    DynamicDropdownComponent,
    HumbergurMenuComponent,
    FiOmniProjectOverviewComponent,
    SelectDropdownComponent,
    SelectDropdownComponentIntegrated,
    MultipleSitePopupComponent,
    CustomDateRangeDropdownComponent,
    DeltaComponent,
    StatusIconComponent,
    NoDecimalPipe,
    SensorMappingFormComponent,
    DialogComponent,
    CustomPopupComponent,
    GroupAssetByCategoryPipe,
    AccountDetailsComponent,
    InventoryListComponent,
    DateRangeCampaignDropdownComponent,
    DropdownDynamicComponent,
    BarChartComponent,
    AssetCountComponent,
    DistinctAssetsPipe,
    GroupPortsByAssetPipe,
    ConfigurationPopupComponent,
    DiagnosticWidgetComponent,
    MaximizeDialogComponent,
    GroupSystems,
    ComboBoxComponent,
    DecimalPipe,
    NoCommaPipe,
    RecipientDetailsPopupComponent,
    CustomGridComponent,
    DateTimePipe,
    DateTimeNoSecondsPipe,
    DownloadConfigurationComponent,
    SystemHealthComponent,
    ViewTrendChartComponent,
    GatewayStatisticsComponent,
    ThresholdPopupComponent,
    DataDownloadComponent,
    HardwareDetailsComponent,
    AlarmListComponent,
    SerialNumberSearchComponent,
    ParametersPopupComponent,
    SolidGaugeChartComponent,
    SpeedoMeterGuageComponent,
    TimeRangeSectionComponent,
    SelectDateRangeComponent,
    CustomizeGridPopupWidgetComponent,
    CustomDateMultiviewCalendarComponent,
    FiNumberFormatPipe,
    FiCurrencyFormatPipe,
    DistinctAssetsPipe,
    GroupAssetByCategoryPipe,
    DateTimeNoSecondsPipe,
    GroupPortsByAssetPipe,
    ConfirmationPopupComponent,
    CustomEDataOverviewComponent,
  ],
  exports: [
    CommonModule,
    TileComponent,
    SearchComponent,
    DropdownComponent,
    MoreOptionComponent,
    FavoriteComponent,
    FavoriteToggleComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SubHeaderComponent,
    SubHeaderLeftSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderRightSectionComponent,
    LineChartComponent,
    StackChartComponent,
    DonutChartComponent,
    PerformanceDonutChartComponent,
    BarLineChartComponent,
    NumberFormatPipe,
    GroupByPipe,
    CurrencyFormatPipe,
    TextSearchPipe,
    TextCategorySearchPipe,
    DateYearDayPipe,
    secondsToTimePipe,
    DeleteConfirmationComponent,
    StockChartComponent,
    MapComponent,
    FilterPillsComponent,
    PillsComponent,
    LeftMenuComponent,
    LeftMenuItemComponent,
    LeftMenuSubmenuItemComponent,
    ConfirmationPopupComponent,
    CancelpopupComponent,
    SiteNavigationPopUpComponent,
    XdContainerComponent,
    SitesFilterComponent,
    CustomerDivisionFilterComponent,
    GeoSalesRegionFilterComponent,
    AccountSearchComponent,
    AutoCompleteDropdownComponent,
    ClickOutside,
    DateRangeDropdownComponent,
    HorizantalBarChartComponent,
    DynamicDropdownComponent,
    FiOmniProjectOverviewComponent,
    SelectDropdownComponent,
    SelectDropdownComponentIntegrated,
    MultipleSitePopupComponent,
    CustomDateRangeDropdownComponent,
    DeltaComponent,
    StatusIconComponent,
    NoDecimalPipe,
    GroupAssetByCategoryPipe,
    SensorMappingFormComponent,
    AccountDetailsComponent,
    DateRangeCampaignDropdownComponent,
    DropdownDynamicComponent,
    BarChartComponent,
    AssetCountComponent,
    DistinctAssetsPipe,
    GroupPortsByAssetPipe,
    ConfigurationPopupComponent,
    DiagnosticWidgetComponent,
    MaximizeDialogComponent,
    ComboBoxComponent,
    DecimalPipe,
    NoCommaPipe,
    RecipientDetailsPopupComponent,
    CustomGridComponent,
    DateTimePipe,
    DateTimeNoSecondsPipe,
    DownloadConfigurationComponent,
    SystemHealthComponent,
    ViewTrendChartComponent,
    GatewayStatisticsComponent,
    ThresholdPopupComponent,
    DataDownloadComponent,
    HardwareDetailsComponent,
    AlarmListComponent,
    SerialNumberSearchComponent,
    ParametersPopupComponent,
    SpeedoMeterGuageComponent,
    SolidGaugeChartComponent,
    TimeRangeSectionComponent,
    SelectDateRangeComponent,
    CustomizeGridPopupWidgetComponent,
    CustomDateMultiviewCalendarComponent,
    FiNumberFormatPipe,
    FiCurrencyFormatPipe,
    GroupSystems,
    CustomEDataOverviewComponent,
  ],
  providers: [
    SharedServiceConfig,
    DateRangeService,
    ConversionUtilityService,
    DateLocaleService,
    ResponsiveService,
    LooperService,
    SharedService,
    DialogService,
    EvocCvocFilterService,
    SSDSharedService,
    DataVisualizationService,
    DataVisualizationServiceConfig,
    CsvExportService,
    UserService,
    AssetDetailsServiceConfig,
    AssetDetailsService,
    NoDecimalPipe,
    {
      provide: MapAPILoader, deps: [AppConfigService], useFactory: MapServiceProviderFactory
    },
    CanDeactivateGuard,
    { provide: 'defaultDateRange', useValue: { Number: 7, Value: 'last7' } },
    { provide: 'startEndDate', useValue: { startDate: '', endDate: '', dateRange: '7' } },
    SystemAssuranceAdminService,
    SystemAssuranceAdminServiceConfig,
    DistinctAssetsPipe,
    GroupPortsByAssetPipe,
    DateTimePipe,
    DateTimeNoSecondsPipe,
    GroupAssetByCategoryPipe,
    GroupSystems
  ]
})

export class SharedModule {
  private static count = 0;
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
