import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTimePipe } from '../../../shared/pipes/date-time-format.pipe';

@Component({
  selector: 'nalco-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent implements OnInit {

  @Input('columns') columns: Array<{
    label: string,
    field: string,
    date?: Date,
    time?: any,
    sortable?: boolean,
    type?: string,
    isDescSort?: boolean,
    isLatestSorted?: boolean
    width?: string;
    isHidden?: boolean;
    thClass?: string;
    tdClass?: string;
    readingTime?: string;
    arrayField?: any;
    readingDate?: any,
    linkTo?: string,
    linkField?: string,
  }>
  @Input('tableClass') tableClass: string;
  @Input('linkField') linkField: string;
  @Input('rows')
  set item(val: any) {
    this.rows = val;
  }
  @Input() enableSorting: boolean;
  @Input() isEditModeEnabled: boolean;
  @Output() onRowsSort = new EventEmitter();
  draggedRowIndex: any;
  isDraggable: boolean = false;
  debounceTimer;

  @Output('navigateLink') navigateLink = new EventEmitter<any>();
  @Output('onRowClick') rowSelected = new EventEmitter<any>();
  @Output('onCellClick') columnSelected = new EventEmitter<any>();
  rows: Array<any>;
  lastColumnSorted: string;

  constructor(
    private dateFormat: DateTimePipe,
    private translate: TranslateService
  ) {
    this.lastColumnSorted = this.translate.instant('ASSET_DETAILS.MEASUREMENT_DETAILS_TABLE.LATEST_VALUE_DATE_AND_TIME');
  }

  ngOnInit() {
  }

  // Fetch Rows
  getRows() {
    return this.rows
  }

  // Navigation Link clicked
  linkClicked(row) {
    this.navigateLink.emit(row)
  }
  //On Row and cell Click
  onRowClick(rowData) {
    this.rowSelected.emit(rowData)
  }

  onCellClick(rowData, cellData) {
    let data = {
      row: rowData,
      column: cellData
    }
    this.columnSelected.emit(data)
  }

  getIconClassForTable(color: string): string {
    switch (color && color.toLowerCase()) {
      case 'red':
        return 'iconwater-HighRiskRed';
      case 'yellow':
        return 'iconwater-LowRiskYellow';
      case 'green':
        return 'iconwater-LowRiskGreen';
      case 'gray':
        return 'grey';
      default:
        return '';
    }
  }

  //Get Data Source Icon
  getIconClassForDataSource(dataSorceValue) {
    if (dataSorceValue != null && dataSorceValue != "")
      return 'showDataSourceIcon';
  }

  public dragStart(event: DragEvent, index) {
    event.dataTransfer.dropEffect = 'move';
    this.draggedRowIndex = index;
  }

  public dragEnter(event: DragEvent, index) {
    event.stopPropagation();
    event.preventDefault();
    if (this.draggedRowIndex !== index) {
      this.arrayMove(this.rows, this.draggedRowIndex, index);
      this.draggedRowIndex = index;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        const sortOrder = this.rows.map((row, index) => {
          return { ContextPointId: row.AssetCpId, MeasurementInstanceId: row.ToMeasurementInstanceId, SortOrder: index };
        })
        this.onRowsSort.emit(sortOrder);
      }, 100);
    }
  }

  public arrayMove(arr, from, to) {
    // Swap the dragged element with its immediate neighbour element
    let temp = arr[from];
    arr[from] = arr[to];
    arr[to] = temp;
  }

  dragEnd() {
    this.draggedRowIndex = -1;
  }

  sortByColumn($event, userClicked) {
    const column = $event;
    if (column && column.sortable) {
      if (userClicked) {
        if (column.isDescSort) {
          this.rows.sort((a, b) => a[column.field].toUpperCase() < b[column.field].toUpperCase() ? 1 : a[column.field].toUpperCase() > b[column.field].toUpperCase() ? -1 : 0);
        }
        else if (column.isDescSort === false) {
          this.rows.sort((a, b) => a[column.field].toUpperCase() > b[column.field].toUpperCase() ? 1 : a[column.field].toUpperCase() < b[column.field].toUpperCase() ? -1 : 0);
        }
        column.isDescSort = column.isDescSort ? false : true;
        this.lastColumnSorted = column.label;
      }
      else {
        if (column.isDescSort) {
          this.rows.sort((a, b) => a[column.field].toUpperCase() > b[column.field].toUpperCase() ? 1 : a[column.field].toUpperCase() < b[column.field].toUpperCase() ? -1 : 0);
        }
        else if (column.isDescSort === false) {
          this.rows.sort((a, b) => a[column.field].toUpperCase() < b[column.field].toUpperCase() ? 1 : a[column.field].toUpperCase() > b[column.field].toUpperCase() ? -1 : 0);
        }
      }
      this.columns.forEach(column => { column.isLatestSorted = false });
      column.isLatestSorted = true;
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && this.columns) {
      this.sortByColumn(this.columns.find(column => column.label === this.lastColumnSorted), false);
    }
  }

  checkNumericValueFont(val) { return isNaN(val); }

}
