import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SharedServiceConfig } from './shared-services.service.config';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import * as sharedContracts from '../../shared/contracts/_contracts';
import * as sharedModels from '../../shared/models/_models';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { AppEnvConfig } from '../../app.env.config';
import { of } from 'rxjs/observable/of';
import { HttpClient } from '@angular/common/http';
import { IContextPointServiceKpi } from '../contracts/IContextPointServiceKpi';
import { IData, ISite } from '../../shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { ITimeZoneCdm } from '../../shared/contracts/IAsset';
import { IDataSource } from '../../asset-harvester/_contracts';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable()
export class SharedService {
    private configEnv: any;
    private usersnavigationmenu: sharedModels.Navigation;
    private defaultOnBoarding: boolean = false;
    private defaultTrialEnabled: boolean = false;
    public masterData = {};
    public plannedAssets: any;
    public assetsChanged:any = [];
    public measurementDetails=[];
    onBoardingUpdated = new Subject<void>();

    constructor(
        private sharedServiceConfig: SharedServiceConfig,
        private httpClient: HttpClient,
        private config: AppEnvConfig,
        private localCacheService: LocalCacheService) {
        this.configEnv = sharedServiceConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
        this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.siteHarversterAggrServerUrl = config.getEnv('siteHarversterAggrServerUrl');
        this.configEnv.siteHarvesterServerUrl = config.getEnv('siteHarvesterServerUrl');
        }
    private parse(url: string, params: string[] = []): string {
        let result: string = url;
        if (params.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
                return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
            });
        }
        return result;
    }
    public getServiceCategoriesAndAssetTypes(serviceName: string, forceLoad: boolean = false): Observable<Array<sharedModels.ServiceCategory>> {
        const text = this.localCacheService.fetchData(serviceName + '_categoriesAndAssets');
        if (text != null && text !== '' && !forceLoad)
            return of(JSON.parse(text));
        else {
            const hostname = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getServiceCategoriesAndAssetTypes}`;
            const url = `${hostname}/${serviceName}`;
            return this.httpClient.get(url).map((response: any) => {
                this.localCacheService.storeData(serviceName + '_categoriesAndAssets', JSON.stringify(response));
                return response;
            })
        }
    }
    public getSiteDetails(siteId: string): Observable<any> {
        const params: any = {
            dateRange: '',
            indicatorType: ''
        };

        const url: string = this.parse(this.configEnv.jsonServerUrl + this.configEnv.apiUrl.getSiteCompliance, [siteId]);
        return this.httpClient.get(url, params).map(response => {
            return response['result'];
        });
    }

    public getSitesForCorporates(siteId: Array<number>): Observable<any> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSitesForCorporates;

        return this.httpClient.post(url, siteId).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getsites(dropDownPaging): Observable<any> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
        const body = JSON.stringify(dropDownPaging);
        return this.httpClient.post(url, body).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getServiceEnabledSites(dropDownPaging:IData): Observable<ISite[]> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getServiceEnabledSites;
        const body = JSON.stringify(dropDownPaging);
        return this.httpClient.post(url, body).map((response:ISite[]) => {
            return response;
        });
    }

    getAssetMeasurementsToCompare(siteId: string, assetIds: Array<string>): Observable<Array<sharedModels.MeasurementToCompare>> {
        const params: any = {};

        params.assetIds = assetIds.join(',');
        params.measurementCategoryId = '';

        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getMeasurementsToCompare, [siteId]);

        return this.httpClient.get(url, { params: params }).map((response: any) => {
            const measurementsResponse = response;
            const measurements = new Array<sharedModels.MeasurementToCompare>();

            if (measurementsResponse.result && measurementsResponse.result.length > 0) {
                measurementsResponse.result.forEach(measurement => {
                    measurements.push(new sharedModels.MeasurementToCompare(measurement));
                });
            }
            return measurements;
        });
    }

    public getUsersNavigationMenu(useremailaddress: string): Observable<sharedModels.Navigation> {
        if (this.usersnavigationmenu) {
            return Observable.of(this.usersnavigationmenu);
        } else {
            const fileTypesFromStorage = this.localCacheService.fetchJson('usersNavigationMenu', 7, 'local');
            if (fileTypesFromStorage) {
                this.usersnavigationmenu = fileTypesFromStorage;
                return Observable.of(this.usersnavigationmenu);
            }
        }

        const params: any = {};
        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getUsersNavigationMenu, [useremailaddress]);
        return this.httpClient.get(url, params).map(response => {
            this.usersnavigationmenu = response['result'];
            this.localCacheService.storeJson('usersNavigationMenu', this.usersnavigationmenu, 'local');
            return this.usersnavigationmenu;
        });
    }

    public getCustomerDivisionData(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagAccountChildren}${filter}/${iD}`;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getCustomerDivisionChild(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagParentEdgeChildren}${filter}/${iD}`;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getCustomerDivisionChildren(ids: Array<number>): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagParentEdgeMultipleChild}`;
        return this.httpClient.post(url, ids).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSalerRegionDistrictTerritories(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getRegionCountries(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRegionCountries, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagRegionCountries);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagRegionCountries;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getProjectOverview(projectID: string, unitsOfMeasure: string): Observable<any> {

        const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getProjectOverview}${projectID}?unitStandard=${unitsOfMeasure}`;
        return this.httpClient.get(url)
            .map((response: any) => response);
    }

    public getCorporateSetting(cpId: string): Observable<any> {
        const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getCorporateSetting}${cpId}`;
        return this.httpClient.get(url)
            .map((response: any) => response.json());
    }

    public getMasterDataByContextType(contextPointType: string, dagId: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getAssetCategoriesMasterData}/${contextPointType}/AssetHarvester/${dagId}`;
        let key = contextPointType;
        if ((JSON.stringify(this.masterData) != JSON.stringify({})) && this.masterData[key]) {
            return of(this.masterData[key]);
        } else {
            return this.httpClient.get(url).map((response: any) => {
                try {
                    this.masterData[key] = response;
                    return response;
                } catch (e) {
                    return response;
                }
            });
        }
    }

    public getAssetsByAssetType(siteId: number, assetGraphFeature, groupCpId?: number): Observable<any> {
        const dagType = "NalcoWater"
        let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getAssetsByAssetType}/${siteId}/${assetGraphFeature}?dagType=${dagType}`;
        if (groupCpId) {
            url += `&groupCpId=${groupCpId}`;
        }
        // adding more ContextPointSubTypeid as part of US-454239
        const obj = [25, 69, 193, 44, 64, 96, 174];
        return this.httpClient.post(url, obj).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getPlannedAssets(dagId, isChanged: boolean): Observable<any> {
        const url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.getAssetCount, [dagId]);
        let key = dagId;
        if (this.assetsChanged[key] && !isChanged) {
            return of(this.assetsChanged[key]);
          }else {
            return this.httpClient.get(url).map((response) => {
                this.assetsChanged[key] = response;
                return response;
              })
      }
    }
    public savePlannedAssets(dagId, totalAssetsPlanned): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.savePlannedAssets, [dagId, totalAssetsPlanned]);
        return this.httpClient.post(url, {}).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getContextPointKpiMapData(contextPointId: number, culture?: string ): Observable<IContextPointServiceKpi> {
        let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getContextPointKpiMapData}` + '/' + contextPointId;
        if (culture) {
            url += `?culture=${culture}`;
        }
        return this.httpClient.get(url).map((response: any) => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public saveOnBoarding(dagId, IsOnboarding): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.saveOnBoarding, [dagId, IsOnboarding]);
        return this.httpClient.post(url, {}).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }
    // For Purate Details Tab System Assurance
    public getMeasurements(assetContextPointId: number, siteCpId: number, startOn?: any,
        endBefore?: any, body?: any) {
        let url: any;
        url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementsDetails}?siteCpId=${siteCpId}`;
        if (assetContextPointId !== null && assetContextPointId !== undefined) {
            url += `&assetContextPointId=${assetContextPointId}`;
        }
        if (startOn !== null && startOn !== undefined) {
            url += `&startOn=${startOn}`;
        }
        if (endBefore !== null && endBefore !== undefined) {
            url += `&endBefore=${endBefore}`;
        }
        let key = assetContextPointId + '_' + startOn + '_' + endBefore;
        if (this.measurementDetails[key]) {
            return of(this.measurementDetails[key]);
        } else {
            return this.httpClient.get(url, body).map(response => {
                try {
                    this.measurementDetails[key] = response;
                    return response;
                } catch (e) {
                    return response;
                }
            });
        }
    }
    //For getting DataSource details at AH and Sensor Mapping Page
    public getDataSourceContextPoints(automaticDataSourceSubType: string, contextPointType: string, contextPointCommonSubType: string, serialNumber: any): Observable<IDataSource[]> {
        let url: string;
        if (automaticDataSourceSubType == 'EcolabDataCollector') {
          url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDataSourceContextPoints,
            [automaticDataSourceSubType, serialNumber]);
        } else {
          url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDatasourceDetails,
            [serialNumber, contextPointType, contextPointCommonSubType]);
        }
        return this.httpClient.get(url).map((response: any) => {
          try {
            return response;
          } catch (e) {
            return response;
          }
        });
    }

    //For getting DataSource details at AH and Sensor Mapping Page
    public getDataSourceContextPointsSiteHarvester(automaticDataSourceSubType: string, contextPointType: string, contextPointCommonSubType: string, serialNumber: any): Observable<IDataSource[]> {
        const url = this.parse(this.configEnv.siteHarversterAggrServerUrl + this.configEnv.apiUrl.getDatasourceDetailsSiteHarvester,
          [serialNumber, contextPointType, automaticDataSourceSubType]);
        return this.httpClient.get(url).map((response: any) => {
          try {
            return response;
          } catch (e) {
            return response;
          }
        });
    }
    //For getting Time Zones
    public getTimeZone(): Observable<ITimeZoneCdm[]> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.timeZoneCdms;
        return this.httpClient.get(url).map((response: any) => {
            try {
            return response;
            } catch (e) {
            return response;
            }
        });
    }
    clearCachedAssets(siteId: number): void {
        const key = siteId;
        if (this.assetsChanged[key]) {
            delete this.assetsChanged[key];
        }
     }
}
