import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { SharedRoService } from '../../services/shared-ro.service';


@Component({
  selector: 'nalco-charge-balance',
  templateUrl: './charge-balance.component.html',
  styleUrls: ['./charge-balance.component.scss']
})
export class ChargeBalanceComponent extends XdComponentState implements OnInit {
  @Input() result;
  @Input() modalText: string;
  @Input() closeIcon?: boolean = false;
  @Input() closeAsCancel?: boolean = false;
  @Input() chargeBalanceData: any;
  @Input() ionTypes: any;
  @Output('cancelModal') cancelModal = new EventEmitter<any>();
  @Output('submitModal') submitModal = new EventEmitter<any>();
  chargeBalanceForm: FormGroup;
  balanceWith: any;
  selectedIon: any;

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private roService: SharedRoService,
    ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  dimissModal() {
    this.activeModal.close();
  }

  submitChargeModal() {
    const addedIon = {
      details: this.result.IonBalanceTypes.find(x => x.Name === this.chargeBalanceForm.controls['balance'].value),
      value: this.chargeBalanceForm.controls['balanceValue'].value,
      selectedIon: JSON.parse(this.ionTypes).filter(x => x.Name == this.chargeBalanceForm.controls['balance'].value)
    }
    this.submitModal.emit(addedIon);
    this.activeModal.close();
    this.roService.setForms({ 'chargeBalance': addedIon });
  }

  createForm() {
    this.chargeBalanceForm = this.formBuilder.group({
      totalCations: this.result.TotalCationsCaCO3.toFixed(3),
      totalAnions: this.result.TotalAnionsCaCO3.toFixed(3),
      difference: this.result.DifferenceCaCO3.toFixed(3),
      balance: this.chargeBalanceData !== null ? this.chargeBalanceData.Name : '',
      balanceValue: this.chargeBalanceData !== null ? this.result.IonBalanceTypes.filter(x => x.Name == this.chargeBalanceData.Name)[0].AddedIon.toFixed(3) : ''
    });
    this.chargeBalanceForm.controls['totalCations'].disable();
    this.chargeBalanceForm.controls['totalAnions'].disable();
    this.chargeBalanceForm.controls['difference'].disable();
    this.chargeBalanceForm.controls['balanceValue'].disable();
  }

  onChangeBalance() {
    this.balanceWith = this.result.IonBalanceTypes.find(x => x.Name === this.chargeBalanceForm.controls['balance'].value)
    this.chargeBalanceForm.controls['balanceValue'].setValue(this.balanceWith.AddedIon.toFixed(3));
    this.selectedIon = JSON.parse(this.ionTypes).filter(x => x.Name == this.chargeBalanceForm.controls['balance'].value);
  }
}
