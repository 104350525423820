import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AppEnvConfig } from '../../app.env.config';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { EDataOverviewServiceConfig } from './e-data-overview.service.config';
import { UploadDocument } from '../../shared-my-documents/_models/ReportDocument'
import { map } from 'rxjs/operators';
import { SiteInfo } from '../../fsqs/services/fsqs.service.model';
import { SetupConfigResponse } from '../_models/SetupConfig';
// /fsqs/_services/fsqs.service.model';

@Injectable({
  providedIn: 'root'
})
export class EDataOverviewService {

  // declare all the variables
  private configEnv: any;
  private allConfigs: any[] = [];

  constructor(private eDataOverviewServiceConfig: EDataOverviewServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {
    this.configEnv = eDataOverviewServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.apiLogSheetServerUrl = config.getEnv('apiLogSheetServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
  }

  public getsites(dropDownPaging): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
    const body = JSON.stringify(dropDownPaging);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getEDataConfigurations(siteId: any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getConfiguration}?siteCpId=${siteId}&configurationStatus=Active`;
    return this.httpClient.get(url).map((response: any) => {
      try {
        this.allConfigs = response;
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getConfigSetup(configId: any, startDate: any, endDate: any, pageSize: any): Observable<any> {
    let selectedConfig = this.allConfigs.filter(val => val.id == configId);
    let partitionsize = 200;
    let mapping = selectedConfig[0].Mapping;
    let numberOfHistoryDataCalls = Math.ceil(mapping.length / partitionsize)
    const getConfigList = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getConfigSetup}?configurationId=${configId}&isMock=${false}`;
    const getHistoryData = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getHistoryData}?configurationid=${configId}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&partitionsize=${partitionsize}&partitionumber=${1}&isMock=${false}`;
    if (numberOfHistoryDataCalls <= 1) {
      let res = forkJoin([
        this.httpClient.get(getConfigList).map(res => res),
        this.httpClient.get(getHistoryData).map(res => res)
      ])
      console.log('res from service', res)
      return res;
    } else if (numberOfHistoryDataCalls > 1) {
      let forkJoinInputs = [];
      forkJoinInputs.push(this.httpClient.get(getConfigList).map(res => res));
      for (var i = 0; i < numberOfHistoryDataCalls; i++) {
        forkJoinInputs.push(
          this.httpClient.get(`${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getHistoryData}?configurationid=${configId}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&partitionSize=${partitionsize}&partitionNumber=${i + 1}&isMock=${false}`).map(res => res)
        )
      }
      let res = forkJoin(
        forkJoinInputs
      );
      return res;
    }
  }

  public IsReadingCollectionExists(configId: any, date: any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.IsReadingCollectionExists}?configurationid=${configId}&measurementDate=${date}&isMock=${false}`;
    return this.httpClient.get(url).map((response: any) => response);
  }

  public saveMeasurementInstanceReading(savedData): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveMeasurementInstanceReading}?isMock=${false}`;
    const body = JSON.stringify(savedData);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public saveContextPointAnnotation(data): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.SaveContextPointAnnotation}`;
    return this.httpClient.post(url, data).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getContextPointAnnotations(measurementDate, data): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getContextPointAnnotations}?measurementDate=${measurementDate}`;
    return this.httpClient.post(url, data).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getCalulatedMeasurementValueByFormula(data): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCalculatedMeasurementValue}`;
    return this.httpClient.post(url, data).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public saveMeasurementAnnotation(data): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveMeasurementAnnotation}`;
    return this.httpClient.post(url, data).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getMeasurementAnnotations(measurementInstanceId: any, measurementDate: any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementAnnotations}?measurementInstanceId=${measurementInstanceId}&measurementDate=${measurementDate}`;
    return this.httpClient.get(url).map((response: any) => response);
  }

  public deleteReadingCollection(configId: any, measurementDate: any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.deleteReadingCollection}?configurationId=${configId}&measurementDate=${measurementDate}`;
    return this.httpClient.post(url, null).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getEDataReadingsInExcel(startDate: any, endDate: any,configurationId: any, siteCpId: any,filename:any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getEDataReadingsInExcel}?startDate=${startDate}&endDate=${endDate}&configurationId=${configurationId}&siteCpId=${siteCpId}&filename=${filename}`;
    return this.httpClient.get(`${url}`,{responseType: 'blob'});
  }

  public getDataSourceDetails(siteCpID: number): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDataSourceDetails}/${siteCpID}`
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  /**
   * @description passing sitid and configid as input parameters and return stream of blob storage
   * @param siteCpId selected site
   * @param configId selected configuration id
   * @param dateandTime current date and time in required format yyyy-MMM-dd hh:mm a
   * @returns blob of stream
   */
  public getExcelTemplate(siteCpId:string,configId:string,dateandTime:string,configData:SetupConfigResponse):Observable<Blob>{
    // const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getExcelTemplate}/${siteCpId}/${configId}`
    const url = `${this.configEnv.apiLogSheetServerUrl}${this.configEnv.apiUrl.getExcelTemplate}/${siteCpId}/${configId}`
    const body = configData;
    let params = new HttpParams().set('exportedDateTime', dateandTime,)
    return this.httpClient.post(`${url}`,body,{params:params,responseType: 'blob'});
  }
  /**
   * @description upload file and docuemnt name and extension
   * @param uploadDocument document file name and details of document
   * @param startDate selected start date
   * @param endDate selected end date
   * @param uploadDateTime current date and time in format
   * @returns boolean value if file was uploaded or not
   */
  public saveUploadedDocument(uploadDocument: UploadDocument,startDate:string,endDate:string,uploadDateTime): Observable<boolean> {
    // const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.uploadedExcelDocument}`;
    const url = `${this.configEnv.apiLogSheetServerUrl}${this.configEnv.apiUrl.uploadedExcelDocument}`;
    const params = {
      fileName: uploadDocument.DisplayName,
      startDate: startDate,
      endDate: endDate,
      uploadDateTime: uploadDateTime
      };
      return this.httpClient.post(url, uploadDocument.Content, { params }).pipe(
      map((response: boolean) => {
          return response;
      }),)

  }
/**
 * @description checking user has access upload and download log button
 * @returns boolean value if user is able to access or not
 */
  public permissionCheck(): Observable<boolean>{
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.uploadPermission}`;
    return this.httpClient.get<boolean>(url).map((response: boolean) => {
    return response
  });
  }

  public getSitebyID(siteIdValue:number){
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getSitebyID}?siteId=${siteIdValue}`;
    return this.httpClient.get<SiteInfo>(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
}
