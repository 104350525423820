import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuService } from './menu.service';

// import { LooperService } from './../shared/services/looper.service';
// import { SharedMainService } from './../shared/services/shared-main.service';
// import { SharedMainConfig } from './../shared/services/shared-main.service.config';

import { DividerModule } from '../shared-ui-refresh/divider/divider.module';

import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu.component';
import { SubmenuItemComponent } from './submenu-item/submenu-item.component';

import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';



@NgModule({
  declarations: [MenuItemComponent, SubmenuItemComponent, MenuComponent],
  exports: [MenuItemComponent, SubmenuItemComponent, MenuComponent],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule,
    TranslateModule.forChild({
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    DividerModule
  ],
  providers: [MenuService]
})
export class MenuModule {
  private static count = 0;
  // static forRoot(): ModuleWithProviders {
  //     return {
  //         ngModule: SharedMainModule,
  //         providers: [],
  //     };
  // }
}
