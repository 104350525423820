import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import type { NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import type { Subscription } from 'rxjs/Subscription';
import type { INotification } from './_contracts';
import { NotificationService } from './_services/notification.service';
import { ConfirmationPopupComponent } from '../shared/components/confirmation-popup/confirmation-popup.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'nalco-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit{

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,) {
  }

  // The type for this allNotification is not yet decided, we will update it once we get the type confirmed.
  allNotification = [];
  totalNotificationCount: string;
  showAccordion: boolean = false;
  errorInfo: HttpErrorResponse;
  private subscriptions: Subscription[] = [];
  @Output() loader: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    this.getAllUnreadNotifications();
    this.subscriptions.push(this.notificationService.refreshNotification$.subscribe((res) => {
      if (res) {
        this.getAllUnreadNotifications();
      }
    }));
  }

  /**
   * @description to get all unread notifications.
   * @returns void
   */
  getAllUnreadNotifications(): void {
    this.subscriptions.push(this.notificationService.getAllUnreadNotifications().subscribe((res: INotification[]) => {
      this.allNotification = res;
      if (this.allNotification.length > 5) {
        this.totalNotificationCount = `(${this.allNotification.length})`;
      }
    }));
  }

  /**
   * @description to show the dropdown model containing notifications
   * @returns void
   */
  onNotificationClick(notification: INotification, event: NgbDropdown): void {
    this.notificationService.setNotificationCategory(notification.NotificationCategoryTypeId);
    if(notification.NotificationCategoryTypeId == this.translate.instant('ASSET_HARVESTER.EDATA_REPLICATE_NOTIFICATION')){
      event.close();
      this.showModal(notification);
    }else if (notification.NotificationCategoryTypeId == this.translate.instant('ASSET_HARVESTER.DATA_CONTINUITY_NOTIFICATION')){
    event.close();
    this.router.navigate(['/merge-system-name', notification.Title, notification.PartitionKey, notification.NotificationProperties.OldDatasourceCpId, notification.NotificationProperties.NewDatasourceCpId]);
    }
  }

  /**
   * @description to show toggle on clicking view all and view less button
   * @returns void
   */
  toggleAccordion(): void {
    this.showAccordion = !this.showAccordion;
  }

  /**
   * @description to show popup to retry failed Edata Request
   * @params notification
   * @returns void
   */
  showModal(notification: any): void{
    const ngbModalOptions : NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'saveExit-modal',
    };
    const modalRef = this.modalService.open(ConfirmationPopupComponent, ngbModalOptions);
    modalRef.componentInstance.modalText = this.translate.instant('ASSET_HARVESTER.RETRY_REPLICATION');
    modalRef.componentInstance.modcontent = notification.Title;
    modalRef.componentInstance.localDriverBtn = false;
    modalRef.componentInstance.alertIcon = false;
    modalRef.componentInstance.disablebtn = true;
    modalRef.componentInstance.closeAsCancel = true;
    modalRef.componentInstance.closeIcon = true;
    modalRef.componentInstance.actionBtn = this.translate.instant('COMMON.RETRY');
    modalRef.componentInstance.submitModal.subscribe(($event) => {
      this.loader.emit(true);

      this.subscriptions.push(this.notificationService.retryReplicateMeasurementsAndLimits(JSON.stringify(notification.id)).subscribe((res: any) => {
      if (res == this.translate.instant('COMMON.TRUE') || res == this.translate.instant('ASSET_HARVESTER.REPLICATION_ALREADY_SUCCESSFULL') || res == this.translate.instant('ASSET_HARVESTER.MAX_RETRY_EXCEEDED')){
          this.notificationService.setMapped(true);
        this.loader.emit(false);
       }else {
        this.loader.emit(false);
       }
    }, error => {
      this.errorInfo = error;
    }));
  });
 }

 /**
   * @description to set the notification title and message
   * @params notification
   * @returns title and message
   */
  getNotificationDetails(notification: any): { title: string, message: string } {
    const notificationTitle = notification.NotificationCategoryTypeId == this.translate.instant('ASSET_HARVESTER.EDATA_REPLICATE_NOTIFICATION') ? this.translate.instant('ASSET_HARVESTER.MESSAGE.REPLICATE_EDATA_MEASUREMENTS') : notification.Title;
    const notificationMessage = notification.NotificationCategoryTypeId == this.translate.instant('ASSET_HARVESTER.EDATA_REPLICATE_NOTIFICATION') ? notification.Title : this.translate.instant('NOTIFICATIONS.NOTIFICATION_MESSAGE');

    return { title: notificationTitle, message: notificationMessage };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

}
