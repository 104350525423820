import {
  Component, HostListener,
  OnDestroy, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { AppEnvConfig } from './app.env.config';
import { AppDynamicsService } from './core/analytics/app-dynamics.service';
import { LocalCacheService } from './core/local-cache/local-cache.service';
import { MsalV2Service } from './core/services/msal-V2.service';
import { UserService } from './core/user/user.service';
import { User } from './shared-main/models/_models';
import { IdleTimeoutConfig } from './shared/_helpers/constants';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthError, AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { MenuService } from './menu/menu.service';
import { SubheaderHandlerService } from './core/nav/subheader-handler.service';
import { ISubheaderLeftSection } from './shared/components/sub-header/sub-header.modal';
@Component({
  selector: 'nalco-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  loading: Boolean = false;
  location: String;
  hideLayout: boolean = false;
  user: User;
  isSubHeaderHidden: boolean = false;
  isMobile: Boolean = false;
  isUserInfoAvailable: boolean = false;
  userInfoCache: any;
  id_token: string;
  allannouncement = [];
  chachedAllAnnouncemnets: any;
  private subscriptions: Subscription[] = [];
  private alive: boolean;
  private globalIdleEnabled = true;
  height: number;
  screenWidth: number;
  idleState = this.translate.get('IDLE_TIMEOUT_POPUP.IDLE_INITIAL_STATE_TEXT');
  timedOut = false;
  lastPing?: Date = null;
  idleCountFormatted = '';
  idleModalRef: NgbModalRef = null;
  @ViewChild('idleModalContent', { read: TemplateRef, static: false })
  idleModalContent: TemplateRef<any>;
  isIframe = false;
  isloggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  userInfoDetails = new BehaviorSubject<any>(null);
  $userInfoDetails = this.userInfoDetails.asObservable();
  hideSubHeader: boolean = false;
  isExpand = false;
  isHomePage = false;
  showLoader = false;
  leftSection: ISubheaderLeftSection; // Getting page information from the existing sub header service
  constructor(
    private translate: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private config: AppEnvConfig,
    private localCacheService: LocalCacheService,
    private userService: UserService,
    private appDynamicsService: AppDynamicsService,
    private idleModal: NgbModal,
    private idle: Idle,
    private msalV2Service: MsalV2Service,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private menuService : MenuService,
    private subHeaderService: SubheaderHandlerService,
  ) {
    this.userInfoCache = JSON.parse(
      this.localCacheService.fetchData('msalUserInfo')
    );
    if (this.localCacheService.fetchData('returnUrl') === null) {
      const urlHash = new URL(window.location.href).hash;
      let redirectURL = new URLSearchParams(urlHash.split('?')[1]).get(
        'returnUrl'
      )
        ? new URLSearchParams(urlHash.split('?')[1]).get('returnUrl')
        : window.location.hash.slice(1);
      if (redirectURL.indexOf('code') < 0) {
        this.localCacheService.storeData('returnUrl', redirectURL);
      }
    }
    this.loading = true;
    this.appDynamicsService.adrumAddScript();
    this.alive = true;
    this.idle.setIdleName('_global_idle_timeout');
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(IdleTimeoutConfig.setIdleTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(IdleTimeoutConfig.setIdleTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = this.translate.get(
          'IDLE_TIMEOUT_POPUP.No_LONGER_IDLE_STATE_TEXT'
        );
        console.log("you're no more idle", new Date());
        this.idleReset();
      })
    );
    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = this.translate.get(
          'IDLE_TIMEOUT_POPUP.TIMED_OUT_IDLE_STATE_TEXT'
        );
        this.timedOut = true;
        this.idleLogout();
      })
    );

    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = this.translate.get(
          'IDLE_TIMEOUT_POPUP.ON_IDLE_STATE_TEXT'
        );
        console.log("you're idle", new Date());
      })
    );
    // get global idle flag from router
    this.subscriptions.push(
      this.router.events
        .pipe(
          tap(),
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activeRoute),
          map((route) => route.firstChild),
          switchMap((route) => route.data),
          map((data) =>
            data['globalIdleEnabled'] != undefined
              ? (this.globalIdleEnabled = data['globalIdleEnabled'])
              : (this.globalIdleEnabled = true)
          )
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleCountFormatted = moment.utc(countdown * 1000).format('mm:ss');
        this.idleState = this.translate.get(
          'IDLE_TIMEOUT_POPUP.TIME_OUT_WARNING_IDLE_STATE'
        );
        let idleModalOptions: NgbModalOptions = {
          container: '.idle-modal-cont',
          backdrop: 'static',
          backdropClass: 'idle-modal-backdrop',
          size: 'sm',
          keyboard: false,
        };
        if (this.idleModalRef === null && this.globalIdleEnabled) {
          this.idleModalRef = this.idleModal.open(
            this.idleModalContent,
            idleModalOptions
          );
        }
      })
    );

    // sets the ping interval to 15 seconds
    // this.keepalive.interval(IdleTimeoutConfig.setKeepAliveInterval);

    // this.subscriptions.push(
    //   this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()))
    // );

    this.idleReset();
  }

  ngOnInit() {
    // Get the page route name
    this.getPagetRoute();
    // if no signed-in account found will redirect back to sign-in authority page
    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if ((this.authService.instance.getAllAccounts().length === 0)) {
          this.msalV2Service.clearMSALCache();
          this.authService.loginRedirect();
          return false;
        }
        if(result.eventType === EventType.ACQUIRE_TOKEN_FAILURE){
          console.log(result.payload);
          return false;
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isloggedIn = this.authService.instance.getAllAccounts().length > 0;
      })
    // login success event
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        console.log("login-success",result); //required for debugging
      });
    //  login failure event
    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError || result.error instanceof InteractionRequiredAuthError) {
          // Do something with the error
          this.msalV2Service.clearMSALCache();
          this.authService.loginRedirect();
        }
      });
    this.msalV2Service.authInit();
    this.translate.addLangs(this.config.getEnv('languages'));
    this.translate.setDefaultLang('en');
    this.translate.use(this.userService.getUserCulture());
    this.subscribeToUserInfoDetails();
    this.subscriptions.push(this.menuService.navExpand.subscribe((value) =>{
      if (JSON.parse(this.config.getEnv('isNewUIEnabled').toString())) {
      this.isExpand = value;
      }
    }
  ));
  }

  /**
 * @description - Handles the change in subheader visibility by updating the 'isSubHeaderHidden' property.
 * @param hidden - A boolean value indicating whether the subheader is hidden(true) or not(false).
 * @returns - This method does not return a value.
 */
  onSubHeaderHiddenChange(hidden: boolean) {
    this.isSubHeaderHidden = hidden;
  }

  subscribeToUserInfoDetails() {
    this.subscriptions.push(
      this.userService.$userInfoDetails.subscribe(
        (userData) => {
          this.userInfoCache = JSON.parse(
            this.localCacheService.fetchData('msalUserInfo')
          );
          if (userData || this.userInfoCache) {
            this.handleUserDataAvailable();
          } else {
            this.isUserInfoAvailable = false;
          }
        },
        (error) => {
          // receiving the error to show appropriate message to user if there is no access to application
          if (error.status === 417) {
            this.isUserInfoAvailable = false;
            // this.translate.addLangs(this.config.getEnv('languages'));
            // this.translate.setDefaultLang(this.userService.getUserCulture());
            this.loading = false;
          }
        }
      )
    );
  }

  handleUserDataAvailable(): void {
    this.isUserInfoAvailable = true;
    this.user = new User(this.userInfoCache);
    this.isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|windows phone/.test(
        navigator.userAgent.toLowerCase()
      );
    if (this.user) {
      this.loading = false;
    }
    this.subscribeToRouterEventNavigationEnd();
    if (this.user && this.user.authenticated) {
      this.localCacheService.storeData('username', this.user.userPrincipalName);
      this.showAnnouncements(this.userInfoCache.UserId);
    }
  }

  subscribeToRouterEventNavigationEnd() {
    this.hideSubHeader = false;
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.hideLayout = this.activeRoute.firstChild.snapshot.data.hideLayout;
      this.hideSubHeader = this.activeRoute.firstChild.snapshot.data.hideSubHeader;
      window.scrollTo(0, 0);
    });
  }

  showAnnouncements(userId: any) {
    this.chachedAllAnnouncemnets =
      this.localCacheService.fetchData('AllAnnouncemnet');
    this.allannouncement = JSON.parse(this.chachedAllAnnouncemnets);
    if (this.allannouncement && this.allannouncement.length > 0) {
      this.allannouncement.forEach((item) => {
        if (
          this.localCacheService.fetchData(
            'AnnouncemnetStatus' + item.AnnouncementId
          ) ===
          'Announcemnet' + item.AnnouncementId
        ) {
          item.Isdisable = false;
        }
      });
      this.setPopUpPosition();
    } else {
      this.showPopup(userId);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach((s) => s.unsubscribe());
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  showPopup(userId: any) {
    this.allannouncement = [];
    if (userId) {
      // Commenting it for now will have a new call "GetAllAnnouncements" instead of this

      /* this.userService.getLatestAnnouncements(userId).subscribe((response) => {
        if (response && response.length > 0) {
          response.forEach((element) => {
            const dateToday = new Date();
            let endDate = new Date(element.EndDate);
            if (dateToday <= endDate) {
              this.allannouncement.push({
                AnnouncementId: element.Id,
                Message: element.Message,
                EndDate: element.EndDate,
                Isdisable: true,
              });
            }
            this.setPopUpPosition();
            this.localCacheService.storeData(
              'AllAnnouncemnet',
              JSON.stringify(this.allannouncement)
            );
          });
        }
      }); */
    }
  }

  onCloseClick(index, announcementId) {
    if (announcementId) {
      this.localCacheService.storeData(
        'AnnouncemnetStatus' + announcementId,
        'Announcemnet' + announcementId
      );
      this.allannouncement.splice(index, 1);
      this.setPopUpPosition();
    }
  }

  onGotItClick(index, announcementId) {
    let userId = this.userService.userInfo.UserId;
    if (userId && announcementId) {
      this.userService
        .saveAnnouncementStatusForUser(userId, announcementId)
        .subscribe((response) => {
          if (response) {
            this.localCacheService.storeData(
              'AnnouncemnetStatus' + announcementId,
              'Announcemnet' + announcementId
            );
            this.allannouncement.splice(index, 1);
            this.setPopUpPosition();
          }
        });
    }
  }

  setPopUpPosition() {
    if (this.allannouncement.length > 0) {
      let i = 0,
        x: any,
        y: any,
        z: any;
      x = 130;
      y = this.getWindowWidth();
      z = x + y;
      this.allannouncement.forEach((item) => {
        if (item.Isdisable !== false) {
          if (i === 0) {
            item.top = 0;
          } else if (i === 1) {
            item.top = z;
          } else {
            z = z + y + 130;
            item.top = z;
          }
          i++;
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setPopUpPosition();
  }

  getWindowWidth() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 540) {
      return (this.height = this.isMobile ? 260 : 200);
    } else if (this.screenWidth < 767 && this.screenWidth > 541) {
      return (this.height = 180);
    } else if (this.screenWidth < 1025 && this.screenWidth > 767) {
      return (this.height = 100);
    } else {
      return (this.height = 80);
    }
  }

  idleReset(): void {
    this.idle.watch();
    this.idleState = this.translate.get(
      'IDLE_TIMEOUT_POPUP.RESET_IDLE_STATE_TEXT'
    );
    this.timedOut = false;
    this.idleCountFormatted = '';
  }

    /**
   * @description This function is to get the page title from existing sub header module service 
   */

    getPagetRoute(): void {
      this.subscriptions.push(this.subHeaderService.subheaderLeftSection$.subscribe(async (update) => {
        this.leftSection = await update;
        if (this.leftSection.data) {
         this.isHomePage = false;
        } else {
         this.isHomePage = true
        }
      }));
    }

  idleStay(): void {
    const activeIdleModal = this.idleModalRef;
    if (activeIdleModal) {
      activeIdleModal.close();
      this.idleModalRef = null;
    }
    this.idleReset();
  }

  idleLogout(): void {
    const activeIdleModal = this.idleModalRef;
    if (activeIdleModal) {
      activeIdleModal.close();
      this.idleModalRef = null;
    }
    this.msalV2Service.logout();
  }

  handleLoading(loader: boolean){
    this.showLoader = loader;
  }
}
