
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';
import { CancelpopupComponent } from '../components/cancel-popup/cancel-popup.component';
//import { BuildComponent } from '../components/build/build.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: ``
})

export class DialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }
}

@Injectable()
export class DialogService {

  constructor(private modalService: NgbModal, private translate: TranslateService
  ) { }

  public confirm() {
    const modalRef = this.modalService.open(CancelpopupComponent, { windowClass: 'saveExit-modal' });
    modalRef.componentInstance.modalText = this.translate.instant('ASSET_TREE.MESSAGE.LEAVE_SITE');
    modalRef.componentInstance.modcontent = this.translate.instant('ASSET_TREE.MESSAGE.SAVE_ALERT');
    modalRef.componentInstance.disablebtn = true;
    modalRef.componentInstance.showBtn = false;
    modalRef.componentInstance.confirmBtn = this.translate.instant('COMMON.OK');
    modalRef.componentInstance.closeBtn = this.translate.instant('COMMON.CANCEL');
    modalRef.componentInstance.closeIcon = true;
    //this.buildComponent.modalChildRef = modalRef;
    modalRef.componentInstance.deleteModal.subscribe(($event) => {
      modalRef.componentInstance.activeModal.close(true);
    });
    modalRef.componentInstance.stayModalPopUp.subscribe(($event) => {
      modalRef.componentInstance.activeModal.close(false);
    });
    modalRef.componentInstance.changeRef.markForCheck();
    return modalRef.result;
  }
}