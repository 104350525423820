import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { IAppliedFilter } from '../../../app/shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { AppEnvConfig } from '../../app.env.config';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { AccountSearch } from '../../shared/models/AccountSearch';
import { Permissions } from '../../shared/models/Permissions';
import { User, UserSettings } from '../../shared/models/User';
import { ISiteDetails, ISiteFilterDetails } from '../../climate-intelligence/_contracts/ISite';
@Injectable()
export class UserService {
  private configEnv: any = {};
  private defaultExpires: number = 86400; //24Hrs
  private userSettingsKey: string = 'UserSettings';
  private userRole: string = 'UserRole';
  private userInfoDetails = new BehaviorSubject<any>(null);
  $userInfoDetails = this.userInfoDetails.asObservable();
  userInfo;
  user: User;
  constructor(
    private config: AppEnvConfig,
    private cache: LocalCacheService,
    private httpClient: HttpClient
  ) {
    this.configEnv.jsonServerUrl = this.config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrl = this.config.getEnv('apiServerUrl');
  }

  setUserInfo(userInfo) {
    this.userInfoDetails.next(userInfo);
  }

  getUserSettings(): Observable<UserSettings> {
    let urlPermissions = `${this.configEnv.apiServerUrl}Users/Token`;
    let urlAcccounts = `${this.configEnv.apiServerUrl}DagTypeSubTypes/All/4000/1`;
    let urlSites = `${this.configEnv.apiServerUrl}Users/UserSiteAccess`;


    let settings: UserSettings = JSON.parse(
      this.cache.fetchData(this.userSettingsKey)
    );

    if (settings) {
      return Observable.of(settings);
    }

    return forkJoin([
      this.httpClient.get(urlAcccounts).map((res) => res),
      this.httpClient.get(urlSites).map((res) => res),
      this.httpClient.get(urlPermissions).map((res) => res),
    ]).map((data: any[]) => {
      let accounts: Array<any> = data[0];
      let sites: number = data[1];
      this.userInfo = data[2];
      this.cache.storeData('msalUserInfo', JSON.stringify(this.userInfo));
      this.cache.storeData(
        'User_FirstName',
        this.userInfo['FirstName'],
        'local'
      );
      this.cache.storeData('User_LastName', this.userInfo['LastName']);
      this.userInfoDetails.next(this.userInfo);
      this.user = new User(this.userInfo);
      let user: any = JSON.parse(this.cache.fetchData('msalUserInfo'));
      let isInternal: boolean = !!user ? user.IsInternalUser : false;
      let accountList: Array<AccountSearch> = [];
      let permissionList: Array<Permissions> = [];

      if (accounts) {
        //Sort Accounts alphabetically
        accounts.sort((a, b) =>
          a.ContextPointName !== b.ContextPointName
            ? a.ContextPointName < b.ContextPointName
              ? -1
              : 1
            : 0
        );
        //Populate accountList
        accounts.forEach((r) => {
          accountList.push(
            new AccountSearch(r.ContextPointId, r.ContextPointName)
          );
        });

        var first = accounts[0];
      }

      if (user.Permissions) {
        user.Permissions.forEach((item) => {
          permissionList.push(new Permissions(item));
        });
      }

      var newSettings = new UserSettings({
        DefaultContextPointId: first?.ContextPointId,
        DefaultContextPointName: first?.ContextPointName,
        IsInternalUser: isInternal,
        IsStreetAccountUser: user.IsStreetAccountUser,
        HasNalcoAccesUser: user.HasNalcoAccesUser,
        Permissions: permissionList,
        Accounts: accountList,
        UserPreference: user.UserPreference,
        Culture: user.Culture,
        UserId: user.UserId,
      });
      //newSettings.IsInternalUser = false; //Uncomment to test
      if (newSettings.IsInternalUser) {
        newSettings.DefaultContextPointId = null;
        newSettings.DefaultContextPointName = null;
      }

      if (sites > 0) {
        newSettings.DefaultSiteId = sites.toString();
        //newSettings.DefaultSiteId = '954970';
        newSettings.IsSingleSiteUser = true;
      }

      this.setDefaultCache(newSettings);

      return newSettings;
    });
  }

  private setDefaultCache(data: UserSettings) {
    this.cache.storeData(this.userSettingsKey, JSON.stringify(data));
    this.cache.storeData('defaultContextPointId', data.DefaultContextPointId);
    // this.cache.storeData('appliedfilterDetails', JSON.stringify(null), 'local');
  }

  getEffectiveContextPointId(): string {
    let contextPointId = this.cache.fetchData('defaultContextPointId');
    const details = JSON.parse(
      this.cache.fetchData('appliedfilterDetails', 'local')
    );
    if (details && details.contextPointId != null) {
      contextPointId = details.contextPointId;
    }
    return contextPointId;
  }

  saveFilterDetails(filterDetails) {
    this.cache.storeData(
      'appliedfilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }

  saveSiteTabSiteFilterDetails(filterDetails) {
    this.cache.storeData(
      'appliedSiteTabSiteFilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }

  saveSiteTabSiteDetails(siteDetails) {
    this.cache.storeData(
      'SiteTabSiteDetails',
      JSON.stringify(siteDetails),
      'local'
    );
  }

  saveSSDFilterDetails(filterDetails) {
    this.cache.storeData(
      'appliedSSDfilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }
  saveFIFilterDetails(filterDetails: IAppliedFilter) {
    this.cache.storeData(
      'appliedFIfilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }
  /**
   * @description saving 'appliedCIfilterDetails' in local storage 
   * @param filterDetails  is object of type <ISiteFilterDetails>
   */
  saveCIFilterDetails(filterDetails : ISiteFilterDetails) {
    this.cache.storeData(
      'appliedCIfilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }

  savePaperFilterDetails(filterDetails : ISiteFilterDetails) {
    this.cache.storeData(
      'appliedPaperfilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }

  saveWQStreetFilterDetails(filterDetails) {
    this.cache.storeData(
      'appliedWQStreetFilterDetails',
      JSON.stringify(filterDetails),
      'local'
    );
  }

  clearFilterDetails() {
    this.cache.clearData('appliedfilterDetails', 'local');
  }

  clearWQStreetFilterDetails() {
    this.cache.clearData('appliedWQStreetFilterDetails', 'local');
  }

  // saveUserRole(filterDetails) {
  //     this.cache.storeData(this.userRole, JSON.stringify(filterDetails));
  // }
  saveFISiteDetails(siteDetails) {
    this.cache.storeData(
      'FISiteDetails',
      JSON.stringify(siteDetails),
      'local'
    );
  }
  /**
   * @description save 'CISiteDetails' in local storage
   * @param siteDetails is a object of type <ISiteDetails>
   */
  saveCISiteDetails(siteDetails : ISiteDetails) {
    this.cache.storeData(
      'CISiteDetails',
      JSON.stringify(siteDetails),
      'local'
    );
  }
  savePaperSiteDetails(siteDetails : ISiteDetails) {
    this.cache.storeData(
      'PaperSiteDetails',
      JSON.stringify(siteDetails),
      'local'
    );
  }
  saveSSDSiteDetails(siteDetails) {
    this.cache.clearData('SSDSiteDetails', 'local');
    this.cache.clearData('SSDContextPointID', 'local');
    this.cache.clearData('SSDCorpAccountId', 'local');
    this.cache.clearData('SSDCorporateAccountName', 'local');
    this.cache.clearData('SSDSiteName', 'local');
    this.cache.clearData('SSDDisplayName', 'local');
    this.cache.storeData(
      'SSDSiteDetails',
      JSON.stringify(siteDetails),
      'local'
    );
    this.cache.storeData(
      'SSDContextPointID',
      JSON.stringify(siteDetails.ContextPointId),
      'local'
    );
    this.cache.storeData(
      'SSDCorpAccountId',
      JSON.stringify(siteDetails.CorpAccountId),
      'local'
    );
    this.cache.storeData(
      'SSDCorporateAccountName',
      JSON.stringify(siteDetails.CorporateAccountName),
      'local'
    );
    this.cache.storeData(
      'SSDSiteName',
      JSON.stringify(siteDetails.SiteName),
      'local'
    );
    this.cache.storeData(
      'SSDDisplayName',
      JSON.stringify(siteDetails.DisplayName),
      'local'
    );
  }

  getSSDSiteDetails() {
    return JSON.parse(this.cache.fetchData('SSDSiteDetails', 'local'));
  }

  clearSSDSiteDetails(){
    this.cache.clearData('SSDSiteDetails', 'local');
    this.cache.clearData('SSDContextPointID', 'local');
    this.cache.clearData('SSDCorpAccountId', 'local');
    this.cache.clearData('SSDCorporateAccountName', 'local');
    this.cache.clearData('SSDSiteName', 'local');
    this.cache.clearData('SSDDisplayName', 'local');
  }

  getSSDFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedSSDfilterDetails', 'local'));
  }
  getFIFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedFIfilterDetails', 'local'));
  }
  getFISiteFilterDetails() {
    return JSON.parse(this.cache.fetchData('FISiteDetails', 'local'));
  }
  getCIFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedCIfilterDetails', 'local'));
  }
  getCISiteFilterDetails() {
    return JSON.parse(this.cache.fetchData('CISiteDetails', 'local'));
  }
  getPaperFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedPaperfilterDetails', 'local'));
  }
  getPaperSiteFilterDetails() {
    return JSON.parse(this.cache.fetchData('PaperSiteDetails', 'local'));
  }
  getSiteTabSiteFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedSiteTabSiteFilterDetails', 'local'));
  }

  getSiteTabSiteDetails() {
    return JSON.parse(this.cache.fetchData('SiteTabSiteDetails', 'local'));
  }
  getFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedfilterDetails', 'local'));
  }

  getWQStreetFilterDetails() {
    return JSON.parse(this.cache.fetchData('appliedWQStreetFilterDetails', 'local'));
  }

  getCurrentSettings(): UserSettings {
    return JSON.parse(this.cache.fetchData(this.userSettingsKey));
  }

  isInternalUser(): boolean {
    const user: UserSettings = JSON.parse(
      this.cache.fetchData(this.userSettingsKey)
    );
    return user ? user.IsInternalUser : false;
  }

  isStreetAccountUser(): boolean {
    const user: UserSettings = JSON.parse(
      this.cache.fetchData(this.userSettingsKey)
    );
    return user ? user.IsStreetAccountUser : false;
  }
  getUserRole() {
    const user: UserSettings = JSON.parse(
      this.cache.fetchData(this.userSettingsKey)
    );
    if (user != null) {
      return user.Permissions;
    } else {
      return null;
    }
  }

  getUserCulture(): string {
    let userLanguage = 'en';
    const userSettings = this.getCurrentSettings();
    if (userSettings) {
      const userCulture = userSettings.Culture.match(/en|es|pt|zh|de|nl|fr|ru/);
      userLanguage = userCulture != null ? userCulture[0] : 'en';
    }
    return userLanguage;
  }

  // Commenting it for now will have a new call "GetAllAnnouncements" instead of this

  /* public getLatestAnnouncements(userId): Observable<any> {
    let url =
      `${this.configEnv.apiServerUrl}api/v1/Announcement/GetLatestAnnouncements` +
      '?userId=' +
      userId;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  } */

  public saveAnnouncementStatusForUser(
    userId,
    announcementId
  ): Observable<any> {
    let url =
      `${this.configEnv.apiServerUrl}api/v1/Announcement/SaveAnnouncementStatusForUSer` +
      '?userId=' +
      userId +
      '&announcementId=' +
      announcementId;
    let body: any;
    return this.httpClient.post(url, body).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  clear() {
    this.cache.clearAll('local');
    this.cache.clearAll('session');
  }
}
