<nalco-loader
  [loader]="loading"
  [transparentBG]="true"
  [fitToScreen]="true"
></nalco-loader>
<!-- <div class="e-data"> -->
<div>
  <nalco-tile
    [parentInstance]="myParentInstance()"
    class="no-header"
    [errorData]="errorInfo"
    [showMoreOptions]="false"
    [showInfoIcon]="false"
    [showCardHeader]="false"
    class="eData-tile"
  >
    <div tile-content class="p-2" *ngIf="!isData">
      <div class="row mt-3">
        <div class="row col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!fromTVD">
          <div class="d-flex">
            <div class="col-lg-2">
              <button
                class="noborderbtn"
                *ngIf="showExpandBtn"
                (click)="expandAll()"
                [ngClass]="expandbtn ? 'normalclass' : 'blueclass'"
                [disabled]="expandbtn"
              >
                {{ "Edata.EXPAND_ALL" | translate }}
              </button>
            </div>
            <div class="col-lg-3">
              <button
                class="noborderbtn"
                *ngIf="showCollapsebtn"
                (click)="collapseAll()"
                [ngClass]="collapsebtn ? 'normalclass' : 'blueclass'"
                [disabled]="collapsebtn"
              >
                {{ "Edata.COLLAPSE_ALL" | translate }}
              </button>
            </div>
            <span *ngIf="showCommaSeparator">
              {{ "Edata.COMMA_SEPARATOR" | translate }}
            </span>
          </div>
        </div>
        <div
          [ngClass]="
            fromTVD ? 'row' : 'row col-lg-6 col-md-6 col-xs-12 col-sm-12'
          "
        >
          <div class="col-lg-4">
            <span class="d-inline-flex">
              <span
                class="ico-info-thin px-2"
                placement="bottom"
                [ngbTooltip]="tooltipContent"
              ></span>
              <ng-template #tooltipContent>
                <p class="tooltip-content">
                  {{ "Edata.E_DTA_INFO" | translate }}
                </p>
              </ng-template>
              <label>{{ "Edata.E_DATA_AUTOSAVE" | translate }}</label>
            </span>
          </div>
          <div class="col-lg-4 pb-1">
            <button
              class="btn btn-primary overflow-ellipsis completeBtn"
              *ngIf="showCompleteEntryBtn"
              (click)="completeEntry()"
              [disabled]="disableCompleteEntryBtn"
            >
              {{ "Edata.COMPLETE_ENTRY" | translate }}
            </button>
          </div>
          <div class="col-lg-4 btnAlign">
            <button
              class="btn btn-primary overflow-ellipsis"
              (click)="showDatepicker()"
              [disabled]="disableBtn"
            >
              {{ "Edata.ADD_NEW_ENTRY" | translate }}
            </button>

            <div class="date-time-wrapper">
              <div class="mt-3" [hidden]="!showDatepickerValue">
                <kendo-datetimepicker
                  [format]="
                    userCulture == 'en-US'
                      ? 'MM/dd/yyyy HH:mm'
                      : 'dd/MM/yyyy HH:mm'
                  "
                  [(value)]="value"
                  [max]="maxDate"
                  [readOnlyInput]="true"
                  #datepicker1
                  [popupSettings]="popupSettings"
                  (click)="calenderPicker($event)"
                >
                </kendo-datetimepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div>
          <!-- [ngClass]="historicalData.length == 0 ? 'col-3': col-12" -->
          <div
            class="table-container p-2 e-data-grid"
            [ngClass]="fromTVD ? 'table-container-TVD' : ''"
          >
            <table
              class="table table-bordered"
              aria-label="EDataEntryGrid"
              #eDataTable
              [ngClass]="fromTVD ? 'table-font' : ''"
            >
              <div class="e-data-table" id="e-data-table">
                <!-- (scroll)="onScroll($event)" -->
                <thead>
                  <tr>
                    <th
                      *ngFor="let column of headers"
                      [ngClass]="
                        fromTVD
                          ? column.field === 'samplename'
                            ? 'first-header1'
                            : column.userStatus === 'new' ||
                              column.userStatus === 'edit'
                            ? 'historicalHeaders blue-col'
                            : 'historicalHeaders'
                          : column.field === 'samplename'
                          ? 'first-header'
                          : column.userStatus === 'new' ||
                            column.userStatus === 'edit'
                          ? 'historicalHeaders green-col'
                          : 'historicalHeaders'
                      "
                    >
                      <div class="row col-12 pr-0 headerWidth">
                        <div
                          [ngClass]="
                            column.field === 'samplename'
                              ? 'col-12 pr-0 pl-0'
                              : 'col-10 p-0'
                          "
                        >
                          <div
                            *ngIf="column.field !== 'samplename' && !fromTVD"
                            class="userName"
                            ngbTooltip="{{ column.title }}"
                            container="body"
                          >
                            {{ column.title }}
                          </div>
                          <div *ngIf="showFilterData">
                            <div
                              *ngIf="column.field === 'samplename'"
                              [ngClass]="
                                rows.length == 0 ? 'emptyRow' : 'alignDropdown'
                              "
                            >
                              <nalco-select-dropdown-integrated
                                noRecoredsLabel="{{
                                  'Edata.NO_MATCHING_RECORDS' | translate
                                }}"
                                (itemSelected)="onSourcesSelection($event)"
                                (itemUnchecked)="OnDeselect($event)"
                                noMatchingRecoredsLabel="{{
                                  'Edata.NO_RECORDS_AVAILABLE' | translate
                                }}"
                                [multiSelection]="true"
                                [list]="Searcharray"
                                [depValue]="clearSourcesOnchange"
                                placeholder="{{
                                  'Edata.SEARCH_LABEL' | translate
                                }}"
                                [showFilterBycategory]="false"
                                [checkedItemsLimit]="Searcharray.length"
                                [displayOnInit]="selectedOptionsOnInit"
                              >
                              </nalco-select-dropdown-integrated>
                              <!-- [showLoader]="systemsLoading" -->
                            </div>
                          </div>
                          <div *ngIf="userCulture == 'en-US'">
                            {{ column.date | date : "MM/dd/yyyy" }}
                          </div>
                          <div *ngIf="userCulture !== 'en-US'">
                            {{ column.date | date : "dd/MM/yyyy" }}
                          </div>
                          <div>
                            {{ column.date | date : "HH:mm" }}
                          </div>
                        </div>
                        <div
                          class="p-0 m-0"
                          [ngClass]="
                            column.field !== 'samplename' ? 'col-2' : ''
                          "
                        >
                          <div
                            ngbDropdown
                            class="d-inline-block dropdown more-option-values float-right"
                            #myDrop="ngbDropdown"
                            *ngIf="column.field !== 'samplename'"
                            [ngClass]="{
                              dropdownButtonBgColor:
                                !fromTVD &&
                                (column.userStatus === 'new' ||
                                  column.userStatus === 'edit'),
                              'more-option-TVD': fromTVD,
                              dropdownButtonBgColorTVD:
                                fromTVD &&
                                (column.userStatus === 'new' ||
                                  column.userStatus === 'edit')
                            }"
                          >
                            <div class="dropdown-content">
                              <button
                                class="btn drop-btn"
                                id="dropdownBasic1"
                                ngbDropdownToggle
                              >
                                <span class="ico-more"></span>
                              </button>
                              <img
                                [ngClass]="
                                  column?.title
                                    ? 'alignCommentTitle'
                                    : 'alignDatasetComment'
                                "
                                *ngIf="column.dataSetComments"
                                src="../../../../assets/images/Message.svg"
                                (click)="
                                  editComment(column.date, column.annotations)
                                "
                              />
                              <div
                                ngbDropdownMenu
                                aria-labelledby="dropdownBasic1"
                              >
                                <button
                                  *ngIf="column.userStatus !== 'new'"
                                  [disabled]="disableEditBtn"
                                  class="dropdown-item"
                                  (click)="editColumn(column)"
                                >
                                  {{ "Edata.EDIT_BUTTON" | translate }}
                                </button>
                                <button
                                  *ngIf="!column.dataSetComments"
                                  class="dropdown-item"
                                  translate
                                  (click)="openCommentModal('ADD', column.date)"
                                >
                                  {{ "Edata.COMMENT_BUTTON" | translate }}
                                </button>
                                <button
                                  class="dropdown-item"
                                  translate
                                  (click)="deleteDataset(column)"
                                >
                                  {{ "Edata.DELETE_BUTTON" | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      *ngIf="historicalData.length == 0"
                      class="noMoreDataMSG"
                    >
                      <div
                        [ngClass]="historicalData.length == 0 ? 'col-12' : ''"
                        *ngIf="historicalData.length == 0"
                      >
                        <label>{{ "Edata.NO_HISTORY_DATA" | translate }}</label>
                      </div>
                    </th>
                    <th
                      *ngIf="
                        historicalData.length != 0 &&
                        fromTVD &&
                        !addEntryFromTVD
                      "
                      class="noMoreDataMSG"
                    >
                      <div
                        [ngClass]="historicalData.length != 0 ? 'col-12' : ''"
                        *ngIf="historicalData.length != 0"
                      >
                        <label>{{ "Edata.NO_HISTORY_DATA" | translate }}</label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of rows; let i = index">
                    <tr
                      [ngClass]="
                        fromTVD ? 'samplepoint-rowTVD' : 'samplepoint-row'
                      "
                      (click)="
                        sampleRowSelected(
                          row.ContextPointLite.ContextPointId,
                          $event
                        )
                      "
                    >
                      <td
                        *ngFor="let column of headers"
                        [ngClass]="{
                          'fixed-cloumn-parent': column.field === 'samplename',
                          'row-highlight':
                            contextPointIdVal ===
                            row.ContextPointLite.ContextPointId,
                          'samplepoint-rowTVD': fromTVD
                        }"
                      >
                        <div
                          class="child"
                          [ngClass]="
                            fromTVD
                              ? column.field === 'samplename'
                                ? 'first-header1'
                                : 'historicalHeaders'
                              : column.field === 'samplename'
                              ? 'first-header'
                              : 'historicalHeaders'
                          "
                        >
                          <div
                            *ngIf="column.field === 'samplename'"
                            [ngClass]="fromTVD ? 'Expand-TVD' : ''"
                          >
                            <img
                              *ngIf="
                                row.MeasurementInstanceConfigurations &&
                                row.MeasurementInstanceConfigurations.length > 0
                              "
                              [ngClass]="[
                                row.ContextPointLite.expand
                                  ? 'onExpand'
                                  : 'onCollapse'
                              ]"
                              class="expand-img"
                              (click)="expand(rows, row)"
                              src="../../../../assets/images/Arrows_2_icon.svg"
                              alt="row.ContextPointLite.expand? 'Expand':'Collapse'"
                            />
                            <label>
                              <span
                                class="text-content"
                                ngbTooltip="{{
                                  row.ContextPointLite.ParentName
                                    ? row.ContextPointLite.ContextPointName
                                      ? row.ContextPointLite.ParentName +
                                        ' | ' +
                                        row.ContextPointLite.ContextPointName
                                      : row.ContextPointLite.ParentName
                                    : row.ContextPointLite.ContextPointName
                                }}"
                                container="body"
                              >
                                <span
                                  *ngIf="
                                    row.ContextPointLite.ParentName !== null &&
                                    row.ContextPointLite.ParentName !== ''
                                  "
                                >
                                  {{ row.ContextPointLite.ParentName }} </span
                                ><span
                                  *ngIf="row.ContextPointLite.ContextPointName"
                                  >|
                                  {{
                                    row.ContextPointLite.ContextPointName
                                  }}</span
                                >
                              </span>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <ng-container
                      *ngIf="
                        row.ContextPointLite.expand &&
                        row.MeasurementInstanceConfigurations &&
                        row.MeasurementInstanceConfigurations.length > 0
                      "
                    >
                      <ng-container
                        *ngFor="
                          let category of row.MeasurementInstanceConfigurations;
                          let j = index
                        "
                      >
                        <tr
                          class="historyData-row"
                          (click)="
                            edataRowSelected(
                              row.ContextPointLite.ContextPointId,
                              j,
                              $event
                            )
                          "
                          [ngClass]="{
                            'row-highlight':
                              samplepointIdVal ===
                                row.ContextPointLite.ContextPointId &&
                              measurementIndexVal == j
                          }"
                        >
                          <td
                            *ngFor="let column of headers"
                            [ngClass]="
                              fixedColumn(column) +
                              ' ' +
                              (fromTVD
                                ? bluecolumn(column)
                                : greenColumn(column)) +
                              ' ' +
                              rowHighlight(row, j) +
                              ' ' +
                              limitCheck(category, column)
                            "
                          >
                            <div
                              *ngIf="column.field === 'samplename'"
                              [ngClass]="
                                fromTVD
                                  ? column.field === 'samplename'
                                    ? 'first-header1'
                                    : 'historicalHeaders'
                                  : column.field === 'samplename'
                                  ? 'first-header'
                                  : 'historicalHeaders'
                              "
                              class="row"
                            >
                              <div class="col-md-8 col-sm-8">
                                <label class="text-content sample-name">
                                  <span
                                    ngbTooltip="{{
                                      category?.EnvisionSamplePointName &&
                                      category?.EnvisionSamplePointName != ''
                                        ? category?.EnvisionSamplePointName
                                        : row.ContextPointLite.ParentName
                                        ? row.ContextPointLite.ContextPointName
                                          ? row.ContextPointLite.ParentName +
                                            ' | ' +
                                            row.ContextPointLite
                                              .ContextPointName
                                          : row.ContextPointLite.ParentName
                                        : row.ContextPointLite.ContextPointName
                                    }}"
                                    container="body"
                                  >
                                    <span
                                      *ngIf="
                                        category?.EnvisionSamplePointName &&
                                        category.EnvisionSamplePointName != ''
                                      "
                                    >
                                      {{
                                        category.EnvisionSamplePointName
                                      }}</span
                                    >
                                    <span
                                      *ngIf="
                                        category.EnvisionSamplePointName ==
                                          null ||
                                        category.EnvisionSamplePointName == ''
                                      "
                                    >
                                      <span
                                        *ngIf="
                                          row.ContextPointLite?.ParentName &&
                                          row.ContextPointLite?.ParentName != ''
                                        "
                                      >
                                        {{
                                          row.ContextPointLite.ParentName
                                        }} </span
                                      ><span
                                        *ngIf="
                                          row.ContextPointLite.ContextPointName
                                        "
                                        >|
                                        {{
                                          row.ContextPointLite.ContextPointName
                                        }}</span
                                      >
                                    </span></span
                                  ></label
                                >
                                <label class="text-content parameter-name">
                                  <span
                                    [ngbTooltip]="tipContent"
                                    container="body"
                                  >
                                    <ng-container
                                      *ngTemplateOutlet="tipContent"
                                    ></ng-container>
                                  </span>
                                </label>
                                <ng-template #tipContent>
                                  <span
                                    *ngIf="
                                      category?.MeasurementInstanceLite
                                        ?.DisplayName &&
                                      category?.MeasurementInstanceLite
                                        ?.DisplayName != ''
                                    "
                                  >
                                    {{
                                      category?.MeasurementInstanceLite
                                        ?.DisplayName
                                    }}</span
                                  >
                                  <span
                                    *ngIf="
                                      category?.MeasurementInstanceLite
                                        ?.StuiName &&
                                      category?.MeasurementInstanceLite
                                        ?.StuiName !== '' &&
                                      category?.MeasurementInstanceLite
                                        ?.StuiName !== 'N/A'
                                    "
                                  >
                                    |
                                    {{
                                      category?.MeasurementInstanceLite
                                        ?.StuiName
                                    }}</span
                                  >
                                  <span
                                    *ngIf="
                                      category?.MeasurementInstanceLite
                                        ?.MeasurementSource &&
                                      category?.MeasurementInstanceLite
                                        ?.MeasurementSource !== 'Default'
                                    "
                                  >
                                    |
                                    {{
                                      category?.MeasurementInstanceLite
                                        ?.MeasurementSource
                                    }}</span
                                  >
                                </ng-template>
                              </div>
                              <div class="col-md-2 col-sm-4 icon-row">
                                <div
                                  class="col-sm-6 col-6 p-0 pt-1 chart-icon"
                                  [ngbTooltip]="
                                    'Edata.PLOT_A_CHART' | translate
                                  "
                                  *ngIf="plotChartIcon"
                                >
                                  <img
                                    src="../../../../assets/images/ProductProductivityPlotChart.svg"
                                    alt="plot-chart"
                                    (click)="navigateDataViz(category)"
                                    class="icon-img"
                                  />
                                </div>
                                <div
                                  class="col-sm-6 col-6 p-0 pt-1 calc-icon"
                                  style="margin-left: -6px"
                                >
                                  <img
                                    src="../../../../assets/images/Icon-calculator-blue.svg"
                                    alt="calculated"
                                    [ngbTooltip]="
                                      'Edata.CALCULATION_DETAILS' | translate
                                    "
                                    *ngIf="
                                      category.MeasurementInstanceLite
                                        .OriginType ==
                                      rapidBio.CAL_MEASUREMENT_ORIGIN_TYPE
                                    "
                                    (click)="showPopup(category, row)"
                                    class="calc-icon-img"
                                  />
                                </div>
                              </div>
                              <div class="col-md-2 col-sm-12 limits-label">
                                <div>
                                  <label
                                    class="ml-0 limit-content"
                                    ngbTooltip="{{ 'No Limits' }}"
                                    container="body"
                                    *ngIf="
                                      !category.MeasurementLimit ||
                                      category.MeasurementLimit.limit_status ==
                                        limitsStatus.NO_LIMIT
                                    "
                                    >No Limits</label
                                  >
                                  <label
                                    class="ml-0 limit-content"
                                    *ngIf="
                                      category.MeasurementLimit &&
                                      category.MeasurementLimit.limit_status ==
                                        limitsStatus.AVAILABLE
                                    "
                                    ngbTooltip="{{
                                      '(' +
                                        category.MeasurementLimit.LowerLimit +
                                        ' - ' +
                                        category.MeasurementLimit.HigherLimit +
                                        ')'
                                    }}"
                                    container="body"
                                  >
                                    ({{ category.MeasurementLimit.LowerLimit }}
                                    -
                                    {{ category.MeasurementLimit.HigherLimit }})
                                  </label>
                                  <label
                                    class="ml-0 limit-content"
                                    *ngIf="
                                      category.MeasurementLimit &&
                                      category.MeasurementLimit.limit_status ==
                                        limitsStatus.MIN
                                    "
                                    ngbTooltip="{{
                                      '(' +
                                        category.MeasurementLimit.LowerLimit +
                                        ' Min)'
                                    }}"
                                    container="body"
                                  >
                                    ({{ category.MeasurementLimit.LowerLimit }}
                                    Min)
                                  </label>
                                  <label
                                    class="ml-0 limit-content"
                                    *ngIf="
                                      category.MeasurementLimit &&
                                      category.MeasurementLimit.limit_status ==
                                        limitsStatus.MAX
                                    "
                                    ngbTooltip="{{
                                      '(' +
                                        category.MeasurementLimit.HigherLimit +
                                        ' Max)'
                                    }}"
                                    container="body"
                                  >
                                    ({{ category.MeasurementLimit.HigherLimit }}
                                    Max)
                                  </label>
                                  <label
                                    class="uom-content d-block"
                                    *ngIf="
                                      category.DataCaptureDimensionalSubUnit
                                    "
                                    #element1
                                    ngbTooltip="{{
                                      element1.offsetWidth <
                                      element1.scrollWidth
                                        ? category.DataCaptureDimensionalSubUnit
                                            .Symbol
                                        : ''
                                    }}"
                                    container="body"
                                  >
                                    ({{
                                      category.DataCaptureDimensionalSubUnit
                                        .Symbol
                                        ? category.DataCaptureDimensionalSubUnit
                                            .Symbol
                                        : "n/a"
                                    }})
                                  </label>
                                  <label
                                    class="w-100 ml-6"
                                    *ngIf="
                                      !category.DataCaptureDimensionalSubUnit
                                    "
                                    >(n/a)</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div *ngIf="column.field !== 'samplename'">
                              <div
                                *ngIf="column.userStatus == 'old'"
                                [ngClass]="
                                  fromTVD
                                    ? column.field === 'samplename'
                                      ? 'first-header1'
                                      : 'historicalHeaders'
                                    : column.field === 'samplename'
                                    ? 'first-header'
                                    : 'historicalHeaders'
                                "
                              >
                                <span
                                  [ngClass]="
                                    category.MeasurementInstanceLite.Measurement
                                      .OptionGroupId != null &&
                                    category.MeasurementOptionDetails.length !=
                                      0
                                      ? 'valueTextGeneration'
                                      : 'valueGeneration'
                                  "
                                  [ngbTooltip]="
                                    category.MeasurementInstanceLite.Measurement
                                      .OptionGroupId != null &&
                                    category.MeasurementOptionDetails.length !=
                                      0
                                      ? valueGeneration(category, column)
                                      : ''
                                  "
                                  container="body"
                                >
                                  {{ valueGeneration(category, column) }}
                                </span>
                                <span
                                  class="commentPointer"
                                  *ngIf="commentPresent(category, column)"
                                  (click)="
                                    commentDataFetch(
                                      category,
                                      column,
                                      row.ContextPointLite,
                                      category.MeasurementInstanceLite,
                                      category.MeasurementLimit,
                                      category?.DataCaptureDimensionalSubUnit
                                        ?.Symbol
                                    )
                                  "
                                >
                                  <img
                                    src="../../../../assets/images/Message.svg"
                                  />
                                </span>
                              </div>
                              <div
                                *ngIf="
                                  column.userStatus == 'new' ||
                                  column.userStatus === 'edit'
                                "
                              >
                                <input
                                  *ngIf="
                                    category.MeasurementInstanceLite.Measurement
                                      .OptionGroupId == null &&
                                    category.MeasurementOptionDetails.length ==
                                      0
                                  "
                                  [readonly]="checkReadOnly(category, column)"
                                  #valueItem
                                  type="text"
                                  maxlength="12"
                                  [ngModel]="
                                    category.isInfinity
                                      ? 'Error'
                                      : valueGeneration(category, column)
                                  "
                                  (keypress)="keyPress($event, category)"
                                  (keyup)="keyUp(category)"
                                  (change)="
                                    valueChange(
                                      $event,
                                      valueItem.value,
                                      column,
                                      category,
                                      row.ContextPointLite.ContextPointId,
                                      false
                                    )
                                  "
                                  [name]="
                                    'input' +
                                    category.MeasurementInstanceLite
                                      .MeasurementInstanceId
                                  "
                                  [id]="
                                    'input' +
                                    category.MeasurementInstanceLite
                                      .MeasurementInstanceId
                                  "
                                  [placeholder]="fromTVD ? 'Enter Value' : ''"
                                  class="add-entry"
                                  [ngClass]="{
                                    'placeholder-Text-Color': fromTVD,
                                    cellDisable:
                                      (category.MeasurementInstanceLite
                                        .Measurement.MeasurementId ==
                                        rapidBio.MEASURE_ID &&
                                        category.MeasurementInstanceLite
                                          .SourceId == rapidBio.SOURCE_ID) ||
                                      category.MeasurementInstanceLite
                                        .OriginType ==
                                        rapidBio.CAL_MEASUREMENT_ORIGIN_TYPE,
                                    infinity_input_field: category.isInfinity
                                  }"
                                />
                                <span class="selectMeasurementDropdown">
                                  <select
                                    (change)="
                                      measurementOptionSelected(
                                        $event,
                                        category.MeasurementOptionDetails,
                                        column,
                                        category,
                                        row.ContextPointLite.ContextPointId
                                      )
                                    "
                                    *ngIf="
                                      category.MeasurementInstanceLite
                                        .Measurement.OptionGroupId != null &&
                                      category.MeasurementOptionDetails
                                        .length != 0
                                    "
                                    class="form-control"
                                    [ngModel]="patchValue(category, column)"
                                    ngbTooltip="{{
                                      valueGeneration(category, column)
                                    }}"
                                    container="body"
                                    [name]="
                                      'input' +
                                      category.MeasurementInstanceLite
                                        .MeasurementInstanceId
                                    "
                                    [id]="
                                      'input' +
                                      category.MeasurementInstanceLite
                                        .MeasurementInstanceId
                                    "
                                  >
                                    <option value="" translate>
                                      COMMON.SELECT
                                    </option>
                                    <option
                                      *ngFor="
                                        let val of category.MeasurementOptionDetails
                                      "
                                      [value]="val.OptionId"
                                    >
                                      {{ val.OptionName }}
                                    </option>
                                  </select>
                                </span>
                                <span class="add-entry-icons">
                                  <!-- Image to show green tick while save Measurement Readings in add/edit mode. -->
                                  <img
                                    src="../../../../assets/images/checkmark-green-icon.svg"
                                    class="save-img-size"
                                    *ngIf="
                                      category.MeasurementInstanceLite.state ==
                                      3
                                    "
                                    alt="calculated"
                                  />
                                  <!-- Image to show loader and failure icon while save Measurement Readings in add/edit mode. -->
                                  <span
                                    class="c-three-dots-loader"
                                    *ngIf="
                                      category.MeasurementInstanceLite.state ==
                                      2
                                    "
                                  ></span>
                                  <img
                                    src="../../../../assets/images/alert-yellow.svg"
                                    class="save-img-size"
                                    *ngIf="
                                      category.MeasurementInstanceLite.state ==
                                      4
                                    "
                                    alt="Failed"
                                  />
                                  <!-- Image to add Measurement Level Comments in add/edit mode. -->
                                  <img
                                    *ngIf="
                                      (column.userStatus === 'new' &&
                                        !category.measurementComments) ||
                                      (column.userStatus === 'edit' &&
                                        !commentPresent(category, column))
                                    "
                                    [ngClass]="
                                      category.MeasurementInstanceLite.state ==
                                      2
                                        ? 'addMeasurementComment'
                                        : 'alignCommentIcon'
                                    "
                                    src="../../../../assets/images/Comment-Icon.svg"
                                    (click)="
                                      openMeasurementComment(
                                        row.ContextPointLite,
                                        category.MeasurementInstanceLite,
                                        category.MeasurementLimit,
                                        category,
                                        column,
                                        'ADD',
                                        category?.DataCaptureDimensionalSubUnit
                                          ?.Symbol
                                      )
                                    "
                                  />
                                  <!-- Image for already added Measurement Level Comments in add/edit mode. -->
                                  <img
                                    *ngIf="
                                      (category.measurementComments &&
                                        category.measurementComments !=
                                          'failed') ||
                                      (column.userStatus === 'edit' &&
                                        commentPresent(category, column))
                                    "
                                    [ngClass]="
                                      category.MeasurementInstanceLite.state ==
                                      2
                                        ? 'addMeasurementComment'
                                        : 'alignCommentIcon'
                                    "
                                    src="../../../../assets/images/Message_Filled.svg"
                                    (click)="
                                      column.userStatus == 'edit'
                                        ? commentDataFetch(
                                            category,
                                            column,
                                            row.ContextPointLite,
                                            category.MeasurementInstanceLite,
                                            category.MeasurementLimit,
                                            category
                                              ?.DataCaptureDimensionalSubUnit
                                              ?.Symbol
                                          )
                                        : openMeasurementComment(
                                            row.ContextPointLite,
                                            category.MeasurementInstanceLite,
                                            category.MeasurementLimit,
                                            category,
                                            column,
                                            'EDIT',
                                            category
                                              ?.DataCaptureDimensionalSubUnit
                                              ?.Symbol
                                          )
                                    "
                                  />
                                  <!-- Image for failed icon if Measurement Level Comments fails to add in add/edit mode. -->
                                  <img
                                    *ngIf="
                                      category.measurementComments == 'failed'
                                    "
                                    [hidden]="
                                      category.MeasurementInstanceLite.state ==
                                      2
                                    "
                                    [ngClass]="
                                      category.MeasurementInstanceLite.state ==
                                      2
                                        ? 'addMeasurementComment'
                                        : 'alignCommentIcon'
                                    "
                                    src="../../../../assets/images/Message_error.svg"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <div
                    *ngIf="disableBtn == false && loadMoreHistoryDataBtn"
                    class="more_button"
                    (click)="loadMoreHistoryDataEntry()"
                  >
                    <img
                      class="more_button_icon"
                      src="../../../../assets/images/Arrows_4_icon.svg"
                    />
                    <span class="more_button_text">{{
                      "Edata.LOAD_MORE_DATA_SET" | translate
                    }}</span>
                  </div>
                  <ng-container *ngIf="rows.length == 0">
                    <tr class="emptyData">
                      <div class="no-result-column">
                        <label>{{ "Edata.NO_RESULT_FOUND" | translate }}</label>
                      </div>
                    </tr>
                  </ng-container>
                </tbody>
              </div>
            </table>
          </div>
        </div>
        <div *ngIf="dateLoader" class="range-content">
          <span class="loader"></span>
        </div>
      </div>
    </div>

    <div tile-content class="state-error-message error-msg-tile" *ngIf="isData">
      <h3 class="defaultMsg align-self-center p-2">
        <span *ngIf="siteCpID == '' || subConfigId == ''">{{
          "Edata.NO_SITE_CONFIG_MESSAGE" | translate
        }}</span>
      </h3>
      <h3 class="defaultMsg align-self-center p-2">
        <span
          *ngIf="
            siteCpID !== '' &&
            subConfigId !== '' &&
            (rows.length == 0 || rows == null)
          "
          >{{ "Edata.NO_SAMPLE_DATA" | translate }}</span
        >
      </h3>
    </div>
  </nalco-tile>
</div>
<!-- </div> -->

<!-- Modal pop up -->
<ng-template #content let-modal>
  <div class="entryExist">
    <div class="modal-header">
      <h4 class="modal-title">{{ "Edata.DATE_EXIST_HEADER" | translate }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span class="ico-exit-round-edge"></span>
      </button>
    </div>
    <div class="modal-body">
      <label>
        {{ "Edata.DATE_EXIST_CONTENT" | translate }}
      </label>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="closeModal()" class="btn btn-primary">
        {{ "COMMON.CLOSE" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Comment Modal pop up -->
<ng-template #commentPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ "Edata.GENERAL_COMMENT_HEADING" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeCommentModal(form)"
    >
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <label class="pl-4 pb-1 pt-2">
    {{ "Edata.GENERAL_COMMENT_TITLE" | translate }}
  </label>
  <form *ngIf="form" [formGroup]="form" (submit)="submitForm(form)">
    <div class="modal-body">
      <div class="commentInput">
        <div
          *ngFor="let keyvalue of form.controls | keyvalue; let i = index"
          [ngClass]="i != 0 ? 'mt-3' : ''"
        >
          <label>{{ SamplePointComment[i].display }}</label>
          <label>{{ "Edata.MAX_LIMIT" | translate }}</label>
          <div>
            <textarea
              type="text"
              class="w-100"
              placeholder="Add Comment"
              (input)="commentValue($event)"
              [formControl]="getControl(SamplePointComment[i].id)"
              maxlength="3000"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        (click)="closeCommentModal(form)"
        class="btn btn-primary"
      >
        {{ "COMMON.CANCEL" | translate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="isButtonDisabled"
      >
        {{ "COMMON.OK" | translate }}
      </button>
    </div>
  </form>
  <div *ngIf="commentLoader" class="commentLoaderWrapper">
    <span class="loader"></span>
  </div>
</ng-template>

<!-- Comment success pop up -->
<ng-template #successCommentPopup let-modal>
  <div class="modal-body text-center">
    <img src="../../../../assets/images/wfi-validate-tick.svg" alt="Save" />
    <div>
      <label>{{ "Edata.COMMENT_SAVED" | translate }}</label>
      <div>
        <button
          class="btn btn-primary w-25"
          (click)="closeCommentSuccessModal()"
        >
          {{ "COMMON.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Complete Entry saved pop up -->
<ng-template #successNewEntryPopup let-modal>
  <div class="modal-body text-center">
    <img src="../../../../assets/images/wfi-validate-tick.svg" alt="Save" />
    <div>
      <label *ngIf="!onEditMode && !deletedDataset">{{
        "Edata.SAVED_NEW_ENTRY" | translate
      }}</label>
      <label *ngIf="onEditMode && !deletedDataset">{{
        "Edata.SAVED_DATA_SUCCESS" | translate
      }}</label>
      <label *ngIf="deletedDataset">{{
        "Edata.DELETE_SUCCESS_MSG" | translate
      }}</label>
      <div>
        <button class="btn btn-primary w-25" (click)="closeSavedEntryModal()">
          {{ "COMMON.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Confirmation popup -->
<ng-template #confirmPopup let-modal>
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span><img src="../../../../assets/images/alert-yellow.svg" /></span>
      <span>{{ "Edata.ATTENTION" | translate }}</span>
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="exitUpdatedComments()"
    >
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <p class="confirm_msg mb-0">{{ "Edata.CONFIRM_MESSAGE" | translate }}</p>
  <div class="modal-footer mt-0">
    <button
      type="button"
      (click)="exitUpdatedComments()"
      class="btn btn-primary"
    >
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button type="button" (click)="closeConfirmModal()" class="btn btn-primary">
      {{ "COMMON.OK" | translate }}
    </button>
  </div>
</ng-template>
