import {  Injectable  } from '@angular/core';  
import {  ApplicationInsights,IExceptionTelemetry, DistributedTracingModes } from '@microsoft/applicationinsights-web';  
import {  Router,  NavigationEnd,NavigationStart  } from '@angular/router';  
import {  filter } from 'rxjs/operators';  
import { AppEnvConfig } from '../../app.env.config';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
@Injectable({  
    providedIn: 'root',  
})   
export class ApplicationInsightsService {  
    private appInsights: ApplicationInsights;  
    
    constructor(private router: Router,private config: AppEnvConfig,private localCacheService: LocalCacheService) {  
        let appInsightKey = config.getEnv('appInsights');
        this.appInsights = new ApplicationInsights({  
            config: {  
                instrumentationKey: appInsightKey.instrumentationKey,
                enableAutoRouteTracking: false,
                autoTrackPageVisitTime: true,
                enableAjaxErrorStatusText: true,
            }  
        });  
        this.appInsights.loadAppInsights(); 
        this.appInsights.setAuthenticatedUserContext(this.localCacheService.fetchData('username'),this.localCacheService.fetchData('username'));
        this.loadCustomTelemetryProperties();  
        this.createRouterSubscription();  
    }  
    setUserId(userId: string) {  
        this.appInsights.setAuthenticatedUserContext(userId);  
    }  
    clearUserId() {  
        this.appInsights.clearAuthenticatedUserContext();  
    }  
    logPageView(name ? : string, uri ? : string) {  
        this.appInsights.trackPageView({  
            name,  
            uri  
        });  
    }  
    logException(error: Error) {  
        let exception: IExceptionTelemetry = {  
            exception: error
        };  
        this.appInsights.trackException(exception);  
    }  
    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name}, properties);
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message}, properties);
    } 

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logStartTrackPage(name: string){
        this.appInsights.startTrackPage(name);
    }
    logStopTrackPage(name: string){
        this.appInsights.stopTrackPage(name);
    }
    private loadCustomTelemetryProperties() {  
        this.appInsights.addTelemetryInitializer(envelope => {  
            var item = envelope.baseData;
            item.properties = item.properties || {}; 
            item.properties["User"] = this.localCacheService.fetchData('username');   
            item.properties["ApplicationPlatform"] = "Web";  
            item.properties["ApplicationName"] = "E3D WEB APPLICATION";  
        });  
    }  
    
    private createRouterSubscription() { 
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.appInsights.startTrackEvent(event.url);
            } else if (event instanceof NavigationEnd) {
                this.appInsights.stopTrackEvent(event.url);
                this.logPageView();
            }
        });
        // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {  
        //     this.logPageView(null, event.urlAfterRedirects);  
        // });  
    }  
}  