import { Component, OnInit, Input } from '@angular/core';
import { ErrorhandlingPopupComponent } from '../../shared/components/error-handling/errorhandling-popup/errorhandling-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 //import { toasts } from 'angular2-toaster';
 import { Toast, ToastrService, ToastPackage, IndividualConfig} from 'ngx-toastr';
 import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { DGSNSupport } from '../../../library/sharedDVLibrary/angular-shared-component/projects/angular-v13/src/lib/shared/enum/constants';
@Component({
  selector: 'nalco-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ErrorHandlerComponent extends Toast {

  supportEmailId = DGSNSupport.Email;
  modalRef: any;
  errorData: any;
  // public toast: Toast;

  constructor(private modalService: NgbModal,public toastrService: ToastrService,
    public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  errorPopUp(event) {

     this.errorData =  this.toastrService.toasts[0];
    let status = 0;
    if(this.errorData.message.status){
      status = this.errorData.message.status;
    }    
    let errorMessage = '';
    let guid = '';
    if (status !== 0) {
      const error = this.errorData.message.error;
      if (!error || !error.Message) {
          errorMessage = error;
      } else {
          errorMessage = error.Message;
          guid = error.MessageDetail;
          errorMessage = '{' + guid + '} ' + errorMessage;
      }
    } else {
      errorMessage = this.errorData.message.message;
    }
   // errorMessage = this.errorData.message.message;

    this.modalRef = this.modalService.open(ErrorhandlingPopupComponent, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'saveExit-modal'
    }).componentInstance;
    this.modalRef.errorMessage = errorMessage;
}

action(btn) {
  event.stopPropagation();
  this.toastPackage.triggerAction(btn);
  this.toastrService.clear();
  return false;
}

}
