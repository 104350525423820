import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  
  MsalService,
  MsalModule,
  MsalInterceptor,
  MsalGuard,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppEnvConfig } from '../../app.env.config';
// import { Logger } from 'msal';
import { AppConfigService } from '../services/app-config.service';
import { IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

// protected resource map factory function
export const protectedResourceMap = new Map<string, Array<string>>();
export const protectedResourceMapMethod = (config:AppEnvConfig) =>{
  const end =  Object.keys(config.getEnv('msalConfig').endpoints);
  const endv =  Object.values(config.getEnv('msalConfig').endpoints);
   end.map((v:string) =>{
   protectedResourceMap.set(v.replace('https://','/')+'/', ['openid',`${config.getEnv('msalConfig').clientId}`]);
   endv.map((k:string) =>{
    protectedResourceMap.set(k.replace('https://','/')+'/', ['openid', `${config.getEnv('msalConfig').clientId}`]);
   });
  });
 }   


// to detect browser type is IE
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

  // config factory function
export function loadConfig(config: AppEnvConfig, appConfigService: AppConfigService) {
  return () => config.load().then(()=>{
    appConfigService.setConfig(config);
  });
}
export function MSALInstanceFactory(config: AppEnvConfig): IPublicClientApplication {
  let b2cPolicies;
  const IsB2CEnable = config.getEnv('IsB2CEnable').toString();
  b2cPolicies = {
    names: {
      signUpSignIn: config.getEnv('signUpSignInPolicy'),
    },
    authorities: {
      signUpSignIn: {
        authority: config.getEnv('authority'),
      },
    },
    authorityDomain: config.getEnv('authorityDomain'),
  };
  return new PublicClientApplication({
    auth: {
      clientId: config.getEnv('msalConfig').clientId,
      authority:
        IsB2CEnable === 'true'
          ? b2cPolicies.authorities.signUpSignIn.authority
          : config.getEnv('authorityUrl') +
            config.getEnv('msalConfig').tenant +
            '/',
      knownAuthorities:
        IsB2CEnable === 'true' ? [b2cPolicies.authorityDomain] : [],
      // validateAuthority: true,
      navigateToLoginRequestUrl: true,
      // postLogoutRedirectUri: window.location.origin + '/assets/logout_redirect/logout.html'
    },
    cache: {
      cacheLocation: config.getEnv('msalConfig').cacheLocation, // This configures where your cache will be stored
      storeAuthStateInCookie: isIE, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions:{
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
      ): void => {
          if (containsPii) {
              return;
          }
          switch (level) {
              case LogLevel.Error:
                  console.error(message);
                  return;
              case LogLevel.Info:
                  console.info(message);
                  return;
              case LogLevel.Verbose:
                  console.debug(message);
                  return;
              case LogLevel.Warning:
                  console.warn(message);
                  return;
          }
      },
      piiLoggingEnabled: false,
  },

    }
  });
}

// MSAL logger function
// export const logCallBack = (level: LogLevel,message: string, containsPii: boolean):void => {
//   if (containsPii) {
//     return;
//   }
//   switch (level) {
//     case LogLevel.Error:
//       console.error(message);
//       return;
//     case LogLevel.Info:
//       console.log(message);
//       return;
//     case LogLevel.Verbose:
//       console.log(message);
//       return;
//     case LogLevel.Warning:
//       console.warn(message);
//       return;
//   }
// };

// MSAL Angular config factory function
export function MSALInterceptorConfigFactory(
  config: AppEnvConfig
): MsalInterceptorConfiguration {
  // const endpoints = config.getEnv('msalConfig').endpoints;
  // const selectedEndpoint =
  //   endpoints[config.getEnv('apiServerUrl').slice(0, -1)] +
  //   '/user_impersonation';
  // const IsB2CEnable = config.getEnv('IsB2CEnable').toString();
  protectedResourceMapMethod(config);
  // protectedResourceMap.set(`${config.getEnv('apiServerUrl')}Users/Token`, ['openid']);
  // protectedResourceMap.set(`${config.getEnv('apiServerUrl')}DagTypeSubTypes/All/4000/1`, ['openid']);
  // protectedResourceMap.set(`${config.getEnv('apiServerUrl')}Users/UserSiteAccess`, ['openid']);
  
  return{
    // unprotectedResources: config.getEnv('unprotectedResources'),
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap,
    
  };
}

export function MSALGuardConfigFactory(config: AppEnvConfig): MsalGuardConfiguration {
  const IsB2CEnable = config.getEnv('IsB2CEnable').toString();
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: IsB2CEnable === 'true' ? ['openid'] : ['user.read'],
    },
    loginFailedRoute: '/'
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule],
})
export class MsalApplicationModule {
  static forRoot() {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        HttpClient,
        AppEnvConfig,
        {
          provide: APP_INITIALIZER,
          useFactory: loadConfig,
          deps: [AppEnvConfig, AppConfigService],
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AppEnvConfig],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [AppEnvConfig],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AppEnvConfig],
        },
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
      ],
    };
  }
}
