export const USER_STATUS = {
    OLD: "old",
    NEW: "new",
    EDIT: "edit"
  }

export const RAPID_BIO = {
  MEASURE_ID : 59,
  SOURCE_ID: 5,
  CAL_MEASUREMENT_ORIGIN_TYPE: 3,
  READ_ONLY: 0,
  SCIENTIFIC_NOTATION: 6
}

export const DATE_FORMAT = {
  DATETIMEFORMAT: 'YYYY-MM-DDTHH:mm:ss',
  MAX_THRESHOLD_DATE: 2 // THRESHOLD MAX 2 Years
}

export const MODALS = {
  MEASUREMENT_COMMENTS: 'measurement-comments',
  EDIT_COMMENTS: 'edit_comments',
  RETRY_SAVE_READINGS: 'retry-save-readings',
  CALCULATED_MSR_POPUP: 'calculated-msr-popup',
  SITE_CHANGE_CONFIRMATION: 'site-change-confirmation',
  SUBCONFIG_CHANGE_CONFIRMATION: 'subconfigration-change-confirmation',
  DELETE_DATASET_CONFIRMATION: 'delete-dataset-confirmation'
}

export const LIMIT_STATUS = {
  AVAILABLE: 0,
  NO_LIMIT: 1,
  MIN: 2,
  MAX: 3
}

export const PAGE_SIZE = {
  DEFAULT_PAGE_SIZE: 15
}

export const ROUTING_PAGE = {
  ASSET_HARVESTER: '/assetharvester/',
  MANAGE_MEASUREMENTS: '/manageMeasurements',
  E_DATA_TOUR_CONFIG: 'edataTourConfig'
}

export const USER_TYPE={
  USER_TYPE_CUSTOMER : 2
}
export const MAX_FILE_SIZE ={
  FILE_SIZE :10485760
}
export const starttimeData = [
  {
    text: 'COMMON.12_AM',
    value: '12 AM',
    isSelected: false
  },
  {
    text: 'COMMON.01_AM',
    value: '01 AM',
    isSelected: false
  },
  {
    text: 'COMMON.02_AM',
    value: '02 AM',
    isSelected: false
  },
  {
    text: 'COMMON.03_AM',
    value: '03 AM',
    isSelected: false
  },
  {
    text: 'COMMON.04_AM',
    value: '04 AM',
    isSelected: false
  },
  {
    text: 'COMMON.05_AM',
    value: '05 AM',
    isSelected: false
  },
  {
    text: 'COMMON.06_AM',
    value: '06 AM',
    isSelected: false
  },
  {
    text: 'COMMON.07_AM',
    value: '07 AM',
    isSelected: false
  },
  {
    text: 'COMMON.08_AM',
    value: '08 AM',
    isSelected: false
  },
  {
    text: 'COMMON.09_AM',
    value: '09 AM',
    isSelected: false
  },
  {
    text: 'COMMON.10_AM',
    value: '10 AM',
    isSelected: false
  },
  {
    text: 'COMMON.11_AM',
    value: '11 AM',
    isSelected: false
  },
  {
    text: 'COMMON.12_PM',
    value: '12 PM',
    isSelected: false
  },
  {
    text: 'COMMON.01_PM',
    value: '01 PM',
    isSelected: false
  },
  {
    text: 'COMMON.02_PM',
    value: '02 PM',
    isSelected: false
  },
  {
    text: 'COMMON.03_PM',
    value: '03 PM',
    isSelected: false
  },
  {
    text: 'COMMON.04_PM',
    value: '04 PM',
    isSelected: false
  },
  {
    text: 'COMMON.05_PM',
    value: '05 PM',
    isSelected: false
  },
  {
    text: 'COMMON.06_PM',
    value: '06 PM',
    isSelected: false
  },
  {
    text: 'COMMON.07_PM',
    value: '07 PM',
    isSelected: false
  },
  {
    text: 'COMMON.08_PM',
    value: '08 PM',
    isSelected: false
  },
  {
    text: 'COMMON.09_PM',
    value: '09 PM',
    isSelected: false
  },
  {
    text: 'COMMON.10_PM',
    value: '10 PM',
    isSelected: false
  },
  {
    text: 'COMMON.11_PM',
    value: '11 PM',
    isSelected: false
  },
];

export const endtimeData = [
  {
    text: 'COMMON.12_AM',
    value: '12:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.01_AM',
    value: '01:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.02_AM',
    value: '02:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.03_AM',
    value: '03:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.04_AM',
    value: '04:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.05_AM',
    value: '05:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.06_AM',
    value: '06:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.07_AM',
    value: '07:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.08_AM',
    value: '08:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.09_AM',
    value: '09:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.10_AM',
    value: '10:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.11_AM',
    value: '11:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.12_PM',
    value: '12:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.01_PM',
    value: '01:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.02_PM',
    value: '02:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.03_PM',
    value: '03:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.04_PM',
    value: '04:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.05_PM',
    value: '05:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.06_PM',
    value: '06:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.07_PM',
    value: '07:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.08_PM',
    value: '08:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.09_PM',
    value: '09:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.10_PM',
    value: '10:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.11_PM',
    value: '11:59 PM',
    isSelected: false
  },
];