export const Response = {

  'leftNavigation': [
    // {
    //   'name': 'Navigation - Main - My Territory',
    //   'link': '/my-territory',
    //   'labelMobile': 'LEFT_NAV.MYTERRITORY_NAV_LABEL_MOBILE',
    //   'label': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
    //   'hover': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
    //   'breadcrumb': 'My Territory',
    //   'text': 'LEFT_NAV.MYTERRITORY_NAV_LABEL',
    //   'icon': 'my-territory-icon',
    //   'subNavigation': [],
    //   'role': ['1']
    // },
    {
      'name': 'Navigation - Main - Dashboards',
      'link': '/sites',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Dashboards',
      'icon': 'icon-icon_dashboard',
      'text': 'LEFT_NAV.MAIN_NAV_LABEL',
      'searchParent': 'Dashboards',
      'flag': 'true',
      'subNavigation': [
        {
          'name': 'Navigation - Main - Service Summary',
          'link': '/service-summary',
          'labelMobile': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
          'hover': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
          'breadcrumb': 'Service Summary',
          'text': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
          'icon': 'icon_Service_Summary',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'SSD',
          'searchName': 'Service Summary'
        },
        {
          'name': 'Navigation - Main - Water Safety',
          'link': '/water-safety',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['2'],
          'shortName': 'WSI',
          'searchName': 'Water Safety Intelligence'
        },
        {
          'name': 'Navigation - Main - Water Quality ',
          'link': '/quality',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['4'],
          'shortName': 'WQ',
          'searchName': 'Water Quality'
        },
        {
          'name': 'Navigation - Main - Asset Details ',
          'link': '/asset-details',
          'labelMobile': 'LEFT_NAV.SUBNAV_ASSET_DETAILS.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ASSET_DETAILS.NAV_LABEL',
          'hover': 'asset-details ',
          'breadcrumb': 'asset-details',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'AD',
          'searchName': 'Asset Details'
        },
        {
          'name': 'Navigation - Main - OMNI',
          'link': '/omni',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': 'OMNI',
          'breadcrumb': 'OMNI',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['5'],
          'shortName': 'OMNI',
          'searchName': 'OMNI'
        },
        {
          'name': 'Navigation - Main - Financial Impact',
          'link': '/financial-impact',
          'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
          'hover': 'Financial Impact',
          'breadcrumb': 'Financial Impact',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['8'],
          'shortName': 'FI',
          'searchName': 'Financial Impact'
        },
        {
          'name': 'Navigation - Main - Data Visualization',
          'link': '/data-visualization',
          'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
          'hover': 'Data Visualization',
          'breadcrumb': 'Data Visualization',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['9'],
          'shortName': 'DV',
          'searchName': 'Data Visualization'
        },
        {
          'name': 'Navigation - Main - Water Flow Intelligence',
          'link': '/water-flow-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['7'],
          'shortName': 'WFI',
          'searchName': 'Water Flow Intelligence'
        },
        {
          'name': 'Navigation - Main - CIP',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL',
          'hover': 'CIP',
          'isExternal': 'true',
          'breadcrumb': 'CIP',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['45'],
          'shortName': 'CIP',
          'searchName': 'CIP'
        },
        {
          'name': 'Navigation - Main - Asset Harvester',
          'link': '/assetharvester',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': 'Asset Harvester',
          'breadcrumb': 'Asset Harvester',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['28', '29'],
          'shortName': 'AH',
          'searchName': 'Asset Harvester'
        },
        {
          'name': 'Navigation - Main - My Documents',
          'link': '/my-documents',
          'labelMobile': 'LEFT_NAV.SUBNAV_MY_DOCUMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_MY_DOCUMENT.NAV_LABEL',
          'hover': 'My Documents',
          'breadcrumb': 'My Documents',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1', '28', '29'],
          'shortName': 'MD',
          'searchName': 'My Documents'
        },
        {
          'name': 'Navigation - Main - Scale Guard Index',
          'link': '/scale-guard-index',
          'labelMobile': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL',
          'hover': 'Scale Guard Index',
          'breadcrumb': 'Scale Guard Index',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['53', '54'],
          'shortName': 'SGI',
          'searchName': 'Scale Guard Index'
        },
        {
          'name': 'Navigation - Main - Water Balance Intelligence',
          'link': '/water-balance-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Balance Intelligence',
          'breadcrumb': 'Water Balance Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['52'],
          'shortName': 'WBI',
          'searchName': 'Water Balance Intelligence'
        },
        {
          'name': 'Navigation - Main - eCommerce',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL',
          'hover': 'eCommerce',
          'isExternal': 'true',
          'breadcrumb': 'eCommerce',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'ECOM',
          'searchName': 'eCommerce'
        },
        {
          'name': 'Navigation - Main - RO Optimizer',
          'link': '/ro-optimizer',
          'labelMobile': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL',
          'hover': 'RO Optimizer',
          'breadcrumb': 'RO Optimizer',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['59'],
          'shortName': 'RO',
          'searchName': 'RO Optimizer'
        },
        {
          'name': 'Navigation - Main - E-data',
          'link': 'Envision_EData_Workaround',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'hover': 'E-data-workaround',
          'breadcrumb': 'E-data-workaround',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'EDATA',
          'searchName': 'Edata'
        },
        {
          'name': 'Navigation - Main - Wash Process Intelligence',
          'link': '',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.WASH_PROCESS_INTELLIGENCE',
          'breadcrumb': 'Wash Process Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['63'],
          'shortName': 'WPI',
          'searchName': 'Wash Process Intelligence'
        },
        {
          'name': 'Navigation - Main - Recycle Intelligence',
          'link': '/recycle-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL',
          'hover': 'Recycle Intelligence',
          'breadcrumb': 'Recycle Intelligence',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_RECYCLE.NAV_LABEL',
          'subNavigation': [],
          'role': ['75'],
          'shortName': 'RI',
          'searchName': 'Recycle Intelligence'
        },
        {
          'name': 'Navigation - Main - FSQS',
          'link': '/fsqs',
          'labelMobile': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL',
          'hover': 'FSQS',
          'breadcrumb': 'FSQS',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL',
          'subNavigation': [],
          'role': ['86']
        },
        {
          'name': 'Navigation - Main - Crude Flex',
          'link': '/crude-flex',
          'labelMobile': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL',
          'hover': 'Crude Flex',
          'breadcrumb': 'Crude Flex',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL',
          'subNavigation': [],
          'role': ['85']
        },
        {
          'name': 'Navigation - Main - Climate Intelligence',
          'link': '/climate-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL',
          'hover': 'Climate Intelligence',
          'breadcrumb': 'Climate Intelligence',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL',
          'subNavigation': [],
          'role': ['78']
        },
        /// introducing connectivity which might change the name and place
        {
          'name': 'Navigation - Main - Connectivity',
          'link': '/connectivity',
          'labelMobile': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL',
          'hover': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL',
          'breadcrumb': 'Connectivity',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_CONNECTIVITY.NAV_LABEL_MOBILE',
          'subNavigation': [],
          'role': ['94']
        },
        {
          'name': 'Navigation - Main - Total Booth Management',
          'link': '/total-booth-management',
          'labelMobile': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL',
          'hover': 'Total Booth Management',
          'breadcrumb': 'Total Booth Management',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL',
          'subNavigation': [],
          'role': ['102']
        },
        {
          'name': 'Navigation - Main - Paper',
          'link': '/paper',
          'labelMobile': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'label': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'hover': 'Paper',
          'breadcrumb': 'Paper',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME',
          'subNavigation': [],
          'role': ['118'] 
        }
      ]
    },
    {
      'name': 'Navigation - Main - Admin & Settings',
      'link': '/settings',
      'labelMobile': 'LEFT_NAV.ADMIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Settings',
      'icon': 'ico-nav-settings2',
      'text': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'searchParent': 'Admin & Settings',
      'flag': 'true',
      'subNavigation': [
        {
          'name': 'Navigation - Main - Admin & Settings - Asset Tree',
          'link': '/asset-tree',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL',
          'hover': 'Asset Tree',
          'breadcrumb': 'Asset Tree',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['3'],
          'shortName': 'AT',
          'searchName': 'Asset Tree'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Water Safety ',
          'link': '/water-safety/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL',
          'hover': 'Water Safety Admin',
          'breadcrumb': 'Water Safety Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['10'],
          'shortName': 'WSA',
          'searchName': 'Water Safety Admin'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Quality ',
          'link': '/quality/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL',
          'hover': 'Water Quality Admin',
          'breadcrumb': 'Water Quality Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['11'],
          'shortName': 'WQA',
          'searchName': 'Water Quality Admin'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - OMNI ',
          'link': '/omni/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL',
          'hover': 'OMNI Admin',
          'breadcrumb': 'OMNI Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['12'],
          'shortName': 'OMNIAD',
          'searchName': 'OMNI Admin'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Climate Intelligence ',
          'link': '/climate-intelligence/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_CLIMATE_INTELLIGENCE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_CLIMATE_INTELLIGENCE.ADMIN_NAV_LABEL',
          'hover': 'Climate-Intelligence Admin',
          'breadcrumb': 'Climate-Intelligence Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['84']
        },
        // {
        //   'name': 'Navigation - Main - Admin & Settings - FSQS ',
        //   'link': '/fsqs/admin',
        //   'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_FSQS.ADMIN_NAV_LABEL_MOBILE',
        //   'label': 'LEFT_NAV.ADMIN_SUBNAV_FSQS.ADMIN_NAV_LABEL',
        //   'hover': 'FSQS Site Admin',
        //   'breadcrumb': 'FSQS Site Admin',
        //   'icon': 'icon-icon_admin',
        //   'subNavigation': [],
        //   'role': ['86']// Need to be changed when we gert Permission ID for ADMIN.
        // },
        {
          'name': 'Navigation - Main - Admin & Settings - Financial Impact',
          'link': '/financial-impact/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_FINANCIAL_IMPACT.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_FINANCIAL_IMPACT.ADMIN_NAV_LABEL',
          'hover': 'Financial Impact Admin',
          'breadcrumb': 'Financial Impact Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['15'],
          'shortName': 'FIA',
          'searchName': 'Financial Impact Admin'
        },
        // 157371 - Disable notification admin  - undo changes of 157371
        {
          'name': 'Navigation - Main - Admin & Settings - Notifications',
          'link': '/notifications-admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL',
          'hover': 'Notifications Admin',
          'breadcrumb': 'Notifications Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['19', '20', '21', '22'],
          'shortName': 'NA',
          'searchName': 'Notifications Admin'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Water Flow Intelligence',
          'link': '/water-flow-intelligence/admin/sensor-config',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL',
          'hover': 'WFI Sensor Mapping',
          'breadcrumb': 'WFI Sensor Mapping',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['27'],
          'shortName': 'WFISM',
          'searchName': 'WFI Sensor Mapping'
        },
        // Controller credential Menu, Plannig for June release.
        {
          'name': 'Navigation - Main - Admin & Settings - Controller Credentials',
          'link': '/controller-credentials',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL',
          'hover': 'NextGen Controller credential',
          'breadcrumb': 'NextGen Controller credential',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'NGCC',
          'searchName': 'NextGen Controller Credential'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - System Assurance Admin',
          'link': '/system-assurance/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL',
          'hover': 'System Assurance Admin',
          'breadcrumb': 'System Assurance Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['55'],
          'shortName': 'SAA',
          'searchName': 'System Assuarance Admin'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Controller Admin',
          'link': '/controller-management',
          'labelMobile': 'CONTROLLER_MANAGEMENT.CONTROLLER_MANAGEMENT',
          'label': 'CONTROLLER_MANAGEMENT.CONTROLLER_MANAGEMENT',
          'hover': 'Controller Management',
          'breadcrumb': 'Controller Management',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['29', '67'],
          'shortName': 'CONTM',
          'searchName': 'Controller Management'
        },
        {
          'name': 'Navigation - Main - Recycle Intelligence Configuration',
          'link': '/recycle-intelligence-configuration',
          'labelMobile': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL',
          'hover': 'Recycle Intelligence Configuration',
          'breadcrumb': 'Recycle Intelligence Configuration',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_RECYCLE_INTELLIGENCE_CONFIGURATION.NAV_LABEL',
          'subNavigation': [],
          'role': ['76']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Logo Management',
          'link': '/logo-management',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_SERVICE_SUMMARY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_SERVICE_SUMMARY.ADMIN_NAV_LABEL',
          'hover': 'Logo Management',
          'breadcrumb': 'Logo Management',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['68'],
          'shortName': 'LM',
          'searchName': 'Logo Management'
        },
        {
          'name': 'Navigation - Main - Credential Management',
          'link': '/credential-management',
          'labelMobile': 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL',
          'hover': 'Credential Management',
          'breadcrumb': 'Credential Management',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['83'],
          'shortName': 'CREDM',
          'searchName': 'Credential Management'
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Ecolab Equipment Assets',
          'link': '/equipment-manager',
          'labelMobile': 'Equipment Manager',
          'label': 'Ecolab Equipment Assets',
          'hover': 'Ecolab Equipment Assets',
          'breadcrumb': 'Ecolab Equipment Assets',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['61']
        },
        /* Commenting as this feature is on hold
        {
          'name': 'Navigation - Main - Admin & Settings - Platform Notifications Admin',
          'link': '/platform-notifications-admin',
          'labelMobile': 'PLATFORM_NOTIFICATIONS.PLATFORM_NOTIFICATIONS_ADMIN',
          'label': 'PLATFORM_NOTIFICATIONS.PLATFORM_NOTIFICATIONS_ADMIN',
          'hover': 'Platform Notifications Admin',
          'breadcrumb': 'Platform Notifications Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['79']
        } 
        */
      ]
    },
    {
      'name': 'Navigation - Main - Action Log',
      'link': '/action-log',
      'labelMobile': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'label': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'hover': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'breadcrumb': 'Actions Log',
      'text': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'icon': 'actionLogIcon',
      'searchParent': 'Action Log',
      'flag': 'true',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Service Summary',
      'link': '/service-summary',
      'labelMobile': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'hover': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'breadcrumb': 'Service Summary',
      'text': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'icon': 'icon_Service_Summary',
      'searchParent': 'Service Summary',
      'flag': 'false',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Training',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'label': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'hover': '',
      'breadcrumb': 'Training',
      'text': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'icon': 'icon-TrainingIcon',
      'searchParent': 'Training',
      'flag': 'true',
      'subNavigation': [
        {
          'name': 'Training Customers',
          'link': 'Training-Customers-External',
          'labelMobile': 'LEFT_NAV.CUSTOMERS',
          'label': 'LEFT_NAV.CUSTOMERS',
          'hover': '',
          'breadcrumb': 'Training Customers',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'CUST',
          'searchName': 'Training Customers'
        },
        {
          'name': 'Training Ecolab Associate',
          'link': 'Training-Ecolab-Associate',
          'labelMobile': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'label': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'hover': ' Ecolab Associate ',
          'breadcrumb': 'Ecolab Associate ',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'EA',
          'searchName': 'Ecolab Associate'
        }
      ]
    },
    // Feedback forums sub navigation links are sorted in ascending order.
    {
      'name': 'Feedback - Uservoice',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'label': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'hover': '',
      'breadcrumb': 'Feedback - Uservoice',
      'text': 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS',
      'icon': 'ico-nav-user-voice',
      'searchParent': 'Feedback Forum',
      'flag': 'true',
      'subNavigation': [
        {
          'name': 'Actions & Recommendations',
          'link': 'Action-Recommendation-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL',
          'hover': ' Actions & Recommendations',
          'breadcrumb': 'Actions & Recommendations',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'FA&R',
          'searchName': 'Actions & Recommendations'
        },
        {
          'name': 'Asset Harvester',
          'link': 'Asset-Harvester-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': ' Asset Harvester ',
          'breadcrumb': 'Asset Harvester',
          'icon': '',
          'subNavigation': [],
          'role': ['28', '29'],
          'shortName': 'FAH',
          'searchName': 'Asset Harvester'
        },
        {
          'name': 'Data Visualization',
          'link': 'data-Visualization-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
          'hover': 'Data Visualization',
          'breadcrumb': 'Data Visualization',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['9'],
          'shortName': 'FDV',
          'searchName': 'Data Visualization'
        },
        {
          'name': 'E-Data',
          'link': 'E-Data-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_E_DATA.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_E_DATA.NAV_LABEL',
          'hover': 'E-Data',
          'breadcrumb': 'E-Data',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Financial Impact',
          'link': 'financial-impact-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
          'hover': 'Financial Impact',
          'breadcrumb': 'Financial Impact',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['8', '15']
        },
        {
          'name': 'Inventory Management',
          'link': 'Inventory-Management-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_INVENTORY_MANAGEMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_INVENTORY_MANAGEMENT.NAV_LABEL',
          'hover': 'Inventory Management',
          'breadcrumb': 'Inventory Management',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'FFI',
          'searchName': 'Financial Impact'
        },
        {
          'name': 'My Documents',
          'link': 'my-documents-Uservoice',
          'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
          'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'breadcrumb': 'My Documents',
          'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'icon': 'ico-nav-my-documents',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'FMD',
          'searchName': 'My Documents'
        },
        {
          'name': 'Omni',
          'link': 'Omni-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': ' Omni ',
          'breadcrumb': 'Omni',
          'icon': '',
          'subNavigation': [],
          'role': ['5'],
          'shortName': 'FOMNI',
          'searchName': 'OMNI'
        },
        {
          'name': 'Service Summary Dashboard',
          'link': 'service-summary-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL',
          'hover': ' Service Summary Dashboard',
          'breadcrumb': 'Service Summary Dashboard',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'FSSD',
          'searchName': 'Service Summary Dashboard'
        },
        {
          'name': 'Water Flow Intelligence',
          'link': 'water-Flow-intelligence-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['7'],
          'shortName': 'FWFI',
          'searchName': 'Water Flow Intelligence'
        },
        {
          'name': 'Water Quality',
          'link': 'Water-Quality-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': '',
          'subNavigation': [],
          'role': ['4', '11'],
          'shortName': 'FWQ',
          'searchName': 'Water Quality'
        },
        {
          'name': 'Water Safety Intelligence',
          'link': 'Water-Safety-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': '',
          'subNavigation': [],
          'role': ['2', '10'],
          'shortName': 'FWSI',
          'searchName': 'Water Safety Intelligence'
        }
      ]
    },
    {
      'name': 'Navigation - Main - My Documents',
      'link': '/my-documents',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'breadcrumb': 'My Documents',
      'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'icon': 'ico-nav-my-documents',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Tools',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'label': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'hover': '',
      'breadcrumb': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'text': 'LEFT_NAV.MAIN_NAV_TOOLS',
      'icon': 'icon-tools',
      'subNavigation': [
        {
          'name': 'Envision-ERB',
          'link': 'Envision_ERB',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['-62', '1'],
          'shortName': 'ERB',
          'searchName': 'ERB'
        },
        {
          'name': 'Navigation - Main - Alarms',
          'link': '/admin-alarm',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'hover': 'Alarms',
          'breadcrumb': 'Alarms',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS',
          'subNavigation': [],
          'role': ['19', '20', '21', '77']
        },
        {
          'name': 'Navigation - wfi - Alarms',
          'link': '/WFI-alarm',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'hover': 'wfi Alarms',
          'breadcrumb': 'wfi Alarms',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_ENVISION.WFI_ALARMS',
          'subNavigation': [],
          'role': ['19', '20', '21', '77']
        },
        {
          'name': 'Alarm & User Management',
          'link': '/alarm-user-mgmt',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'hover': 'Alarms',
          'breadcrumb': 'Alarms',
          'icon': 'icon-icon_admin',
          'text': 'LEFT_NAV.SUBNAV_ENVISION.ALARM_AND_USER_MGMT',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Envision-Controller',
          'link': 'Envision_Controller',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': true,
          'role': ['1'],
          'shortName': 'ENVC',
          'searchName': 'Controller'
        },

        {
          'name': 'Envision-Inventory',
          'link': 'Envision_Inventory',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.INVENTORY',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': true,
          'role': ['62'],
          'shortName': 'ENVI',
          'searchName': 'Inventory'
        },

        {
          'name': 'Envision-CustomerData-Upload',
          'link': 'Envision_CustomerDataUpload',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CUSTOMER_DATA_UPLOAD',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVCDU',
          'searchName': 'Customer Data Upload'
        },

        {
          'name': 'Envision-USBManualNextGenUpgrades',
          'link': 'Envision_NextGenUpgrades',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.USB_MANUAL_NEXTGEN_UPGRADES',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVUSB',
          'searchName': 'USB Manual NextGen Upgrades'
        },

        {
          'name': 'Envision-Configurator',
          'link': 'Envision_Configurator',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['-62', '1'],
          'shortName': 'ENVCG',
          'searchName': 'Configurator'
        },

        {
          'name': 'Envision-Configurator-CoolingWater',
          'link': 'Configurators_CoolingWater',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_COOLING_WATER',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVCCW',
          'searchName': 'Cooling Water'
        },
        {
          'name': 'Envision-Configurator-SecondaryDesinfection',
          'link': 'Configurators_SecondaryDesinfection',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_SECONDARY_DISINFECTION',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVCSD',
          'searchName': 'Secondary Disinfection'
        },
        {
          'name': 'Envision-Configurator-CanningAndBottling',
          'link': 'Configurators_CanningAndBottling',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_CANNING_AND_BOTTLING',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVCCB',
          'searchName': 'Canning And Bottling'
        },
        {
          'name': 'Envision-Configurator-WasteWaterOther',
          'link': 'Configurators_WasteWaterOther',
          'isExternal': 'true',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATORS_WASTEWATER_OTHER',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['62'],
          'shortName': 'ENVCWW',
          'searchName': 'Waste Water Other'
        },
        {
          'name': 'Navigation - Main - Manual Equipment Creation',
          'link': '/manual-equipment-creation',
          'labelMobile': 'Manual Equipment Creation',
          'label': 'Manual Equipment Creation',
          'hover': 'Manual Equipment Creation',
          'breadcrumb': 'Manual Asset Creation',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['81']
        },
        {
          'name': 'Navigation - Main - Manual Equipment Creation',
          'link': '/manual-equipment-creation/approverPage',
          'labelMobile': 'Manual Equipment Creation',
          'label': 'Manual Equipment Creation',
          'hover': 'Manual Equipment Creation',
          'breadcrumb': 'Manual Asset Creation',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['82']
        }
      ],
      'role': ['1']
    },
    {
      'name': 'Common Pages',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'label': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'hover': '',
      'breadcrumb': 'Common Pages',
      'text': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'icon': 'ico-nav-common',
      'searchParent': 'Safety Information',
      'flag': 'true',
      'subNavigation': [
        {
          'name': 'Emergency Numbers',
          'link': 'common-pages/emergency-numbers',
          'labelMobile': 'LEFT_NAV.EMERGENCY',
          'label': 'LEFT_NAV.EMERGENCY',
          'hover': '',
          'breadcrumb': 'Emergency Numbers',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'EN',
          'searchName': 'Emergency Numbers'
        },
        {
          'name': 'SH&E Reference',
          'link': 'common-pages/safety-health',
          'labelMobile': 'LEFT_NAV.SH&E',
          'label': 'LEFT_NAV.SH&E',
          'hover': 'SH&E Reference',
          'breadcrumb': 'SH&E Reference',
          'icon': '',
          'subNavigation': [],
          'role': ['1'],
          'shortName': 'SH&ER',
          'searchName': 'SH&E Reference'
        }
      ]
    },
    {
      'name': 'Support Info',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_SUPPORT_INFO',
      'label': 'LEFT_NAV.MAIN_NAV_SUPPORT_INFO',
      'hover': '',
      'breadcrumb': 'Support Info',
      'text': 'LEFT_NAV.MAIN_NAV_SUPPORT_INFO',
      'icon': 'ico-nav-help',
      'isVisible': true,
      'subNavigation': [
        {
          'name': 'Ecolab Digital Support:(866) 851 – 8670',
          'link': '',
          'href': 'tel:(866) 851 – 8670',
          'labelMobile': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT',
          'label': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT',
          'hover': '',
          'breadcrumb': 'Ecolab Digital Support:(866) 851 – 8670',
          'icon': '',
          'isVisible': true,
          'isInternalUser': false,
          'region': ['North America', 'Latinamerica'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n 855-432-6522',
          'link': '',
          'href': 'tel:855-432-6522',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_NA',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_NA',
          'hover': '',
          'breadcrumb': 'Onboarding Support:\n 855-432-6522',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n +31 71 524 1111',
          'link': '',
          'href': 'tel:+31 71 524 1111',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_EU',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_EU',
          'hover': '',
          'breadcrumb': 'Onboarding Support:\n +31 71 524 1111',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['Europe'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support:\n Digital Contacts',
          'link': '',
          'href': '',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA_PACIFIC',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA_PACIFIC',
          'hover': '',
          'breadcrumb': 'Onboarding Support: Digital Contacts',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['Pacific'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Ecolab Digital Support:',
          'link': '',
          'href': '',
          'labelMobile': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT_ALL',
          'label': 'LEFT_NAV.ECOLAB_DIGITAL_SUPPORT_ALL',
          'hover': '',
          'breadcrumb': 'Ecolab Digital Support:',
          'icon': '',
          'isVisible': true,
          'isInternalUser': false,
          'region': ['Europe', 'Pacific', 'Mid.East/Africa'], // 'Greater China'
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Onboarding Support: ',
          'link': '',
          'href': 'tel:',
          'labelMobile': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA',
          'label': 'LEFT_NAV.ONBOARDING_SUPPORT_IMEA',
          'hover': '',
          'breadcrumb': 'Onboarding Support:',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['Greater China', 'Latinamerica', 'Mid.East/Africa'],
          'subNavigation': [],
          'role': ['1']
        },
        // {
        //   'name': 'CDS Support Email',
        //   'link': '',
        //   'href':'mailto:ecolabcds@service-now.com?subject=New%20Ecolab 3D%20–%20Nalco%20Water%20–%20Support&body=Please%20mention%20which%20service%20you%20are%20using.%20(i.e.%20Water%20Quality%20Intelligence,%20Water%20Safety%20Intelligence,%20OMNI,%20My%20Documents,%20etc.)%20Please%20give%20a%20brief%20description%20of%20what%20you%20need%20help%20with.',
        //   'labelMobile': 'LEFT_NAV.SUPPORT_EMAIL',
        //   'label': 'LEFT_NAV.SUPPORT_EMAIL',
        //   'hover': 'CDS Support Email',
        //   'breadcrumb': 'CDS Support Email',
        //   'icon': '',
        //   'isVisible':true,
        //   'isInternalUser': true,
        //   'region': ['North America', 'Europe'],
        //   'subNavigation': [],
        //   'role': ['1']
        // },
        {
          'name': 'Email',
          'link': '',
          'href': '',
          'target': '',
          'labelMobile': 'LEFT_NAV.EGIC_EMAIL',
          'label': 'LEFT_NAV.EGIC_EMAIL',
          'hover': 'Email',
          'breadcrumb': 'Email',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Email',
          'link': '',
          'href': '',
          'target': '',
          'labelMobile': 'LEFT_NAV.EGIC_EMAIL',
          'label': 'LEFT_NAV.EGIC_EMAIL',
          'hover': 'Email',
          'breadcrumb': 'Email',
          'icon': '',
          'isVisible': true,
          'isInternalUser': false,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Site Action Request',
          'link': 'SITE Creation (Emily Synal) (formsite.com)',
          'href': '',
          'labelMobile': 'LEFT_NAV.SITE_ACTION_REQUEST',
          'label': 'LEFT_NAV.SITE_ACTION_REQUEST',
          'hover': 'Site Action Request',
          'breadcrumb': 'Site Action Request',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa', 'Unknown'],
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Digital Support Dashboard',
          'link': '',
          'href': 'https://ecolab.sharepoint.com/sites/IndustrialDigitalSupportDashboard/SitePages/ECOLAB3D-noticeboard.aspx?csf=1&&web=1&&e=ZuTHyf&&cid=6ed84063-33a2-4490-8729-b4d391a134ed',
          'labelMobile': 'LEFT_NAV.DIGITAL_SUPPORT_DASHBOARD',
          'label': 'LEFT_NAV.DIGITAL_SUPPORT_DASHBOARD',
          'hover': 'Digital Support Dashboard',
          'breadcrumb': 'Digital Support Dashboard',
          'icon': '',
          'isVisible': true,
          'isInternalUser': true,
          'region': ['North America', 'Europe', 'Greater China', 'Pacific', 'Latinamerica', 'Mid.East/Africa'],
          'subNavigation': [],
          'role': ['1']
        },
      ]
    }
  ],
  'topNavigation': []
};
