<div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title delete-txt-icon">{{'COMMON.ERROR_HEADER' | translate}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="disablebtn? activeModal.close(): closeModal();">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
  <p class="revert mb-1 modalcontent"[translate]="'COMMON.ERROR_TEXT'" 
  [translateParams]="{ supportEmail: supportEmailId }"></p>  
  <p class="revert mb-0 modalcontent">{{errorMessage}}</p>

  <div class="modal-footer mt-0">
    <button #closeBtn container="body" placement="bottom" [ngbTooltip]="closeBtn.scrollWidth > closeBtn.offsetWidth ? ('COMMON.CLOSE' | translate) : '' "
    type="button" class="btn btn-secondary text-overflow" (click)="closeModal()">{{'COMMON.CLOSE' | translate }}</button>
    <button #copyToClipboardBtn container="body" placement="bottom" [ngbTooltip]="copyToClipboardBtn.scrollWidth > copyToClipboardBtn.offsetWidth ? ('COMMON.COPY_CLIPBOARD' | translate) : '' "
     type="button" class="btn btn-primary clipboard-btn text-overflow" (click)="copyToClipboard(errorMessage)">{{'COMMON.COPY_CLIPBOARD' | translate }}</button>
  </div>
